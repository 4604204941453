<template>
    <div class="list_products flex f-c">
        <div class="search_header flex f-w a-i-c">
            <a-col :span="6">
                <div class=""> <a-input :style="{ width: '80%' }" placeholder="请输入订单编号" allow-clear
                        v-model="paramsData.ordersn">
                        <template #prepend>订单编号:
                        </template>
                    </a-input></div>
            </a-col>
            <a-col :span="6">
                <div class=""> <a-input :style="{ width: '80%' }" placeholder="请输入商品名称" allow-clear
                        v-model="paramsData.name">
                        <template #prepend>
                            商品名称:
                        </template>
                    </a-input></div>
            </a-col>
            <!-- <a-col :span="6">
                <div class="flex a-i-c">
                    <div class="item_desc  desc_width_two">订单状态:</div>
                    <a-select :options="['Option1', 'Option2', 'Option3']" :style="{ width: '160px' }"
                        placeholder="请选择订单状态">
                    </a-select>
                </div>
            </a-col> -->
            <a-col :span="6">
                <div class=""> <a-input :style="{ width: '80%' }" placeholder="请输入手机号" allow-clear
                        v-model="paramsData.phone">
                        <template #prepend>
                            手机号:
                        </template>
                    </a-input></div>
            </a-col>
            <a-col :span="6">
                <div class="flex a-i-c" style="margin: 10px;">
                    <div class="item_desc ">筛选日期:</div>
                    <!-- <a-date-picker style="width: 247px;background-color: #ffffff;border: 1px solid #E6E6E6;"
                        @change="confirmDate">
                    </a-date-picker> -->
                    <a-range-picker
                        style="width: 220px;height: 40px;;background-color: #ffffff;border: 1px solid #E6E6E6; margin-left: 14px;"
                        format="YYYY-MM-DD" @change="onOk" v-model="rangeValue" />
                </div>
            </a-col>
            <div class="flex f-x-b" style="width: 100%;padding: 30px 50px;">
                <a-col :span="2">
                    <!-- <a-button type="primary" @click="excalEvent" style="height: 40px;">
                        导出excl表格
                    </a-button> -->
                </a-col>
                <div class="flex f-x-e" style="width: 100%;">
                    <a-col :span="3">
                        <div class="item btn flex a-i-c ">
                            <a-button type="primary" @click="handlePageChange(1, 10)" style="height: 40px;">
                                <template #icon>
                                    <icon-search />
                                </template>
                                搜索
                            </a-button>
                        </div>
                    </a-col>
                    <a-col :span="3">
                        <div class="item btn">
                            <a-button type="primary" status="success" @click="reset" style="height: 40px;">
                                <template #icon>
                                    <icon-refresh />
                                </template>
                                重置
                            </a-button>
                        </div>
                    </a-col>
                </div>

            </div>

        </div>
        <!-- <div class="center_btn flex f-x-b">
            <div class="flex">
                <!-- <div class="center_item ">
                    <a-button type="primary">
                        批量导入
                    </a-button>
                </div> -->
        <!-- <div class="center_item">
            <a-button type="primary" @click="excalEvent">
                导出excl表格
            </a-button>
        </div>
    </div>
    <div class="flex" style="position: relative; z-index: 1;">
        <div class="center_item">

        </div> -->

        <div class="list_content">
            <div class="list_content_header flex ">
                <div class="list_item" v-for="item, index in listHeader" :key="index"
                    @click="handelChangeHeaderListItem(item, index)"
                    :style="{ color: currentSelectHeaderItem === item.id ? ' #6784FD' : '' }">
                    {{
                        item.title }}
                </div>
            </div>
            <a-space direction="vertical" size="large" fill>
                <a-table :columns="columns" :data="datas" :row-selection="rowSelection" :row-key="'id'"
                    @selectAll="selectedAllKeysEvent" @select="selectedEvent" v-model:selectedKeys="selectedKeys"
                    :pagination="pagination" :bordered="false" :stripe="true">
                    <!-- :data="form.noData ? [] : data" -->
                    <!-- <template #accessories="{ record, rowIndex }">
                        <span v-if="!record.editable">
                            <span style="color: #FD9C65;cursor: pointer;" @click="edit(record.key)"> 点击录入</span>
                        </span>
                        <span v-else>
                            <a-image width="30" height="30"
                                :src="`https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/a8c8cdb109cb051163646151a4a5083b.png~tplv-uwbnlip3yd-webp.webp?timestamp=${timestamp}`"
                                show-loader />
                            {{ record.tradeName }}
                        </span>
                    </template> -->
                    <template #orderCode="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.orderCode" />
                        </span>
                        <span v-else>
                            {{ record.orderCode }}
                        </span>
                    </template>
                    <template #shopName="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.shopName" />
                        </span>
                        <span v-else>
                            <a-image width="30" height="30"
                                :src="`https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/a8c8cdb109cb051163646151a4a5083b.png~tplv-uwbnlip3yd-webp.webp?timestamp=${timestamp}`"
                                show-loader />
                            {{ record.shopName }}
                        </span>
                    </template> <template #modelNumber="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.modelNumber" />
                        </span>
                        <span v-else>
                            {{ record.modelNumber }}
                        </span>
                    </template>


                    <template #people="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.people" />
                        </span>
                        <span v-else>
                            {{ record.people }}
                        </span>
                    </template>
                    <template #phone="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.phone" />
                        </span>
                        <span v-else>
                            {{ record.phone }}
                        </span>
                    </template>
                    <template #phoneNumber="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.phoneNumber" />
                        </span>
                        <span v-else>
                            {{ record.phoneNumber }}
                        </span>
                    </template> <template #address="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.address" />
                        </span>
                        <span v-else>
                            {{ record.address }}
                        </span>
                    </template> <template #quantity="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.quantity" />
                        </span>
                        <span v-else>
                            {{ record.quantity }}
                        </span>
                    </template> <template #price="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.price" />
                        </span>
                        <span v-else>
                            {{ record.price }}
                        </span>
                    </template>
                    <template #paystatus="{ record, rowIndex }">
                        <span v-if="record.paystatus == '0'">
                            未支付</span>
                        <span v-if="record.paystatus == '1'">
                            已支付</span>
                        <span v-if="record.paystatus == '2'">
                            退款中</span> <span v-if="record.paystatus == '3'">
                            已退款</span> <span v-if="record.paystatus == '4'">
                            预收款</span>
                        <span v-if="record.paystatus == '5'">
                            预付款</span>
                    </template><template #remarks="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.remarks" />
                        </span>
                        <span v-else>
                            {{ record.remarks }}
                        </span>
                    </template>

                    <template #controls="{ record, rowIndex }">
                        <!-- v-if="record.editable" style="white-space: nowrap;" -->
                        <div class="flex f-x-c">
                            <!-- <a-button type="primary" @click="editRecord(record.key)"
                                style="margin-left: 5px;">查看</a-button> -->
                            <a-button type="primary" @click="FH(record.shoporderid)" style="margin-left: 5px;"
                                v-if="currentSelectHeaderItem == 2">发货</a-button>
                            <a-button type="primary" @click="TK(record.orderosn)" style="margin-left: 5px;"
                                v-if="record.paystatus == '1'">退款</a-button>
                            <a-button status="danger" @click="deleteRecord(record.id, rowIndex)"
                                style="margin-left: 5px;">删除</a-button>
                        </div>
                    </template>

                </a-table>
            </a-space>
        </div>
        <!-- //收款弹窗 -->
        <a-modal :footer="null" v-model:visible="collectionPop" :width="724" title="收款" alignCenter draggable
            escToClose>
            <a-form :model="collectionFrom" @submit="handleSubmitAddGoods">
                <div class="flex a-i-c">
                    <a-form-item field="orderId">
                        <div class="good_item_desc">订单编号:</div>
                        <a-input :style="{ width: '120px' }" v-model="collectionFrom.orderId" placeholder="请输入订单编号"
                            allow-clear disabled />
                    </a-form-item>
                    <a-form-item field="shopName">
                        <div class="good_item_desc  ">商品名称:</div>
                        <a-input :style="{ width: '120px' }" v-model="collectionFrom.shopName" placeholder="请输入商品名称"
                            allow-clear disabled />
                    </a-form-item>
                    <a-form-item field="orderStatus">
                        <div class="good_item_desc  ">订单状态:</div>
                        <a-select :options="['Option1', 'Option2', 'Option3']" :style="{ width: '160px' }"
                            placeholder="请选择订单状态">
                        </a-select>
                    </a-form-item>
                    <a-form-item field="phoneNumber">
                        <div class="good_item_desc  ">手机号:</div>
                        <a-input :style="{ width: '120px' }" v-model="collectionFrom.phoneNumber" placeholder="请输入手机号"
                            allow-clear disabled />
                    </a-form-item>
                </div>
                <a-form-item field="warning" class="flex f-x-a" style="transform: translateX(-14%)">
                    <div class="good_item_desc ">收款金额:</div>
                    <!-- <a-input :style="{ width: '248px', border: 'none !important' }" v-model="collectionFrom.warning"
                            placeholder="输入收款金额" allow-clear disabled /> -->
                    <a-input-number v-model="value" :style="{ width: '24%', border: 'none !important' }"
                        class="input-demo" :min="10" :max="100" hide-button="true" placeholder="输入收款金额">
                        <template #prefix>
                            <span class="money_icon" style="color: red; font-size: 18px;">¥</span>
                        </template>
                    </a-input-number>
                </a-form-item>
                <div style="font-size: 14px;
color: #333333;padding-left: 7%;font-weight: bold;">欠款单据:</div>
                <a-checkbox-group v-model="checkedValue">
                    <!-- <div v-for="(spec, index) in collectionFrom.notesOfArrearsLists" :key="index"
                        class="notes flex  f-r f-w"> -->
                    <a-form-item style="width: 45%;padding-left: 7% ;margin: 5px 5px 5px 0; box-sizing: border-box;"
                        class="notes flex f-r f-w" v-for="(spec, index) in collectionFrom.notesOfArrearsLists"
                        :key="index">
                        <!-- :field="'specifications[' + index + '].modelNumber'" -->
                        <div class="notes_box">
                            <div class="notes_item_t flex f-x-b a-i-c">
                                <div class="notes_item_t_l">2024.04.03</div>
                                <div class="notes_item_t_r">
                                    <a-checkbox value="1"></a-checkbox>
                                </div>
                            </div>
                            <div class="notes_item_b flex f-w">
                                <div class="flex f-r a-i-c">总金额:
                                    <span class="moneys">¥600</span>
                                </div>
                                <div class="flex f-r a-i-c">未收金额:
                                    <span class="moneys">¥7600</span>
                                </div>
                                <div class="flex f-r a-i-c">已付金额:
                                    <span class="moneys">¥7600</span>
                                </div>

                            </div>
                        </div>
                    </a-form-item>
                    <!-- </div> -->
                </a-checkbox-group>
                <a-form-item>
                    <div style="width: 75%; text-align: center;margin-top: 50px">
                        <a-button type="outline" status="primary" size="large" style="margin-right: 20px;">取消</a-button>
                        <a-button html-type="submit" type="primary" size="large">确定</a-button>
                    </div>
                </a-form-item>
            </a-form>
        </a-modal>
        <!-- 添加客户弹窗 -->
        <a-modal :footer="null" v-model:visible="addCustomer" :width="724" title="添加客户" alignCenter draggable
            escToClose>
            <a-form :model="addCustomerForm" @submit="handleAddCustomer" class="flex f-x-c"> <a-form-item field="name"
                    style="margin-top: 30px;">
                    <div class="good_item_desc">客户名称:</div>
                    <a-input :style="{ width: '320px', }" v-model="addCustomerForm.customerName" placeholder="请输入客户名称"
                        allow-clear />
                </a-form-item>
                <a-form-item field="phone" style="margin-top: 20px;">
                    <div class="good_item_desc  ">联系方式:</div>
                    <a-input :style="{ width: '320px' }" v-model="addCustomerForm.phone" placeholder="请输入联系方式"
                        allow-clear />
                </a-form-item>
                <a-form-item field="address" style="margin-top: 20px;">
                    <!-- :rules="[{ required: true, message: '请选择地址' }]" -->
                    <div class="good_item_desc">地址:</div>
                    <div class="flex f-c">
                        <a-cascader :options="options" default-value="datunli" allow-clear expand-trigger="hover"
                            :style="{ width: '320px' }" placeholder="请选择所在地址" />
                        <a-textarea placeholder="请输入详细地址" allow-clear :max-length="200" show-word-limit :style="{
                            width: '320px', height: '100px', marginTop: '30px', border: '1px solid #E6E6E6', background: '#FFFFFF'
                        }" v-model="addCustomerForm.address" />
                        <!-- v-model="form.feedback" -->
                    </div>
                </a-form-item>
                <a-form-item field="houseNumber" style="margin-top: 20px;">
                    <div class="good_item_desc  ">门牌号:</div>
                    <a-input :style="{ width: '320px' }" v-model="addCustomerForm.houseNumber" placeholder="请输入门牌号"
                        allow-clear />
                </a-form-item>
                <a-form-item field="logistics" style="margin-top: 20px;">
                    <div class="good_item_desc ">物流信息:</div>
                    <a-input :style="{ width: '320px' }" v-model="addCustomerForm.logistics" placeholder="请输入物流信息"
                        allow-clear />
                </a-form-item>
                <a-form-item style="text-align: center !important;padding: 50px 80px 0">
                    <a-button type="outline" status="primary" size="large" style="margin-right: 20px;">取消</a-button>
                    <a-button html-type="submit" type="primary" size="large">确定</a-button>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>
<script setup>
import { ref, watch, toRefs, reactive, getCurrentInstance, onMounted } from 'vue';
import { IconSearch, IconRefresh, IconPrinter, IconPlus, } from '@arco-design/web-vue/es/icon';
const Message = getCurrentInstance().appContext.config.globalProperties.$message
const Api = getCurrentInstance()?.appContext.config.globalProperties.$Api
const Com = getCurrentInstance()?.appContext.config.globalProperties.$Com
onMounted(() => {
    handlePageChange()
});
//#region 头部bar
const listHeader =
    [
        {
            title: '全部',
            id: 'all'
        },
        {
            title: '待付款',
            id: 'dfk'

        },
        {
            title: '待发货',
            id: 'dfh'

        },
        {
            title: '待收货',
            id: 'dsh'

        },
        //  {
        //     title: '已完成',
        //     id: 'ywc'
        // }
    ];
const currentSelectHeaderItem = ref('all')
//子项点击
const handelChangeHeaderListItem = (item, index) => {
    if (currentSelectHeaderItem.value == item.id) return
    currentSelectHeaderItem.value = item.id
    if (item.id === 'dfk') {
        paramsData.value.kind = 2
        paramsData.value.paystatusArray = `0,2`
    } else if (item.id === 'dfh') {
        paramsData.value.kind = 1
        paramsData.value.paystatusArray = `1`
        paramsData.value.harvestsArray = `0`
    } else if (item.id === 'dsh') {
        paramsData.value.kind = 1
        paramsData.value.paystatusArray = `1`
        paramsData.value.harvestsArray = `1`
    } else if (item.id === 'ywc') {
        paramsData.value.kind = 2
        paramsData.value.paystatusArray = `1`
        paramsData.value.harvestsArray = `2`
    } else if (item.id === 'tkz') {
        //TODO:
    } else if (item.id === 'all') {
        paramsData.value.kind = ''
        paramsData.value.paystatusArray = ``
        paramsData.value.harvestsArray = ``
    }
    datas.value = []
    handlePageChange(pagination.value.current === undefined ? 1 : pagination.value.current, 10)

}
//#endregion
//#region  重制
const reset = () => {
    paramsData.value = {
        kind: '',
        paystatusArray: '',
        harvestsArray: '',
        ordersn: '',
        phone: '',
        name: '',//客户名称
        start_time: '',
        end_time: ''
    }
    rangeValue.value = []
}
//#endregion  
//#region 列表功能**//
const paramsData = ref({
    kind: '',
    paystatus: '',
    harveststatus: '',
    ordersn: '',
    phone: '',
    name: '',//客户名称
    // starttime: '',
    // stoptime: '',

})
const columns = [{
    title: '订单编号',
    dataIndex: 'ordersn',
    slotName: 'ordersn'
}, {
    title: '商品名称',
    dataIndex: 'goodname',
    slotName: 'goodname'

},
{
    title: '型号',
    dataIndex: 'model',
    slotName: 'model'

},
{
    title: '收货人',
    dataIndex: 'shoporderUsername',
    slotName: 'shoporderUsername'

}, {
    title: '收货地址',
    dataIndex: 'shoporderAddress',
    slotName: 'shoporderAddress'

}, {
    title: '数量',
    dataIndex: 'qty',
    slotName: 'qty'

}, {
    title: '金额',
    dataIndex: 'price',
    slotName: 'price'

}, {
    title: '订单状态',
    dataIndex: 'paystatus',
    slotName: 'paystatus'

}, {
    title: '备注',
    dataIndex: 'remark',
    slotName: 'remark'

},

{
    title: '操作',
    slotName: 'controls'
}
];
const datas = ref([])
const selectedKeys = ref([]);
const rowSelection = reactive({
    type: 'checkbox',
    showCheckedAll: true,
    onlyCurrent: false,
    selectedRowKeys: selectedKeys.value, // 当前选中的行 key 数组
    onChange: (selectedRowKeys, selectedRows, record) => {
        selectedKeys.value = selectedRowKeys;
        console.log('selectedRows:', selectedRows, record);
    }
});
//分页
const pagination = ref({
    total: 0,  // 数据总条目数
    showTotal: true,            // 是否显示总条目数
    defaultPageSize: 10,        // 默认每页显示条目数
    pageSize: 10,               // 当前每页显示条目数
    current: 1,                 // 当前页码
    showJumper: true,
    onChange: (page, pageSize) => {
        handlePageChange(page, pageSize);
    }
});
const handlePageChange = (page, pageSize) => {
    if (JSON.parse(localStorage.getItem("userInfo")).shopId == -1) return Message.warning("请开通商城并提交店铺信息")
    console.log('page:', page, 'pageSize:', pageSize);
    const pageIndex = page ? page : pagination.value.current;
    Api.orderList({
        pageIndex: pageIndex,
        status: 0,
        shopId: JSON.parse(localStorage.getItem("userInfo")).shopId,
        ...paramsData.value
    }).then(res => {
        console.log(res);
        pagination.value.current = page;        // 更新当前页码
        res.data.datalist.forEach(item => {
            item.editable = false;
            item.rowKey = `${item.id}_${item.goodsid}`
            item.firstImg = Com.getFirstImage(item.img)
        });
        pagination.value.total = res.data.total; // 更新总条目数
        datas.value = [...datas.value, ...res.data.datalist];
        console.log(datas.value);
    }).catch(error => {
        console.error('Error fetching data:', error);
    });
};
//#endregion
//#region 选择单个 多个 操作
//全选
const selectedIds = ref([]);
const selectedIdsString = ref('');
const clearSelectData = () => {
    // 清空数组
    selectedIds.value = [];
    // 清空字符串变量
    selectedIdsString.value = '';
    // datas.value = []
}
const selectedAllKeysEvent = val => {
    if (val) {
        // 清空数组
        selectedIds.value = datas.value.map(row => row.id);
        // 更新字符串变量
        selectedIdsString.value = selectedIds.value.join(',');
    } else {
        clearSelectData()
    }

    // 输出变量值
    console.log('Selected IDs:', selectedIdsString.value);
};
//单选
const selectedEvent = val => {
    // 清空之前的数据
    selectedIds.value = [];
    // 对 val 进行类型检查或者空值检查
    if (!Array.isArray(val)) {
        console.error('Invalid value for selectedEvent:', val);
        return;
    }
    val.forEach(item => {
        const id = item
        selectedIds.value.push(id);
    });

    // 转换为字符串
    selectedIdsString.value = selectedIds.value.join(',');

    // 输出变量值
    console.log('Selected IDs:', selectedIdsString.value);
};
//#endregion
//#region 导出
const excalEvent = () => {
    if (!selectedIdsString.value) {
        return Message.warning('请选择商品');
    }
    Api.excal({ ids: selectedIdsString.value },).then(res => {
        const downloadUrl = res.data.data;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'filename.xlsx'); // 设置下载文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })
}

//#endregion
// 编辑单个
// const editingKey
const editRecord = (key) => {
    // console.log('editRecord:', record);
    // datas.value.forEach(item => {
    //     if (item.key === key) {
    //         item.editable = true;
    //     } else {
    //         item.editable = false;
    //     }
    // });
    // editingKey.value = key;
    // $modal.info({ title: '编辑', content: record.name });
};
const cancelRecord = (key) => {
    datas.value.forEach(item => {
        if (item.key === key) {
            item.editable = false;
        }
    });
    // editingKey.value = '';
}
//发货
const FH = (id) => {
    Api.updateShopPaymentInfo({ shoporderid: id }).then(res => {
        datas.value = [];

        handlePageChange(pagination.value.current === undefined ? 1 : pagination.value.current, 10)

        console.log(res);
        Message.success(res.msg)
    })
}
//退款
const TK = (id) => {
    Api.TKOrder({ orderosn: id }).then(res => {
        datas.value = [];
        handlePageChange(pagination.value.current === undefined ? 1 : pagination.value.current, 10)
        console.log(res);
        Message.success('退款成功')
    })
}
// 删除单个
const deleteRecord = (id) => {
    Api.delOrder({ ids: id }).then(res => {
        datas.value = [];
        handlePageChange(pagination.value.current === undefined ? 1 : pagination.value.current, 10)

        console.log(res);
        Message.success(res.msg)
    })
};

//**收款 */
const collectionPop = ref(false)
const collectionFrom = reactive({
    customerName: '',//客户名称
    accumulatedArrears: '',//累计欠款
    advanceSalePayment: '', //预收款项
    amountOfMoneyReceived: '',//收款金额
    notesOfArrearsLists: [{ desc: 1 }, { desc: 1 }, { desc: 1 }]
})
const openCollectionPop = (item, index) => {
    // const { customerName, accumulatedArrears, advanceSalePayment, amountOfMoneyReceived } = toRefs(item);
    // collectionFrom.customerName = customerName.value;
    // collectionFrom.accumulatedArrears = accumulatedArrears.value;
    // collectionFrom.advanceSalePayment = advanceSalePayment.value;
    // collectionFrom.amountOfMoneyReceived = amountOfMoneyReceived.value;
    collectionPop.value = true;
    console.log(collectionFrom, item);
}

//**添加客户功能**//
const addCustomer = ref(false);
const addCustomerForm = reactive({
    customerName: '',//客户名称
    phone: '',//联系方式
    address: '',//地址
    houseNumber: '',//门牌号
    logistics: '',//物流信息
});
const options = [
    {
        value: 'beijing',
        label: 'Beijing',
        children: [
            {
                value: 'chaoyang',
                label: 'ChaoYang',
                children: [
                    {
                        value: 'datunli',
                        label: 'Datunli',
                    },
                ],
            },
            {
                value: 'haidian',
                label: 'Haidian',
            },
            {
                value: 'dongcheng',
                label: 'Dongcheng',
            },
            {
                value: 'xicheng',
                label: 'Xicheng',
                children: [
                    {
                        value: 'jinrongjie',
                        label: 'Jinrongjie',
                    },
                    {
                        value: 'tianqiao',
                        label: 'Tianqiao',
                    },
                ],
            },
        ],
    },
    {
        value: 'shanghai',
        label: 'Shanghai',
        children: [
            {
                value: 'huangpu',
                label: 'Huangpu',
            },
        ],
    },
];

const handleSubmitAddGoods = (data) => {
    console.log(data);
};
//#region 时间选择弃
const rangeValue = ref([])
const onOk = (dateString, date) => {
    paramsData.value.start_time = dateString[0]
    paramsData.value.end_time = dateString[1]
    console.log(paramsData.value);
}
//#endregions

</script>
<style scoped lang="scss">
.list_products {
    background: #FFFFFF;
    border-radius: 2px;
    height: 100%;
    box-sizing: border-box;
    padding: 15px 10px;

    .editable-row-operations a {
        margin-right: 8px;
    }

    .item {
        margin: 0 20px;
        width: 100%;
    }



    .mt {
        margin-top: 20px;
    }

    .nbsp {
        width: 30px;
        color: #999999;
        text-align: center;
    }

    .item_desc,
    :deep(.arco-input-prepend) {
        font-weight: 400;
        white-space: nowrap;
        color: #000000;
        font-size: 14px;
        background-color: red;
        background: #FFFFFF !important;
        border: none;
    }

    .desc_width {
        width: 100px;
        width: 70px;
    }

    :deep(.arco-input-wrapper) .arco-input::placeholder,
    :deep(.arco-select-view-single) .arco-select-view-input::placeholder {
        font-size: 12px !important;

    }

    .desc_right {
        margin-right: 12px;
    }

    .desc_right_t {
        margin-right: 2px;

    }

    .desc_width_two {
        width: 23%;
        margin-right: 12px;
        text-align: right;
    }

    .money_icon {
        font-weight: 500;
        font-size: 12px;
        color: #FF4D4C;
    }


    :deep(.arco-input-wrapper),
    :deep(.arco-select-view-single) {
        border-radius: 2px;
        border: 1px solid #E6E6E6;
        background: #FFFFFF !important;
    }

    :deep(.arco-select-view-single) {
        box-sizing: border-box;
        width: 53% !important;
    }

    .center_btn {
        padding: 1%;

        .center_item {
            margin: 10px 10px 10px;
            //font-style: 10px;
        }
    }

    .list_content {
        flex: 1;
        padding: 0 1%;
        // transform: translateY(-40px);

    }

    .btn_controls {
        margin: 0 3px;
    }



}

:deep(.arco-table .arco-table-cell) {
    padding: 10px 3px;
}

:deep(.arco-table-td-content) {
    box-sizing: border-box;
}

.list_content_header {

    .list_item {
        font-size: 14px;
        color: #333333;
        padding: 13px 6px;
        border: 1px solid #E6E6E6;
        width: 106px;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;

    }

    .active {
        color: #6784FD
    }
}

:deep(.arco-checkbox-group) {
    display: flex !important;
    // flex-wrap: wrap;
    flex-wrap: wrap !important;

    width: 100%;


}



.notes {
    display: flex;
    flex-wrap: wrap;

    :deep(.arco-col-19) {
        flex: none !important;
        width: 100%;
        width: 300px;

    }

    :deep(.arco-checkbox-icon) {
        border-radius: 12px;
    }

    .notes_box {
        width: 300px;
        border-radius: 3px;
        border: 1px solid #E6E6E6;
        padding: 8px 0;
        box-sizing: border-box;

        .notes_item_t {
            font-size: 12px;
            color: #999999;
            border-bottom: 1px solid #F0F0F0;
            padding-left: 5px
        }

        .notes_item_b {
            font-weight: 400;
            font-size: 12px;
            color: #666666;
            padding: 0 5px;

            div {
                width: 50%;
                padding: 5px 0;
            }

            .moneys {
                color: #333333;
                font-weight: 600;
            }
        }
    }
}

:deep(.arco-form-item-content-flex) {
    align-items: normal !important;
    // transform: translateY(30px);
}

.good_item_desc {
    line-height: 40px;
    font-size: 15px;
    // font-weight: 600;
    color: #000000;
    width: 70px !important;

}
</style>