import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    isGetterRouter: false, //是否获取过路由
    isCollapsed: false, //是否折叠
    currentPath: "", // 当前路由
    userInfo: {}, //用户信息
    isGetMenu: false, //是否获取了菜单
    permissions: [],
  },
  getters: {},
  mutations: {
    changeGetterRouter(state, value) {
      state.isGetterRouter = value;
    },
    //控制侧边栏的展开
    changeCollapsed(state, payload) {
      state.isCollapsed = payload;
    },
    //设置当前路由
    setCurrentPath(state, path) {
      state.currentPath = path;
    },
    //改变菜单状态
    changeMenuStatus(state, payload) {
      state.isGetMenu = payload;
    },
    changeUserInfo(state, value) {
      state.userInfo = {
        ...state.userInfo,
        ...value,
      };
    },
    clearUserInfo(state) {
      state.userInfo = {};
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
  },
  actions: {
    //更新当前路由
    updateCurrentPath({ commit }, path) {
      commit("setCurrentPath", path);
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      paths: [
        "isCollapsed",
        "currentPath",
        "userInfo",
        "roleid",
        "isGetMenu",
        "permissions",
      ], //控制是否持久化
    }),
  ],
});
