import { createApp } from "vue";
import ArcoVue from "@arco-design/web-vue";
import App from "./App.vue";
import "@arco-design/web-vue/dist/arco.css";
import router from "./router";
import store from "./store";
import api from "./util/api";
import Common from "./util/common";
import { Message } from "@arco-design/web-vue";

const app = createApp(App);
Message._context = app._context;
// 设置全局变量
app.config.globalProperties.$Com = Common;
app.config.globalProperties.$Api = api;
app.use(ArcoVue);
app.use(store);
app.use(router);
app.mount("#app");
