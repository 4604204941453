<template>
    <div class="list_products">
        <div class="img_box">
            <img src="../../assets/feedback/icon_yijian.png" alt="">
            <div class="desc">意见反馈</div>
            <a-form :model="form" :style="{ width: '500px', margin: '20px auto', textAlign: 'center', }"
                @submit="handleSubmit" class="flex f-x-c">
                <a-form-item field="content" class="flex f-x-c" style="transform: translate(-50px);"
                    :rules="[{ required: true, message: '请输入您的意见和反馈' }]">
                    <a-textarea placeholder="请输入您的意见和反馈" :max-length="200" allow-clear show-word-limit
                        v-model="form.content" class="flex f-x-c" />
                </a-form-item>
                <a-form-item style="transform: translate(115px);">
                    <a-button html-type="submit" type="primary" size="large">提交</a-button>
                </a-form-item>
            </a-form>
        </div>

    </div>
</template>
<script setup>
import { ref, reactive, getCurrentInstance, onMounted } from 'vue';
const Message = getCurrentInstance().appContext.config.globalProperties.$message
const Api = getCurrentInstance()?.appContext.config.globalProperties.$Api
const Com = getCurrentInstance()?.appContext.config.globalProperties.$Com

const form = ref({
    content: '',
    phone: '',
});
onMounted(() => {
    console.log(JSON.parse(localStorage.getItem('userInfo')));
})
const handleSubmit = (data) => {
    console.log(data);
    form.value.phone = JSON.parse(localStorage.getItem('userInfo')).phone
    if (!form.value.content) return
    Api.feedBack({ ...form.value, userid: JSON.parse(localStorage.getItem("userInfo")).id}).then(res => {
        Message.success('提交成功')
        form.value.content = ''
    })
};

</script>

<style lang="scss" scoped>
.list_products {
    background: #FFFFFF;
    border-radius: 2px;
    height: 100%;
    box-sizing: border-box;
    padding: 15px 0;
}

.img_box {
    width: 500px;
    margin: 10% auto 25px;
    text-align: center;

    img {
        width: 114px;
        height: 114px;
    }

    .desc {
        margin-top: 25px;
        font-weight: 600;
        font-size: 20px;
        color: #829AFF;
    }

}
</style>
