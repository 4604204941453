import request from "./request";
import config from "./requrstConfig";

export default {
  //登录
  login: (params) => {
    return request.post(config.api.apiUrl + "/loginuser/login", params, {
      auth: false,
      needUserId: false, // 设置为 false 不包含 userid
    });
  },
  //数据管理
  getOneData: (params) => {
    return request.post(
      config.api.apiUrl + "/loginuser/getStatisticsOneCount",
      params,
      {
        auth: true,
      }
    );
  },
  getTwoData: (params) => {
    return request.post(
      config.api.apiUrl + "/loginuser/getOrderQuantityMonth",
      params,
      {
        auth: false,
      }
    );
  },
  getThreeData: (params) => {
    return request.post(
      config.api.apiUrl + "/loginuser/getShoporderpaymentList",
      params,
      {
        auth: false,
      }
    );
  },
  getFourData: (params) => {
    return request.post(
      config.api.apiUrl + "/loginuser/getSortSalesCount",
      params,
      {
        auth: false,
      }
    );
  },

  getFiveData: (params) => {
    return request.post(
      config.api.apiUrl + "/loginuser/getInventoryWarnings",
      params,
      {
        auth: false,
      }
    );
  },
  //编辑接口 商品
  editGoodsInfo: (params) => {
    return request.post(
      config.api.apiUrl + "/goods/updateGoodSpecsInfo",
      params,
      {
        auth: true,
      }
    );
  },
  //所有商品规格
  getGoodsList: (params) => {
    return request.post(
      // /goods/getErpstockGoodsList
      config.api.apiUrl + "/goods/getErpstockGoodsSiseList",
      params,
      {
        auth: true,
        // needUserId: false,
      }
    );
  },
  //商品列表
  getErpStockGoodsLists: (params) => {
    return request.post(
      config.api.apiUrl + "/goods/getErpstockGoodsList",
      params,
      {
        auth: false,
      }
    );
  },
   //所有客户列表
   getCustomerList: (params) => {
    return request.post(config.api.apiUrl + "/customer/list", params, {
      auth: false,
      needUserId: false,
    });
  },
  //店铺列表
  getShopList: (params) => {
    return request.post(config.api.apiUrl + "/shop/list", params, {
      auth: false,
      needUserId: false,
    });
  },
  //分类列表
  erpcategoryList: (params) => {
    return request.post(config.api.apiUrl + "/erpcategory/list", params, {
      auth: false,
    });
  },
  //仓库列表
  erpstockList: (params) => {
    return request.post(config.api.apiUrl + "/erpstock/list", params, {
      auth: false,
    });
  },
  //一键调整
  changePcGoods: (params) => {
    return request.post(
      config.api.apiUrl + "/goodssize/updatePcGoods",
      params,
      {
        auth: false,
      }
    );
  },
  updateShopPaymentInfo: (params) => {
    return request.post(config.api.apiUrl + "/shoporder/update", params, {
      auth: true,
    });
  },
  //店铺列表

  shopOrderList: (params) => {
    return request.post(
      config.api.apiUrl + "/shoporder/getOrdersList",
      params,
      {
        auth: false,
        needUserId: false,
      }
    );
  },

  //改价
  changePrice: (params) => {
    return request.post(
      config.api.apiUrl + "/goodssize/updateGoodsSizePrice",
      params,
      {
        auth: false,
      }
    );
  },

  //导出
  excal: (params) => {
    return request.post(
      config.api.apiUrl + "/goods/getExportAssociates",
      params,
      {
        auth: false,
      }
    );
  },
  //导入
  excalInner: (params) => {
    return request.post(
      config.api.apiUrl + "/goods/imoprtExcalWaterBiao",
      params,
      {
        auth: false,
      }
    );
  },
  //供应商列表
  customerList: (params) => {
    return request.post(config.api.apiUrl + "/customer/list", params, {
      auth: false,
    });
  },
  //增加商品
  addGoods: (params) => {
    return request.post(config.api.apiUrl + "/goods/addGoodsDao", params, {
      auth: false,
    });
  },
  //分类管理列表
  erpcategoryListManagement: (params) => {
    return request.post(
      config.api.apiUrl + "/erpcategory/getClassifyIofnPcList",
      params,
      {
        auth: false,
      }
    );
  },
  //仓库管理列表
  erpstockListManagement: (params) => {
    return request.post(config.api.apiUrl + "/erpstock/list", params, {
      auth: false,
    });
  },
  //新增分类
  addErpcaTeory: (params) => {
    return request.post(
      config.api.apiUrl + "/erpcategory/addErpcategory",
      params,
      {
        auth: false,
        needUserId: false, // 设置为 false 不包含 userid
      }
    );
  },
  //新增仓库
  addErpstock: (params) => {
    return request.post(config.api.apiUrl + "/erpstock/addErpstock", params, {
      auth: false,
      needUserId: false, // 设置为 false 不包含 userid
    });
  },
  //更新分类
  updateErpcaTeory: (params) => {
    return request.post(config.api.apiUrl + "/erpcategory/update", params, {
      auth: false,
    });
  },
  //更新仓库
  updateErpstock: (params) => {
    return request.post(config.api.apiUrl + "/erpstock/update", params, {
      auth: false,
    });
  },
  //编辑保存单个商品
  updateGoods: (params) => {
    return request.post(config.api.apiUrl + "/goods/updateGoodsInfo", params, {
      auth: false,
    });
  },
  //** 店铺 */
  //获取店铺信息
  getShopInfo: (params) => {
    return request.post(config.api.apiUrl + "/shop/getShopInfo", params, {
      auth: true,
      needUserId: false, // 设置为 false 不包含 userid
    });
  },
  //保存店铺信息
  saveShopInfo: (params) => {
    return request.post(config.api.apiUrl + "/shop/save", params, {
      auth: true,
    });
  },
  //更新店铺信息
  updateShopInfo: (params) => {
    return request.post(config.api.apiUrl + "/shop/update", params, {
      auth: true,
    });
  },
  //客户供应商列表
  getUseManagementList: (params) => {
    return request.post(
      config.api.apiUrl + "/customer/getCustomerList",
      params,
      {
        auth: true,
      }
    );
  },
  //PDF
  getSharePdfOrDownLoad: (params) => {
    return request.post(`${config.api.pdf}/${params.ordersn}`, params, {
      auth: true,
      headers: {
        "Content-Type": "application/pdf", // 设置 Content-Type 为 application/pdf
      },
    });
  },

  //收款信息
  payMentInfo: (params) => {
    return request.post(
      config.api.apiUrl + "/stockorder/getOrdersnCustomerInfo",
      params,
      {
        auth: true,
        needUserId: false, // 设置为 false 不包含 userid
      }
    );
  },
  //保存编辑供应商 客户
  savePeoPle: (params) => {
    return request.post(config.api.apiUrl + "/customer/update", params, {
      auth: true,
    });
  },
  //删除供应商 客户
  delPeoPle: (params) => {
    return request.post(config.api.apiUrl + "/customer/delete", params, {
      auth: true,
    });
  }, //制单人列表
  getSheetUserList: (params) => {
    return request.post(
      config.api.apiUrl + " /stockorder/getSheetusernameList",
      params,
      {
        auth: true,
      }
    );
  },
  newPeoPleAdd: (params) => {
    return request.post(config.api.apiUrl + "/customer/save", params, {
      auth: true,
    });
  },
  //收款
  proceeds: (params) => {
    return request.post(
      config.api.apiUrl + " /stockorderpayment/updateStockorderpayment",
      params,
      {
        auth: true,
        needUserId: false, // 设置为 false 不包含 userid
      }
    );
  },
  //投诉建议
  feedBack: (params) => {
    return request.post(config.api.apiUrl + "/tipoff/save", params, {
      auth: true,
      needUserId: false, // 设置为 false 不包含 userid
    });
  },
  //收款列表
  payMenRealList: (params) => {
    return request.post(
      config.api.apiUrl + "/stockorderpayment/getStockorderpaymentList",
      params,
      {
        auth: true,
        needUserId: false, // 设置为 false 不包含 userid
      }
    );
  },
  //开单列表
  getStockorderList: (params) => {
    return request.post(
      config.api.apiUrl + "/stockorder/getStockorderList",
      params,
      {
        auth: true,
      }
    );
  },
  //删除仓库开单信息
  deleteStockorderInfo: (params) => {
    return request.post(config.api.apiUrl + "/stockorder/delete", params, {
      auth: true,
    });
  },
  //制单人列表
  orderList: (params) => {
    return request.post(
      config.api.apiUrl + "/shoporderpayment/getOrdersPaymentList",
      params,
      {
        auth: true,
        needUserId: false, // 设置为 false 不包含 userid
      }
    );
  },
  //退款
  TKOrder: (params) => {
    return request.post(config.api.apiUrl + "/shoporder/storeRefunds", params, {
      auth: true,
    });
  },
  //删除订单
  delOrder: (params) => {
    return request.post(
      config.api.apiUrl + "/shoporderpayment/delete",
      params,
      {
        auth: true,
      }
    );
  },

  saveStockorderInfo: (params) => {
    return request.post(config.api.apiUrl + "/stockorder/addBilling", params, {
      auth: true,
    });
  },
  //查询开单详情信息
  getStockorderInfoByOrderSn: (params) => {
    return request.post(
      config.api.apiUrl + "/stockorder/getBillingDetails",
      params,
      {
        needUserId: false, // 设置为 false 不包含 userid
      }
    );
  },
  //PDF
  getSharePdfOrDownLoad: (params) => {
    return request.post(`${config.api.pdf}/${params.ordersn}`, params, {
      auth: true,
    });
  },

  //新增的配置角色权限接口
  //角色列表
  getRoleList: (params) => {
    return request.post(config.api.apiUrl + "/ebSystemRole/list", params, {
      needUserId: false, // 设置为 false 不包含 userid
    });
  },
  //更改状态
  updateRoleStatus: (params) => {
    return request.post(config.api.apiUrl + "/ebSystemRole/update", params, {
      needUserId: false, // 设置为 false 不包含 userid
    });
  },
  //删除角色
  deleteRole: (params) => {
    return request.post(config.api.apiUrl + "/ebSystemRole/delete", params, {
      // needUserId: false, // 设置为 false 不包含 userid
    });
  },
  //获取权限
  power: (params) => {
    return request.post(`${config.api.power}/${params.userid}`, params, {
      auth: true,
    });
  },
  //添加角色
  addRole: (params) => {
    return request.post(config.api.apiUrl + "/ebSystemRole/save", params, {
      needUserId: false, // 设置为 false 不包含 userid
    });
  },
  //运营列表
  specRoleList: (params) => {
    return request.post(
      config.api.apiUrl + "/ebSystemRole/geRoleList",
      params,
      {
        needUserId: false, // 设置为 false 不包含 userid
      }
    );
  },
  //添加用户（运营）
  addUser: (params) => {
    return request.post(config.api.apiUrl + "/loginuser/save", params, {
      needUserId: false, // 设置为 false 不包含 userid
    });
  },
  //添加用户（运营）
  updateUserStatus: (params) => {
    return request.post(config.api.apiUrl + "/loginuser/update", params, {
      needUserId: false, // 设置为 false 不包含 userid
    });
  },
  //删除用户（运营）
  delRole: (params) => {
    return request.post(config.api.apiUrl + "/loginuser/delete", params, {
      needUserId: false, // 设置为 false 不包含 userid
    });
  },
  //查询是否存在该用户
  searchUser: (params) => {
    return request.post(config.api.apiUrl + "/loginuser/info", params, {
      needUserId: false, // 设置为 false 不包含 userid
    });
  },
  //广告和视频
  getAdsAndVideo: (params) => {
    return request.post(config.api.apiUrl + "/carousel/list", params, {
      auth: true,
      needUserId: false, // 设置为 false 不包含 userid
    });
  },
  //保存图片和视频
  //广告和视频
  saveAdsAndVideo: (params) => {
    return request.post(config.api.apiUrl + "/carousel/save", params, {
      auth: true,
      needUserId: false, // 设置为 false 不包含 userid
    });
  },
  //修改上下架状态
  updateAdsAndVideo: (params) => {
    return request.post(config.api.apiUrl + "/carousel/update", params, {
      auth: true,
      needUserId: false, // 设置为 false 不包含 userid
    });
  },
  //删除
  deleteAdsAndVideo: (params) => {
    return request.post(config.api.apiUrl + "/carousel/delete", params, {
      auth: true,
      needUserId: false, // 设置为 false 不包含 userid
    });
  },
};
