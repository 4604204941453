// 返回逗号分隔的字符串中的第一个图像URL
const getFirstImage = (imgString) => {
  if (imgString) {
    if (imgString.includes(",")) {
      return imgString.split(",")[0];
    } else {
      return imgString;
    }
  } else {
    return "https://cdn.uviewui.com/uview/album/1.jpg";
  }
};

export default {
  getFirstImage,
};
