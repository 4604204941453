// config.pc.js

var host = "https://ycapi.yidianchen.com/api/rest/v1"; // 正式
// var host = "http://192.168.2.26:9999/api/rest/v1"; // 线下
// var host = "https://654v7l1019.vicp.fun/api/rest/v1";
//
var imgHost = host;

module.exports = {
  api: {
    apiUrl: host,
    pdf: "https://ycapi.yidianchen.com/api/sys/common/getStockorderPdf", //正式
    power: "https://ycapi.yidianchen.com/api/sys/common/getMenus",
    // power: "https://654v7l1019.vicp.fun/api/sys/common/getMenus",
    // pdf: "https://654v7l1019.vicp.fun/api/sys/common/getStockorderPdf",
    serviceUrl: host + "/api",
    uploadUrl: host + "/api/upload/image",
    uploadVideo: host + "/api/upload/video",
  },
};
