<template>
    <div class="list_products flex f-c">
        <div class="search_header flex f-w a-i-c">
            <a-col :span="6">
                <div class="flex a-i-c">
                    <div class="item_desc desc_right">开票日期:</div>
                    <a-date-picker
                        style="width: 248px;height: 40px;background-color: #ffffff;border: 1px solid #E6E6E6; "
                        placeholder="请选择开票日期" v-model="paramsData.payfinishtime">
                    </a-date-picker>
                </div>
            </a-col>

            <a-col :span="6">

                <div class=""> <a-input :style="{ width: '80%' }" placeholder="请输入客户名称" allow-clear
                        v-model="paramsData.customerName">
                        <template #prepend>
                            客户名称:
                        </template>
                    </a-input></div>

            </a-col>

            <a-col :span="6">
                <div class=""> <a-input :style="{ width: '80%' }" placeholder="请输入联系方式" allow-clear
                        v-model="paramsData.mobile">
                        <template #prepend>
                            联系方式:
                        </template>
                    </a-input></div>

            </a-col>
            <a-col :span="6">
                <div class="item flex a-i-c">
                    <div class="item_desc desc_right">制单人:</div>
                    <!-- <a-select :style="{ width: '160px' }" placeholder="请输入制单人" v-model="currentSheetUser">
                        <a-option v-for="value of sheetUserList">{{ value.name }}</a-option>
                    </a-select> -->
                    <a-input :style="{ width: '60%' }" placeholder="请输入制单人" allow-clear
                        v-model="paramsData.sheetusername">
                    </a-input>
                </div>
            </a-col>
            <div class="flex a-i-c" style="width: 100%; ">
                <a-col :span="8">
                    <div class="flex " style="margin-top: 30px">
                        <div style="white-space: nowrap">

                            订单类型
                        </div>
                        <a-radio-group v-model="selectTypes" :options="typeOptions">
                        </a-radio-group>
                    </div>
                </a-col>
                <a-col :span="8">
                    <div class="flex " style="margin-top: 30px">
                        <div style="white-space: nowrap">
                            付款状态
                        </div>
                        <a-radio-group v-model="selectStatus" :options="refundStatusOptions">
                        </a-radio-group>
                    </div>
                </a-col>

                <a-col :span="2">
                    <div class="item btn">
                        <a-button type="primary" @click="handlePageChange(1, 10)"
                            style="height: 40px;transform: translate(30px);">
                            <template #icon>
                                <icon-search />
                            </template>
                            搜索
                        </a-button>
                    </div>
                </a-col>
                <a-col :span="2" style="margin-left: 1%;">

                    <div class="item btn">
                        <a-button type="primary" status="success" @click="reset" style="height: 40px;">
                            <template #icon>
                                <icon-refresh />
                            </template>
                            重置
                        </a-button>
                    </div>

                </a-col>
            </div>
        </div>
        <div class="center_btn flex f-x-b">
            <div class="flex">
                <!-- <div class="center_item">
                    <a-badge :count="2" :dotStyle="{ width: '20px', height: '20px' }">
                        <a-button type="primary" @click="customizedProducts = true">
                            <template #icon>

                                <icon-stamp />
                            </template>
                            审核
                        </a-button>
                    </a-badge>

                </div> -->
            </div>
            <div class="flex">

                <div class="center_item">
                    <a-button type="outline" status="warning" @click="printEvent" style="height: 40px;">
                        <template #icon>
                            <icon-printer />

                        </template>
                        打印
                    </a-button>
                </div>

                <div class="center_item">
                    <a-button type="primary" status="warning" @click="addShop" style="height: 40px;">
                        <template #icon>
                            <icon-plus />
                        </template>
                        添加
                    </a-button>
                </div>
            </div>
        </div>
        <div class="list_content">
            <a-space direction="vertical" size="large" fill>
                <a-table :columns="columns" :data="datas" :row-selection="rowSelection" :row-key="'ordersn'"
                    @selectAll="selectedAllKeysEvent" @select="selectedEvent" v-model:selectedKeys="selectedKeys"
                    :pagination="pagination" :bordered="false" :stripe="true">
                    <template #ordersn="{ record, rowIndex }">
                        <!-- <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.ordersn" />
                        </span>
                        <span v-else> -->
                        {{ record.ordersn }}
                        <!-- </span> -->
                    </template>
                    <template #updatetime="{ record, rowIndex }">
                        <!-- <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.updatetime" />
                        </span> -->
                        <!-- <span v-else> -->
                        {{ record.updatetime }}
                        <!-- </span> -->
                    </template> <template #qty="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.qty" />
                        </span>
                        <span v-else>
                            {{ record.qty }}
                        </span>
                    </template>
                    <template #amount="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.amount" />
                        </span>
                        <span v-else>
                            {{ record.amount }}
                        </span>
                    </template> <template #username="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.username" />
                        </span>
                        <span v-else>
                            {{ record.username }}
                        </span>
                    </template> <template #mobile="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.mobile" />
                        </span>
                        <span v-else>
                            {{ record.mobile }}
                        </span>
                    </template> <template #sheetusername="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.sheetusername" />
                        </span>
                        <span v-else>
                            {{ record.sheetusername }}
                        </span>
                    </template> <template #paytype="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.paytype" />
                        </span>
                        <span v-else>
                            {{ record.paytype == 0 ? '对公' : '对私' }}
                        </span>
                    </template> <template #paystatus="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.paystatus" />
                        </span>
                        <span v-else>
                            {{
                                record.paystatus == 1
                                    ? "已付款" : record.paystatus == 6
                                        ? "草稿"
                                        : record.paystatus == 0
                                            ? "未付款"
                                            : record.paystatus == 5
                                                ? "预付款"
                                                : ""
                            }}
                        </span>
                    </template> <template #payamount="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.payamount" />
                        </span>
                        <span v-else>
                            {{ record.payamount ? record.payamount : 0 }}
                        </span>
                    </template>
                    <template #remark="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.remark" />
                        </span>
                        <span v-else>
                            {{ record.remark ? record.remark : '' }}
                        </span>
                    </template>
                    <template #controls="{ record, rowIndex }">
                        <div class="flex f-x-c" v-if="record.editable" style="white-space: nowrap;">
                            <a-button type="primary" @click="saveRecord(record)">保存</a-button>
                            <a-button status="danger" @click="cancelRecord(record.id)">取消</a-button>
                        </div>
                        <div v-else class="flex">
                            <a-button v-if="record.paystatus == 6" @click="draftOpen(record)">草稿</a-button>
                            <a-button v-else type="primary" @click="look(record)">查看</a-button>
                            <!-- @click="editRecord(record.id)" -->
                            <a-button status="danger" @click="deleteRecord(record.id)"
                                style="transform: translateX(15px)">删除</a-button>
                        </div>
                    </template>
                </a-table>
            </a-space>
        </div>
        <!-- 添加商品弹窗  -->
        <a-modal :footer="null" v-model:visible="customizedProducts" :width="1500" title="添加" alignCenter draggable
            destroyOnClose escToClose @cancel="closeAddShopPop">
            <a-form :model="customizedProductsForm" @submit="handleSubmitCustomizedProductsForm">
                <div class="flex ">
                    <a-form-item field="updatetime" :rules="[{ required: true, message: '请选择开票日期' }]">
                        <div class="flex a-i-c">
                            <div class="good_item_desc desc_right" style="white-space: nowrap;">开票日期:</div>
                            <a-date-picker v-model="customizedProductsForm.updatetime" @change="confirmTime"
                                style="width: 200px;height: 40px;background-color: #ffffff;border: 1px solid #E6E6E6; "
                                placeholder="请选择开票日期">
                            </a-date-picker>
                        </div>
                    </a-form-item>
                    <a-form-item field="username">
                        <!-- :rules="[{ required: true, message: '请输入客户名称' }]" -->
                        <div class="good_item_desc desc_right">客户:</div>
                        <a-input :style="{ width: '150px' }" v-model="customizedProductsForm.username"
                            placeholder="请输入客户名称" allow-clear />
                    </a-form-item>
                    <!-- :rules="[{ required: true, message: '请输入联系方式' }]" -->
                    <a-form-item field="mobile">
                        <div class="good_item_desc">联系方式:</div>
                        <a-input :style="{ width: '170px' }" v-model="customizedProductsForm.mobile"
                            placeholder="请输入联系方式" allow-clear />
                    </a-form-item>
                    <a-form-item field="address" :rules="[{ required: true, message: '请输入地址信息' }]">
                        <div class="good_item_desc">地址:</div>
                        <a-input :style="{ width: '170px' }" v-model="customizedProductsForm.address"
                            placeholder="请输入地址信息" allow-clear />
                    </a-form-item>
                    <a-form-item field="sheetusername" :rules="[{ required: true, message: '请选择制单人' }]">
                        <div class="good_item_desc">制单人:</div>
                        <!-- <a-select :style="{ width: '160px' }" placeholder="请输入制单人"
                            v-model="customizedProductsForm.sheetusername">
                            <a-option v-for="value of sheetUserList">{{ value.name }}</a-option>
                        </a-select> -->
                        <a-input :style="{ width: '80%' }" placeholder="请输入制单人" allow-clear
                            v-model="customizedProductsForm.sheetusername">
                        </a-input>
                    </a-form-item>
                </div>
                <div class="flex">
                    <div class="flex f-x-a a-i-c">
                        <a-form-item field="qty" :rules="[{ required: true, message: '请输入数量' }]">
                            <div class="good_item_desc">数量:</div>
                            <a-input :style="{ width: '220px' }" v-model="customizedProductsForm.qty"
                                placeholder="请输入数量" allow-clear />
                        </a-form-item>
                    </div>
                    <a-form-item field="amount" :rules="[{ required: true, message: '请输入金额' }]">
                        <div class="good_item_desc">金额:</div>
                        <a-input-number v-model="customizedProductsForm.amount"
                            :style="{ padding: '0 5px !important', border: '1px solid #E6E6E6 !important', width: '220px !important', textAlign: 'center' }"
                            class="input-demo" :min="1" hide-button="true"
                            :placeholder="customizedProductsForm.amount ? customizedProductsForm.amount : '请输入金额'">
                            <template #prefix>
                                <span class="money_icon" style="color: red; font-size: 18px;">¥</span>
                            </template>
                        </a-input-number>
                    </a-form-item>
                    <a-form-item field="payamount" :rules="[{ required: true, message: '请输入已付金额' }]">
                        <div class="good_item_desc">已付金额:</div>
                        <a-input-number v-model="customizedProductsForm.payamount"
                            :style="{ padding: '0 5px !important', border: '1px solid #E6E6E6 !important', width: '220px !important', textAlign: 'center' }"
                            class="input-demo" :min="1" hide-button="true"
                            :placeholder="customizedProductsForm.payamount ? customizedProductsForm.payamount : '请输入已付金额'">
                            <template #prefix>
                                <span class="money_icon" style="color: red; font-size: 18px;">¥</span>
                            </template>
                        </a-input-number>
                    </a-form-item>
                    <a-form-item field="remark">
                        <div class="good_item_desc">备注1:</div>
                        <a-input-number v-model="customizedProductsForm.remark"
                            :style="{ padding: '0 5px !important', border: '1px solid #E6E6E6 !important', width: '220px !important', textAlign: 'center' }"
                            class="input-demo" :min="1" hide-button="true" placeholder="请输入备注">
                            <!-- <template #prefix>
                                <span class="money_icon" style="color: red; font-size: 18px;">¥</span>
                            </template> -->
                        </a-input-number>
                    </a-form-item>
                </div>
                <div class="flex f-x-s">
                    <a-form-item field="costOfLabor">
                        <div class="flex">
                            <div style="white-space: nowrap">
                                订单类型
                            </div>
                            <a-radio-group v-model="selectTypes" :options="typeOptions"
                                v-if="!customizedProductsForm.openLook">
                            </a-radio-group>
                            <span v-if="customizedProductsForm.openLook">
                                {{ customizedProductsForm.paytype == 0 ? ':对公' : ':对私' }}
                            </span>
                        </div>
                    </a-form-item>

                    <a-form-item field="costOfLabor">
                        <div class="flex ">
                            <div style="white-space: nowrap;">
                                付款状态
                            </div>
                            <a-radio-group v-model="selectStatus" :options="refundStatusOptions"
                                v-if="!customizedProductsForm.openLook" @change="changePayStatus">
                            </a-radio-group>
                            <span v-if="customizedProductsForm.openLook">
                                {{ customizedProductsForm.paystatus == 0 ? ':未付款' : customizedProductsForm.paystatus ==
                                    1 ?
                                    ':已付款' : customizedProductsForm.paystatus == 5 ? ':预付款'
                                        : '' }}
                            </span>
                        </div>
                    </a-form-item>
                </div>
                <a-table :columns="customizedProductsColumns" :data="customizedProductsData"
                    :bordered="{ wrapper: true, cell: true }" style="margin-top: 20px;">
                    <template #name="{ record, rowIndex }">
                        <a-image width="30" height="30" :src="Com.getFirstImage(record.img)" show-loader
                            v-if="record.img" />
                        {{ record.name }}
                    </template>
                    <template #erpcategoryName="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.erpcategoryName"
                                @change="e => handleChange(e.target.value, record.key, col)" />
                        </span>
                        <span v-else>
                            {{ record.erpcategoryName }}
                        </span>
                    </template>
                    <template #models="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.models" />
                        </span>
                        <span v-else>
                            {{ record.models }}
                        </span> </template>
                    <template #quantity="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.quantity" />
                        </span>
                        <span v-else>
                            {{ record.quantity }}
                        </span> </template>
                    <template #unitPrice="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.unitPrice" />
                        </span>
                        <span v-else>
                            {{ record.unitPrice }}
                        </span> </template>
                    <template #amount="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.amount" />
                        </span>
                        <span v-else>
                            {{ record.amount }}
                        </span> </template>
                    <template #remarks="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0;" v-model="record.remarks" />
                        </span>
                        <span v-else>
                            {{ record.remarks }}
                        </span> </template>
                    <template #operation="{ record, rowIndex }">
                        <div class="editable-row-operations">
                            <div class="flex f-x-c" v-if="record.editable">
                                <a-button @click="save(record.key)" type="primary" size="large">保存</a-button>
                                <a-button type="outline" status="primary" size="large" style="margin-left: 15px;"
                                    @click="cancel(record.key)">
                                    取消</a-button>
                            </div>
                            <div class="flex f-x-c" v-else style="cursor: pointer">
                                <!-- <span>
                                    <icon-plus @click="addCustomizedRecord(record, rowIndex)" size="25" />
                                </span> -->
                                <span>
                                    <icon-minus size="25" @click="delCustomizedRecord(record, rowIndex)" />
                                </span>
                            </div>
                        </div>
                    </template>
                    <template #footer>
                        <span style="color: #FD9C65;cursor: pointer;" @click="selectDz = true"> 点击录入</span>
                    </template>
                </a-table>
                <a-form-item v-if="lookForHiddenBtn">
                    <div style="width: 75%;margin-top: 20px; text-align: center;">
                        <a-button type="outline" status="primary" size="large" style="margin-right: 20px;"
                            @click="customizedProducts = false">取消</a-button>
                        <a-button html-type="submit" type="primary" size="large"
                            style="margin-right: 20px;">生成订单</a-button>
                        <a-button @click="draft()" type="primary" size="large"
                            style="margin-right: 20px">生成草稿</a-button>

                        <a-button type="primary" status="warning" size="large"
                            @click="submitAndTakePhoto">提交并打印</a-button>
                    </div>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal :footer="null" v-model:visible="selectDz" :width="1300" title="选择开单商品" alignCenter draggable
            escToClose>
            <div class="search_header flex f-w a-i-c">
                <a-col :span="6">
                    <div class="item"> <a-input :style="{ width: '80%' }" placeholder="请输入商品名称" allow-clear
                            v-model="paramsData.name">
                            <template #prepend>
                                &nbsp&nbsp商品名称:
                            </template>
                        </a-input></div>
                </a-col>
                <a-col :span="6">
                    <div class="item flex a-i-c">
                        <div class="item_desc  desc_width_two">商品分类:</div>
                        <a-select :style="{ width: '160px' }" value-key="id" placeholder="请选择商品分类" allow-clear
                            v-model="selectCategoryVal" @change="categoryChange" :loading="categoryLoading">
                            <a-option v-for="item of categoryOptions" :value="item" :label="item.name" />
                        </a-select>
                        <!-- :virtual-list-props="{ height: 250 }" -->
                    </div>
                </a-col>
                <a-col :span="6">
                    <div class="item flex a-i-c">
                        <div class="item_desc desc_right">所在仓库:</div>
                        <a-select :style="{ width: '160px' }" value-key="id" placeholder="请选择所在仓库"
                            :loading="erpstockLoading" allow-clear v-model="selectErpstockVal" @change="erpstockChange">
                            <a-option v-for="item of erpstockOptions" :value="item" :label="item.name" />
                        </a-select>

                    </div>
                </a-col>
                <a-col :span="6">
                    <div class="item flex a-i-c">
                        <div class="item_desc desc_right">供应商:</div>
                        <a-select :style="{ width: '160px' }" value-key="id" placeholder="请选择供应商"
                            :loading="customerLoading" allow-clear v-model="selectCustomerVal" @change="customerChange">
                            <a-option v-for="item of customerOptions" :value="item" :label="item.name" />
                        </a-select>
                    </div>
                </a-col>
                <div style="margin: 20px 0;padding-left: 20px" class="flex a-i-c">

                    <a-col :span="6">
                        <div class="item flex a-i-c mt">
                            <div class="item_desc  desc_width">价格区间:</div>
                            <a-input-number v-model="paramsData.start_price" :style="{ width: '24%' }"
                                class="input-demo" :min="1" hide-button="true">
                                <template #prefix>
                                    <span class="money_icon">¥</span>
                                </template>
                            </a-input-number>
                            <span class="nbsp">~</span>
                            <a-input-number v-model="paramsData.end_price" :style="{ width: '24%' }" class="input-demo"
                                :min="1" hide-button="true">
                                <template #prefix>
                                    <span class="money_icon">¥</span>
                                </template>
                            </a-input-number>
                        </div>
                    </a-col>
                    <a-col :span="6">
                        <div class="item flex a-i-c mt">
                            <div class="item_desc desc_right_t">库存数量区间:</div>
                            <a-input-number v-model="paramsData.start_stock" :style="{ width: '24%' }" placeholder=""
                                class="input-demo" :min="1" hide-button="true" /> <span class="nbsp">~</span>
                            <a-input-number v-model="paramsData.end_stock" :style="{ width: '24%' }" placeholder=""
                                class="input-demo" :min="1" hide-button="true" />
                        </div>
                    </a-col>
                    <a-col :span="2">
                        <div class="item btn" @click="handlePageChangeDZ(1, 10)">
                            <a-button type="primary">
                                <template #icon>
                                    <icon-search />
                                </template>
                                搜索
                            </a-button>
                        </div>
                    </a-col>
                    <a-col :span="2">
                        <div class="item btn" @click="reset">
                            <a-button type="primary" status="success">
                                <template #icon>
                                    <icon-refresh />
                                </template>
                                重置
                            </a-button>
                        </div>

                    </a-col>
                </div>
            </div>
            <div class="list_content">
                <!-- // <a-spin /> -->
                <a-space direction="vertical" size="large" fill>
                    <a-table :columns="columnsDZ" :data="datasDZ" :row-selection="rowSelection" :row-key="'id'"
                        @selectAll="selectedAllKeysEventDZ" @select="selectedEventDZ"
                        v-model:selectedKeys="selectedKeys" :pagination="paginationDZ"
                        :bordered="{ wrapper: true, cell: true }">
                        <!-- //商品名称 -->
                        <template #name="{ record, rowIndex }">
                            <span>
                                <a-image width="30" height="30" :src="record.firstImg" /> {{ record.name }} </span>
                        </template>
                        <!-- //型号 -->
                        <template #model="{ record, rowIndex }">
                            {{ record.model }}
                        </template>
                        <!-- //单位 -->
                        <template #unit="{ record, rowIndex }">
                            {{ record.unit }}
                        </template>
                        <!-- //数量 -->
                        <template #selectQty="{ record, rowIndex }">
                            {{ record.selectQty }}
                        </template>

                        <!-- //价格-->
                        <template #price="{ record, rowIndex }">

                            {{ record.price }}
                        </template>
                        <template #totalPrice="{ record, rowIndex }">
                            {{ Number(record.selectQty) * Number(record.price) }}
                        </template>
                        <!-- 操作 -->
                        <template #controls="{ record, rowIndex }">
                            <div class="flex f-x-c" style="cursor: pointer">
                                <span>
                                    <icon-plus @click="changeSelectQty(record, 'add')" size="25" />
                                </span>
                                <span style="margin-left: 15px;">
                                    <icon-minus size="25" @click="changeSelectQty(record, 'sub')" />
                                </span>
                            </div>
                        </template>
                        <!-- <template #footer> 
                            <span>点击录入</span>
                        </template> -->
                    </a-table>
                    <a-form-item>
                        <div style="width: 100%; text-align: center;">
                            <a-button type="outline" status="primary" size="large" style="margin-right: 20px;"
                                @click="selectDz = false">取消</a-button>
                            <!-- <a-button html-type="submit" type="primary" size="large"></a-button> -->
                            <a-button type="primary" size="large" @click="confirmDZ">确定</a-button>
                        </div>
                    </a-form-item>
                </a-space>
            </div>
        </a-modal>

    </div>
</template>
<script setup>
import { ref, watch, reactive, getCurrentInstance, onMounted } from 'vue';
import { IconSearch, IconRefresh, IconPrinter, IconPlus, IconMinus, IconCodeSandbox, IconMinusCircle, IconPlusCircle, IconStamp } from '@arco-design/web-vue/es/icon';
const Message = getCurrentInstance().appContext.config.globalProperties.$message
const Api = getCurrentInstance()?.appContext.config.globalProperties.$Api
const Com = getCurrentInstance()?.appContext.config.globalProperties.$Com

onMounted(() => {
    handlePageChange()
    handlePageChangeDZ()
    getSheetLists()
    getErpcategoryLists()
    getErpstockLists()
    getCustomerLists()
});

//#region
const selectCustomerVal = ref() // 单个选择供应商
const customerOptions = ref([])
const customerLoading = ref(true)

const selectErpstockVal = ref() // 单个选择仓库
const erpstockOptions = ref([])
const erpstockLoading = ref(true)

const selectCategoryVal = ref()//单个选择分类
const categoryOptions = ref([])
const categoryLoading = ref(true)

const getCustomerLists = () => {
    Api.customerList({ pagesize: 100, }).then(res => {
        // status: 0 
        customerOptions.value = res.data.datalist
        customerLoading.value = false

        console.log(customerOptions.value);
    })
        .catch(error => {
            // 处理请求失败的逻辑
            console.error('Error fetching data:', error);
        });
}
const customerChange = () => {
    if (selectCustomerVal.value.id === undefined) {
        paramsData.value.customerid = '';
    } else {
        paramsData.value.customerid = selectCustomerVal.value.id;
    }
    console.log('供应商id', paramsData.value.customerid);
}

const getErpstockLists = () => {
    Api.erpstockList({ pagesize: 100, }).then(res => {
        // status: 0 
        erpstockOptions.value = res.data.datalist
        erpstockLoading.value = false

        console.log(erpstockOptions.value);
    })
        .catch(error => {
            // 处理请求失败的逻辑
            console.error('Error fetching data:', error);
        });
}
const erpstockChange = () => {
    if (selectErpstockVal.value.id === undefined) {
        paramsData.value.erpstockid = '';
    } else {
        paramsData.value.erpstockid = selectErpstockVal.value.id;
    }
    console.log('仓库id', paramsData.value.erpstockid);

}
const getErpcategoryLists = () => {
    Api.erpcategoryList({ pagesize: 100, }).then(res => {
        // status: 0 
        categoryOptions.value = res.data.datalist
        categoryLoading.value = false
        console.log('分类id', categoryOptions.value);
    })
        .catch(error => {
            // 处理请求失败的逻辑
            console.error('Error fetching data:', error);
        });
}
const categoryChange = () => {
    if (selectCategoryVal.value.id === undefined) {
        paramsData.value.categoryid = '';
    } else {
        paramsData.value.categoryid = selectCategoryVal.value.id;
    }
    console.log('分类id', paramsData.value.categoryid);

}
//#endregion

const selectTypes = ref('-1');
const typeOptions = [
    { label: '全部', value: '-1' },
    { label: '对公', value: '0' },
    { label: '对私', value: '1' },
];
const selectStatus = ref('-1');
const refundStatusOptions = [
    { label: '全部', value: '-1' },
    { label: '未付款', value: '0' },
    { label: '已付款', value: '1' },
    { label: '预付款', value: '5' },

];
//#region  重制
const reset = () => {
    // currentSheetUser.value = ''
    selectTypes.value = '-1'
    selectStatus.value = '-1'
    selectCustomerVal.value = ''
    selectCustomerVal.value = ''
    selectCategoryVal.value = ''
    paramsData.value = {
        customerName: '',//客户名称
        // starttime: '',
        // stoptime: '',
        type: 1,
        mobile: '',
        sheetusername: '',
        paytype: '',
        paystatus: '',
        payfinishtime: '',
        categoryid: '',//商品分类
        erpstockid: '',//所在仓库
        customerid: '',//供应商
    }
}
//#endregion  
//#region 列表功能**//
const paramsData = ref({
    customerName: '',//客户名称
    // starttime: '',
    // stoptime: '',
    type: 1,
    mobile: '',
    sheetusername: '',
    paytype: '',
    paystatus: '',
    payfinishtime: '',
    categoryid: '',//商品分类
    erpstockid: '',//所在仓库
    customerid: '',//供应商
})
const columns = [{
    title: '单据编号',
    dataIndex: 'ordersn',
    slotName: 'ordersn'
}, {
    title: '开票日期',
    dataIndex: 'updatetime',
    slotName: 'updatetime'

},
{
    title: '数量',
    dataIndex: 'qty',
    slotName: 'qty'

},
{
    title: '金额',
    dataIndex: 'amount',
    slotName: 'amount'

},
{
    title: '客户',
    dataIndex: 'username',
    slotName: 'username'

}, {
    title: '联系方式',
    dataIndex: 'mobile',
    slotName: 'mobile'

}, {
    title: '制单人',
    dataIndex: 'sheetusername',
    slotName: 'sheetusername'

},
//  {
//     title: '审核人',
//     dataIndex: 'inprice',
//     slotName: 'inprice'

// },
{
    title: '订单类型',
    dataIndex: 'paytype',
    slotName: 'paytype'

}, {
    title: '付款状态',
    dataIndex: 'paystatus',
    slotName: 'paystatus'

}, {
    title: '付款金额',
    dataIndex: 'payamount',
    slotName: 'payamount'

}, {
    title: '备注',
    dataIndex: 'remark',
    slotName: 'remark'

},
{
    title: '操作',
    slotName: 'controls'
}
];
const columnsDZ = [
    {
        title: '商品',
        dataIndex: 'name',
        slotName: 'name',
        width: 200

    },
    {
        title: '单位',
        dataIndex: 'unit',
        slotName: 'unit',
        width: 140


    },
    {
        title: '型号',
        dataIndex: 'model',
        slotName: 'model',
        width: 140


    },
    {
        title: '数量',
        dataIndex: 'selectQty',
        slotName: 'selectQty',
        width: 140


    },
    {
        title: '销售价',
        dataIndex: 'price',
        slotName: 'price',
        width: 140


    }, {
        title: '金额',
        dataIndex: 'totalPrice',
        slotName: 'totalPrice',
        width: 140


    },
    {
        title: '备注',
        dataIndex: 'remark',
        slotName: 'remark',
        width: 140


    },
    {
        title: '',
        slotName: 'controls'
    }
];
const datas = ref([])
const datasDZ = ref([])
const selectedKeys = ref([]);
const sheetUserList = ref([])
// const currentSheetUser = ref()
const getSheetLists = () => {
    Api.getSheetUserList().then(res => {
        sheetUserList.value = res.data.datalist
    })
}
const rowSelection = reactive({
    type: 'checkbox',
    showCheckedAll: true,
    onlyCurrent: false,
    selectedRowKeys: selectedKeys.value, // 当前选中的行 key 数组
    onChange: (selectedRowKeys, selectedRows, record) => {
        selectedKeys.value = selectedRowKeys;
        console.log('selectedRows:', selectedRows, record);
    }
});
//分页
const pagination = ref({
    total: 0,  // 数据总条目数
    showTotal: true,            // 是否显示总条目数
    defaultPageSize: 10,        // 默认每页显示条目数
    pageSize: 10,               // 当前每页显示条目数
    current: 1,                 // 当前页码
    showJumper: true,
    onChange: (page, pageSize) => {
        handlePageChange(page, pageSize);
    }
});

const handlePageChange = (page, pageSize) => {
    // console.log(selectTypes.value, selectStatus.value);
    // if (currentSheetUser.value) {
    //     paramsData.value.sheetusername = currentSheetUser.value
    // }
    if (selectTypes.value == -1) {
        paramsData.value.paytype = ''
    } else {
        paramsData.value.paytype = selectTypes.value

    }
    if (selectStatus.value == -1) {
        paramsData.value.paystatus = ''

    } else {
        paramsData.value.paystatus = selectStatus.value

    }
    console.log('page:', page, 'pageSize:', pageSize);
    const pageIndex = page ? page : pagination.value.current;
    paramsData.value.type = 1
    Api.getStockorderList({
        pageIndex: pageIndex,
        status: 0,
        ...paramsData.value,
        userid: JSON.parse(localStorage.getItem("userInfo")).id,
    }).then(res => {
        console.log(res);
        pagination.value.current = page;        // 更新当前页码
        res.data.datalist.forEach(item => {
            item.editable = false;
        });
        pagination.value.total = res.data.total; // 更新总条目数
        datas.value = [...res.data.datalist];
        console.log(datas.value);
    }).catch(error => {
        console.error('Error fetching data:', error);
    });
};
const confirmTime = (e) => {
    console.log(e);
}
//分页
const paginationDZ = ref({
    total: 0,  // 数据总条目数
    showTotal: true,            // 是否显示总条目数
    defaultPageSize: 10,        // 默认每页显示条目数
    pageSize: 10,               // 当前每页显示条目数
    current: 1,                 // 当前页码
    showJumper: true,
    onChange: (page, pageSize) => {
        handlePageChangeDZ(page, pageSize)
    }
});
const handlePageChangeDZ = (page, pageSize) => {
    const pageIndex = page ? page : paginationDZ.value.current;
    paramsData.value.type = 0
    Api.getGoodsList({
        pageIndex: pageIndex,
        status: 0,
        ...paramsData.value,
        userid: JSON.parse(localStorage.getItem("userInfo")).id,
    }).then(res => {
        console.log(res);
        paginationDZ.value.current = page;        // 更新当前页码
        res.data.datalist.forEach(item => {
            item.editable = false;
            item.firstImg = Com.getFirstImage(item.img)
            item.selectQty = 1

        });
        paginationDZ.value.total = res.data.total; // 更新总条目数
        datasDZ.value = [...res.data.datalist];

    }).catch(error => {
        console.error('Error fetching data:', error);
    });
};
//#endregion
//#region 编辑 删除 保存修改
const singleSelectOrderTypeVal = ref() // 单个选择供应商
const singleSelectPayTypeVal = ref() // 单个选择仓库
const selectedItems = ref([])
const selectPintItems = ref([])
const selectPintItemsString = ref('')
const singleEditForm = {
    qty: '',
    amount: '',
    mobile: '',
    sheetusername: '',
    paytype: '',
    paystatus: '',
    payamount: '',
    remark: ''
}
const selectedEvent = val => {
    selectPintItems.value = [];
    val.forEach(ordersn => {
        datas.value.forEach(item => {
            if (item.ordersn === ordersn && !selectPintItems.value.some(selectedItem => selectedItem.goodsid === item.goodsid)) {
                selectPintItems.value.push(item.ordersn);
            }
        });
    });
    selectPintItemsString.value = selectPintItems.value.join(',');
    console.log('selectPintItemsString:', val, selectPintItemsString.value);
};
const selectedAllKeysEvent = val => {
    if (val) {
        // 清空数组
        selectPintItems.value = datas.value.map(row => row.ordersn);
    } else {
        selectPintItems.value = [];
    }
    selectPintItemsString.value = selectPintItems.value.join(',');
    console.log('selectPintItemsString:', val, selectPintItemsString.value);
};
const selectedEventDZ = val => {
    selectedItems.value = [];
    val.forEach(id => {
        datasDZ.value.forEach(item => {
            // if (item.goodsid === id && !selectedItems.value.some(selectedItem => selectedItem.goodsid === item.goodsid)) {
            //     selectedItems.value.push(item);
            // }
            if (
                item.id === id
            ) {
                selectedItems.value.push(item);
            }
        });
    });
    console.log('selectedItems:', val, selectedItems.value);
};
const selectedAllKeysEventDZ = val => {
    if (val) {
        // 清空数组
        selectedItems.value = datasDZ.value.map(row => row);
    } else {
        selectedItems.value = [];
    }
    console.log('selectedItems:', val, selectedItems.value);

}
//保存单个
const clearSingleEditFrom = () => {
    singleEditForm.value = {
        qty: '',
        amount: '',
        mobile: '',
        sheetusername: '',
        paytype: '',
        paystatus: '',
        payamount: '',
        remark: ''
    }
    singleSelectOrderTypeVal.value = ''
    singleSelectPayTypeVal.value = ''
}
const saveRecord = (item) => {
}
// 编辑单个
const editRecord = (id) => {
    datas.value.forEach(item => {
        if (item.id === id) {
            item.editable = true;
        } else {
            item.editable = false;
        }
    });
    editingKey.value = id;

};
//取消单个
const cancelRecord = (id) => {
    datas.value.forEach(item => {
        if (item.id === id) {
            item.editable = false;
        }
    });
    editingKey.value = '';
}
// 删除单个
const deleteRecord = (id) => {
    Api.deleteStockorderInfo({ ids: id, status: 1 }).then(res => {
        Message.success(res.message);
        datas.value = []
        handlePageChange(pagination.value.current === undefined ? 1 : pagination.value.current, 10)

    }).catch(error => {
        console.error('Error changing goods:', error);
    });
};
//#endregion

//#region 选择开单商品
const customizedProducts = ref(false);
const customizedProductsData = ref([])
const selectDz = ref(false);
const customizedProductsColumns = [{
    title: '商品',
    dataIndex: 'name', slotName: 'name',
}, {
    title: '型号',
    dataIndex: 'model',
    slotName: 'model'
}, {
    title: '单位',
    dataIndex: 'unit',
    slotName: 'unit'
}, {
    title: '数量',
    dataIndex: 'selectQty',
    slotName: 'selectQty'
}, {
    title: '单价',
    dataIndex: 'price',
    slotName: 'price'
}, {
    title: '金额',
    dataIndex: 'totalPrice',
    slotName: 'totalPrice'
}, {
    title: '备注',
    dataIndex: 'remark',
    slotName: 'remark'
}, { title: '', dataIndex: 'operation', slotName: 'operation' },]

const totalAmount = ref(0)
const resultTotalAmount = ref(0)
const confirmDZ = () => {
    if (!selectedItems.value) return Message.warning('请选择配件')

    totalAmount.value = 0;  // 初始化总金额
    selectedItems.value.forEach(item => {
        const totalPrice = Number(item.price) * Number(item.selectQty) || 0;
        customizedProductsData.value.push({
            name: item.name || '',
            img: item.img || '',
            model: item.model || '',
            unit: item.unit || '',
            selectQty: item.selectQty || '',
            price: item.price || '',
            totalPrice: totalPrice || '',
            remark: item.remark || ''
        });
        totalAmount.value += totalPrice;  // 累加每个配件的总金额
    });
    selectDz.value = false
    resultTotalAmount.value += totalAmount.value;  // 累加新的总金额到总的总金额字段
    // 输出计算出的总金额
    console.log('每次增加总金额:', totalAmount.value);
    console.log('表单所有总金额:', resultTotalAmount.value);
    customizedProductsForm.value.amount = resultTotalAmount.value
    // 打印 customizedProductsData 的值
    console.log(customizedProductsData.value)
}
const changeSelectQty = (item, type) => {
    if (type === 'add') {
        item.selectQty++;
    } else if (type === 'sub' && item.selectQty > 1) {
        item.selectQty--;
    }
}
const customizedProductsForm = ref({
    type: 1,
    username: '',
    mobile: '',
    sheetusername: '',
    qty: '',//数量
    amount: '',//总金额
    payamount: '',//支付金额
    remark: '',//备注
    paytype: 0, //0 对公 1对私 
    paystatus: 0,// 支付状态：0【未支付】，1【已支付】，2【申请退款】3【退款成功】4.【预收款】 5预付款
    chooseType: 0,
    address: ''


})
const delCustomizedRecord = (item, index) => {
    customizedProductsData.value.splice(index, 1);
    // 遍历 customizedProductsForm.value 里面的 totalPrice 并计算总和
    let total = 0;
    customizedProductsData.value.forEach(product => {
        total += product.totalPrice || 0;
    });
    // 将总和赋值给 resultTotalAmount.value
    resultTotalAmount.value = total;
    // 打印结果以进行调试
    console.log('删除后的总金额:', resultTotalAmount.value);
    customizedProductsForm.value.amount = resultTotalAmount.value
    changePayStatus(recordPayStatus.val)
    // console.log(item);
}
const recordPayStatus = ref(-1)
const changePayStatus = (val) => {
    // 未付款默认 0 已付款默认 总金额
    if (val == 0) {
        // console.log('未付款，默认值是 0');
        customizedProductsForm.value.payamount = 0;
    } else if (val == 1) {
        // console.log('已付款，默认值是总金额');
        customizedProductsForm.value.payamount = customizedProductsForm.value.amount;
    } else if (val == 3) {
        customizedProductsForm.value.payamount = ''
    }
    recordPayStatus.value = val
}

const draft = (res) => {
    // 如果 selectTypes 的值是 -1，则将 paytype 设置为空字符串，否则保留其原来的值
    // if (selectTypes.value == -1) {
    //     customizedProductsForm.value.paytype = '';
    // } else {
    //     customizedProductsForm.value.paytype = selectTypes.value; // 使用 selectTypes 的值
    // }
    // 如果 selectStatus 的值是 -1，则将 paystatus 设置为空字符串，否则保留其原来的值
    if (selectStatus.value == -1) {
        customizedProductsForm.value.paystatus = '';
    } else {
        customizedProductsForm.value.paystatus = selectStatus.value; // 使用 selectStatus 的值
    }
    if (!customizedProductsForm.value.sheetusername) {
        if (JSON.parse(localStorage.getItem("userInfo")).phone) {
            customizedProductsForm.value.sheetusername = JSON.parse(localStorage.getItem("userInfo")).phone
        }
    }
    customizedProductsForm.value.datalist = selectedItems.value;
    if (Array.isArray(customizedProductsForm.value.datalist)) {
        customizedProductsForm.value.datalist.forEach(item => {
            item.amount = item.price;
        });
    }
    customizedProductsForm.value.type = 1

    // 调用 API 保存数据
    Api.saveStockorderInfo({ ...customizedProductsForm.value, chooseType: 0, userid: JSON.parse(localStorage.getItem("userInfo")).id }).then(res => {
        customizedProducts.value = false;
        resultTotalAmount.value = 0; // 重置计算总金额的结果
        paramsData.value = {
            name: '',//客户名称
            // starttime: '',
            // stoptime: '',
            type: 1,
            mobile: '',
            sheetusername: '',
            paytype: '',
            paystatus: '',
            payfinishtime: '',
            start_price: '',
            end_price: '',
            start_stock: '',
            end_stock: '',
            categoryid: '',//商品分类
            erpstockid: '',//所在仓库
            customerid: '',//
        }
        handlePageChange(pagination.value.current === undefined ? 1 : pagination.value.current, 10);
        handlePageChangeDZ(pagination.value.current === undefined ? 1 : pagination.value.current, 10);
    });

    // 打印表单数据
    console.log(customizedProductsForm.value);
}
const handleSubmitCustomizedProductsForm = (res) => {
    // 如果 selectTypes 的值是 -1，则将 paytype 设置为空字符串，否则保留其原来的值
    // if (selectTypes.value == -1) {
    //     customizedProductsForm.value.paytype = '';
    // } else {
    //     customizedProductsForm.value.paytype = selectTypes.value; // 使用 selectTypes 的值
    // }
    // 如果 selectStatus 的值是 -1，则将 paystatus 设置为空字符串，否则保留其原来的值
    if (customizedProductsForm.value.paystatus == 6) {
        customizedProductsForm.value.paystatus = "0";
    } else if (selectStatus.value == -1) {
        customizedProductsForm.value.paystatus = "";
    } else {
        customizedProductsForm.value.paystatus = selectStatus.value; // 使用 selectStatus 的值
    }
    if (!customizedProductsForm.value.sheetusername) {
        if (JSON.parse(localStorage.getItem("userInfo")).phone) {
            customizedProductsForm.value.sheetusername = JSON.parse(localStorage.getItem("userInfo")).phone
        }
    }
    customizedProductsForm.value.datalist = selectedItems.value;
    if (Array.isArray(customizedProductsForm.value.datalist)) {
        customizedProductsForm.value.datalist.forEach(item => {
            item.amount = item.price;
        });
    }
    customizedProductsForm.value.type = 1
    // 调用 API 保存数据
    Api.saveStockorderInfo({ ...customizedProductsForm.value, chooseType: 1, userid: JSON.parse(localStorage.getItem("userInfo")).id }).then(res => {
        customizedProducts.value = false;
        resultTotalAmount.value = 0; // 重置计算总金额的结果
        paramsData.value = {
            name: '',//客户名称
            // starttime: '',
            // stoptime: '',
            type: 1,
            mobile: '',
            sheetusername: '',
            paytype: '',
            paystatus: '',
            payfinishtime: '',
            start_price: '',
            end_price: '',
            start_stock: '',
            end_stock: '',
            categoryid: '',//商品分类
            erpstockid: '',//所在仓库
            customerid: '',//
        }
        handlePageChange(pagination.value.current === undefined ? 1 : pagination.value.current, 10);
        handlePageChangeDZ(pagination.value.current === undefined ? 1 : pagination.value.current, 10);
    });

    // 打印表单数据
    console.log(customizedProductsForm.value);
}
const lookForHiddenBtn = ref(true)  //查看的时候隐藏操作按钮
const draftOpen = (item) => {
    Api.getStockorderInfoByOrderSn({ ordersn: item.ordersn }).then((res) => {
        customizedProductsForm.value = res.data;
        customizedProductsForm.value.openLook = true;
        res.data.datalist.forEach((item) => {
            item.editable = false;
            item.selectQty = item.qty;
            item.price = item.price;
            item.totalPrice = item.amount;
            item.unit = item.unit;
        });
        // selectTypes.value = customizedProductsForm.value.paytype
        // selectStatus.value = customizedProductsForm.value.paystatus
        customizedProductsData.value = res.data.datalist;
        console.log(customizedProductsForm.value, customizedProductsData.value);
    });
    // customizedProductsForm.value = item;
    // console.log('customizedProductsForm.value',);
    customizedProducts.value = true;
    // customizedProductsData.value = selectedItems.value;
    lookForHiddenBtn.value = true;
    console.log(customizedProductsForm.value);
};
// 查看
const look = (item) => {
    Api.getStockorderInfoByOrderSn({ ordersn: item.ordersn }).then(res => {
        customizedProductsForm.value = res.data;
        customizedProductsForm.value.openLook = true
        res.data.datalist.forEach(item => {
            item.editable = false;
            item.selectQty = item.qty
            item.price = item.price
            item.totalPrice = item.amount
            item.unit = item.unit

        })
        // selectTypes.value = customizedProductsForm.value.paytype
        // selectStatus.value = customizedProductsForm.value.paystatus
        customizedProductsData.value = res.data.datalist
        console.log(customizedProductsForm.value, customizedProductsData.value);
    })
    // customizedProductsForm.value = item;
    // console.log('customizedProductsForm.value',);
    customizedProducts.value = true;
    // customizedProductsData.value = selectedItems.value;
    lookForHiddenBtn.value = false
    console.log(customizedProductsForm.value);
}
const closeAddShopPop = (e) => {
    console.log('closeAddShopPop');
    if (customizedProductsForm.value.openLook) {
        delete customizedProductsForm.value.openLook
    }
    customizedProductsForm.value = {
        type: 1,
        username: '',
        mobile: '',
        sheetusername: '',
        qty: '',//数量
        amount: '',//总金额
        payamount: '',//支付金额
        remark: '',//备注
        paytype: 0, //0 对公 1对私 
        paystatus: 0,// 支付状态：0【未支付】，1【已支付】，2【申请退款】3【退款成功】4.【预收款】 5预付款
        chooseType: 0,
        address: '',
        amount: '',
        payamount: '',
        datalist: []
    }
    customizedProductsData.value = []
    // selectTypes.value = -1
    // selectStatus.value = -1

}
const addShop = () => {
    customizedProducts.value = true;
    customizedProductsForm.value = {
        type: 0,
        username: '',
        mobile: '',
        sheetusername: '',
        qty: '', // 数量
        amount: '', // 总金额
        payamount: '', // 支付金额
        remark: '', // 备注
        paytype: 0, // 0 对公 1 对私
        paystatus: 0, // 支付状态：0【未支付】，1【已支付】，2【申请退款】3【退款成功】4.【预收款】 5 预付款
        chooseType: 0,
        address: '',
        datalist: []
    }
    customizedProductsData.value = []
}
//#endregion
//#region 打印
const blueToothEvent = () => {
    // Api.getSharePdfOrDownLoad({ ordersn: this.form.ordersn })
    //     .then(async res => {
    //         const fileUrl = res.data.pdfUrl;
    //         console.log(fileUrl);
    //     })
}
//#endregion
//#region
const printEvent = async () => {
    // 检查是否选择了打印单
    if (!selectPintItemsString.value) {
        Message.warning('未选择打印单');
        return;
    }
    // 将选择的打印单号拆分成数组（假设打印单号之间使用逗号分隔）
    const orderNumbers = selectPintItemsString.value.split(',');
    try {
        // 用于存储所有请求的 Promise
        const promises = orderNumbers.map(async (orderNumber) => {
            try {
                // 发送请求获取 PDF 文件的 URL
                const res = await Api.getSharePdfOrDownLoad({ ordersn: orderNumber });
                const fileUrl = res.data.pdfUrl;
                if (fileUrl) {
                    return fileUrl; // 返回文件URL
                } else {
                    Message.warning(`未能获取到订单号为 ${orderNumber} 的PDF文件URL`);
                    return null; // 返回空值
                }
            } catch (error) {
                console.error(`获取订单号为 ${orderNumber} 的PDF文件URL失败`, error);
                Message.error(`获取订单号为 ${orderNumber} 的PDF文件URL失败`);
                return null; // 返回空值
            }
        });
        // 等待所有请求完成
        const urls = await Promise.all(promises);

        // 过滤有效的文件URL
        const validUrls = urls.filter(url => url);

        // 顺序创建新的浏览器标签页来打开 PDF 文件
        validUrls.forEach(url => {
            window.open(url, '_blank');
        });
    } catch (error) {
        console.error('API请求出错:', error);
        Message.error('API请求出错请稍后重试');
    }
}

//#endregion
//#region  生成订单并且打印
const submitAndTakePhoto = () => {
    // 如果 selectTypes 的值是 -1，则将 paytype 设置为空字符串，否则保留其原来的值
    if (selectTypes.value == -1) {
        customizedProductsForm.value.paytype = '';
    } else {
        customizedProductsForm.value.paytype = selectTypes.value; // 使用 selectTypes 的值
    }
    // 如果 selectStatus 的值是 -1，则将 paystatus 设置为空字符串，否则保留其原来的值
    if (selectStatus.value == -1) {
        customizedProductsForm.value.paystatus = '';
    } else {
        customizedProductsForm.value.paystatus = selectStatus.value; // 使用 selectStatus 的值
    }
    customizedProductsForm.value.datalist = selectedItems.value;
    if (customizedProductsForm.value.datalist.length > 0) {
        customizedProductsForm.value.datalist.forEach(item => {
            item.amount = item.price
        })
    }
    customizedProductsForm.value.type = 1

    // 调用 API 保存数据
    Api.saveStockorderInfo({ ...customizedProductsForm.value, chooseType: 1, userid: JSON.parse(localStorage.getItem("userInfo")).id }).then(res => {
        customizedProducts.value = false;
        resultTotalAmount.value = 0; // 重置计算总金额的结果
        paramsData.value = {
            name: '',//客户名称
            // starttime: '',
            // stoptime: '',
            type: 1,
            mobile: '',
            sheetusername: '',
            paytype: '',
            paystatus: '',
            payfinishtime: '',
            start_price: '',
            end_price: '',
            start_stock: '',
            end_stock: '',
            categoryid: '',//商品分类
            erpstockid: '',//所在仓库
            customerid: '',//
        }
        handlePageChange(pagination.value.current === undefined ? 1 : pagination.value.current, 10);
        handlePageChangeDZ(pagination.value.current === undefined ? 1 : pagination.value.current, 10);
        const ordersn = res.data.ordersn
        Api.getSharePdfOrDownLoad({ ordersn: ordersn }).then(ress => {
            const fileUrl = ress.data.pdfUrl;
            if (fileUrl) {
                // 创建一个新的浏览器标签页来打开 PDF 文件
                window.open(fileUrl, '_blank');
            } else {
                Message.warning('未能获取到PDF文件URL');
            }
        }).catch(error => {
            console.error('获取PDF文件URL失败', error);
            Message.error('获取PDF文件URL失败');
        });


    });

}
//#endregion
</script>
<style scoped lang="scss">
.desc_right {
    margin-right: 12px;
}

.desc_right_t {
    margin-right: 2px;

}

:deep(.arco-input-prepend) {
    font-weight: 400;
    white-space: nowrap;
    color: #000000;
    font-size: 14px;
    background-color: red;
    background: #FFFFFF !important;
    border: none;
}

:deep(.arco-input-wrapper) .arco-input::placeholder,
:deep(.arco-select-view-single) .arco-select-view-input::placeholder {
    font-size: 12px !important;

}

.desc_width_two {
    width: 23%;
    margin-right: 12px;
    text-align: right;
}

.desc_width {
    width: 100px;
    width: 70px;
}

.money_icon {
    font-weight: 500;
    font-size: 12px;
    color: #FF4D4C;
}


:deep(.arco-input-wrapper),
:deep(.arco-select-view-single) {
    border-radius: 2px;
    border: 1px solid #E6E6E6;
    background: #FFFFFF !important;
}

:deep(.arco-select-view-single) {
    box-sizing: border-box;
    width: 53% !important;
}

.center_btn {
    padding: 1%;

    .center_item {
        margin: 25px 10px 10px;
        //font-style: 10px;
    }
}

.list_products {
    background: #FFFFFF;
    border-radius: 2px;
    height: 100%;
    box-sizing: border-box;
    padding: 15px 10px;

    .editable-row-operations a {
        margin-right: 8px;
    }

    .item {
        margin: 0 20px;
        width: 100%;
    }

    .btn {
        transform: translateY(10px);
    }

    .mt {
        margin-top: 20px;
    }

    .nbsp {
        width: 30px;
        color: #999999;
        text-align: center;
    }

    .item_desc,
    :deep(.arco-input-prepend) {
        font-weight: 400;
        white-space: nowrap;
        color: #000000;
        font-size: 14px;
        background-color: red;
        background: #FFFFFF !important;
        border: none;
    }

    .desc_width {
        width: 100px;
        width: 70px;
    }

    :deep(.arco-input-wrapper) .arco-input::placeholder,
    :deep(.arco-select-view-single) .arco-select-view-input::placeholder {
        font-size: 12px !important;

    }

    .desc_right {
        margin-right: 12px;
    }

    .desc_right_t {
        margin-right: 2px;

    }

    .desc_width_two {
        width: 23%;
        margin-right: 12px;
        text-align: right;
    }

    .money_icon {
        font-weight: 500;
        font-size: 12px;
        color: #FF4D4C;
    }


    :deep(.arco-input-wrapper),
    :deep(.arco-select-view-single) {
        border-radius: 2px;
        border: 1px solid #E6E6E6;
        background: #FFFFFF !important;
    }

    :deep(.arco-select-view-single) {
        box-sizing: border-box;
        width: 53% !important;
    }

    .center_btn {
        padding: 1%;

        .center_item {
            margin: 25px 10px 10px;
            //font-style: 10px;
        }
    }

    .list_content {
        flex: 1;
        padding: 0 1%;
    }

    .btn_controls {
        margin: 0 3px;
    }



}

:deep(.arco-table .arco-table-cell) {
    padding: 10px 3px;
}

:deep(.arco-table-td-content) {
    box-sizing: border-box;
}

.good_item_desc {}
</style>