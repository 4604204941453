        <template>
            <div class="box">
                <div class="top_info flex j-c-s-a f-w">
                    <div class="item flex f-x-b a-i-c">
                        <div class="item_l ">
                            <div class="num flex">
                                <div class="num_one ellipsis" v-if="oneInfo.customerNumber">{{ oneInfo.customerNumber ?
                                    oneInfo.customerNumber : 0
                                    }}
                                </div>
                                <div class="corner" v-if="oneInfo.addCustomerNumber">+{{ oneInfo.addCustomerNumber ?
                                    oneInfo.addCustomerNumber : 0
                                    }}
                                </div>
                            </div>
                            <div class="title">客户总数</div>
                        </div>
                        <div class="item_r ">
                            <img src="../../assets/dataManagement/icon_kehu.png" alt="">
                        </div>
                    </div>

                    <div class="item flex f-x-b a-i-c">
                        <div class="item_l">
                            <div class="num  ellipsis color_two">+{{
                                oneInfo.yesterdayNumber ? oneInfo.yesterdayNumber : 0 }}</div>
                            <div class="title">昨日新增</div>
                        </div>
                        <div class="item_r">
                            <img src="../../assets/dataManagement/icon_xinzeng.png" alt="">
                        </div>
                    </div>
                    <div class="item flex f-x-b a-i-c">
                        <div class="item_l">
                            <div class="num  ellipsis color_three">{{ oneInfo.todayNumber ? oneInfo.todayNumber : 0 }}
                            </div>
                            <div class="title">今日订单</div>
                        </div>
                        <div class="item_r">
                            <img src="../../assets/dataManagement/icon_dingdan.png" alt="">
                        </div>
                    </div>
                    <div class="item flex f-x-b a-i-c">
                        <div class="item_l">
                            <div class="num ellipsis  color_four">￥{{ oneInfo.saleAmount ? oneInfo.saleAmount : 0 }}
                            </div>
                            <div class="title">销售金额</div>
                        </div>
                        <div class="item_r">
                            <img src="../../assets/dataManagement/icon_xiaoshou.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="echarts flex f-x-b">
                    <div class="echarts_item flex f-c f-x-b">
                        <div class="echarts_header flex f-x-b a-i-c">
                            <div class="echarts_header_l flex f-r a-i-c">
                                <div class="line">
                                </div>
                                订单数量
                            </div>
                            <div class="echarts_header_r"></div>
                        </div>
                        <div ref="quantity" style="height: 85%;"></div>
                    </div>
                    <div class="echarts_item flex f-c f-x-b">
                        <div class="echarts_header flex f-x-b a-i-c">
                            <div class="echarts_header_l flex f-r a-i-c">
                                <div class="line">
                                </div>订单金额
                            </div>
                            <div class="echarts_header_r"></div>
                        </div>
                        <div ref="amount" style="height: 85%;"></div>
                    </div>
                </div>
                <div class=" echarts_b_box flex f-x-b">
                    <div class="echarts_pillar flex f-c f-x-b">
                        <div class="echarts_header flex f-x-b a-i-c">
                            <div class="echarts_header_l flex f-r a-i-c">
                                <div class="line">
                                </div>销量排行
                            </div>
                            <div class="echarts_header_r"></div>
                        </div>
                        <div ref="ranking" style="height: 85%;"></div>
                    </div>
                    <div class="forewarning flex f-c ">
                        <div class="echarts_header flex f-x-b a-i-c">
                            <div class="echarts_header_l flex f-r a-i-c">
                                <div class="line">
                                </div>库存预警
                                <div class="warning_icon">
                                    <img src="../../assets/dataManagement/icon_yujing.png" alt="">
                                </div>

                            </div>
                            <div class="echarts_header_r"></div>
                        </div>
                        <div class="forewarning_list">
                            <div class="desc_header flex j-c-s-a">
                                <div class="desc_l">商品名称</div>
                                <div class="desc_c">型号</div>
                                <div class="desc_r">库存</div>
                            </div>
                            <!-- @reach-bottom="fetchData" :scrollbar="scrollbar" -->
                            <div class="item_box">
                                <div class="forewarning_item flex j-c-s-a a-i-c" v-for="item in fiveInfo">
                                    <div class="item_l flex a-i-c">
                                        <div class="l_img">
                                            <a-image width="32" height="32" :src="item.goodsImg" show-loader />
                                        </div>
                                        <div class="l_desc ellipsis">{{ item.goodsName }}</div>
                                    </div>
                                    <div class="item_c ellipsis">x{{ item.model }}</div>
                                    <div class="item_b ellipsis">{{ item.stock }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
<script setup>
import { ref, reactive, getCurrentInstance, defineExpose, watch, onMounted, inject } from 'vue'
import * as echarts from 'echarts';
const Message = getCurrentInstance().appContext.config.globalProperties.$message
const Api = getCurrentInstance()?.appContext.config.globalProperties.$Api
// 1. 创建echarts实例
let mChartQuantity = null;
let myChartAmount = null;
let myChartRanking = null;
const quantity = ref(null);
const amount = ref(null);
const ranking = ref(null);

// 响应式数据对象
const oneInfo = ref({});
const twoInfo = ref({});
const threeInfo = ref({});
const fourInfo = ref({});
const fiveInfo = ref({});

// 获取数据函数
const getOne = () => {
    return new Promise((resolve) => {
        Api.getOneData().then(res => {
            oneInfo.value = res.data;
            resolve();
        });
    });
}

const getTwo = () => {
    return new Promise((resolve) => {
        Api.getTwoData().then(res => {
            twoInfo.value = res.data;
            resolve();
        });
    });
}

const getThree = () => {
    return new Promise((resolve) => {
        Api.getThreeData().then(res => {
            threeInfo.value = res.data;
            resolve();
        });
    });
}

const getFour = () => {
    return new Promise((resolve) => {
        Api.getFourData().then(res => {
            fourInfo.value = res.data;
            resolve();
        });
    });
}

const getFive = () => {
    return new Promise((resolve) => {
        Api.getFiveData().then(res => {
            fiveInfo.value = res.data.list;
            resolve();
        });
    });
}
// 2. 构建 option 配置对象
const renderChart = () => {
    let resQuantity = {
        xData: twoInfo.value.x,
        yData: twoInfo.value.y,
        mockData: twoInfo.value.y,
    };

    let optionsQuantity = {
        title: {
            show: !resQuantity.xData.length,
            text: '暂无数据',
            left: 'center',
            top: 'center',
            textStyle: {
                color: '#333',
                fontSize: 20
            }
        },
        xAxis: {
            type: "category",
            axisLabel: {
                color: "#333333",
            },
            axisLine: {
                lineStyle: {
                    color: "#F0F0F0",
                },
            },
            axisTick: {
                show: false,
            },
            data: resQuantity.xData,
        },
        grid: {
            top: 10,
            bottom: 30,
        },
        yAxis: {
            type: "value",
            axisLine: {
                show: true,
                lineStyle: {
                    color: "#F0F0F0",
                },
            },
            splitLine: {
                show: false,
            },
            axisLabel: {
                color: "#333333",
                lineStyle: {
                    color: "#333333",
                },
            },
        },
        tooltip: {
            trigger: "axis",
            triggerOn: "click mousemove",
            axisPointer: {
                type: "line",
                lineStyle: {
                    color: "#55CD8C",
                },
                label: {
                    show: true,
                },
                crossStyle: {
                    show: false,
                },
            },
            show: true,
            showDelay: 0,
            formatter: function (params) {
                let dataIndex = params[0].dataIndex;
                let xValue = params[0].axisValue;
                let yValue = params[0].data;
                return `${xValue}-订单数${yValue}`;
            },
            textStyle: {
                color: "#55CD8C",
            },
        },
        color: ["#55CD8C"],
        series: [
            {
                data: resQuantity.mockData,
                type: "line",
                areaStyle: {
                    color: {
                        type: "linear",
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0.1,
                                color: "#55CD8C",
                            },
                            {
                                offset: 0.8,
                                color: "#FFFFFF",
                            },
                        ],
                    },
                    opacity: 0.5,
                },
            },
        ],
    };

    let resAmount = {
        xData: threeInfo.value.x,
        yData: threeInfo.value.y,
        mockData: threeInfo.value.y,
    };

    let optionsAmount = {
        title: {
            show: !resAmount.xData.length,
            text: '暂无数据',
            left: 'center',
            top: 'center',
            textStyle: {
                color: '#333',
                fontSize: 20
            }
        },
        xAxis: {
            type: "category",
            axisLabel: {
                color: "#333333",
            },
            axisLine: {
                lineStyle: {
                    color: "#F0F0F0",
                },
            },
            axisTick: {
                show: false,
            },
            data: resAmount.xData,
        },
        grid: {
            top: 10,
            bottom: 30,
        },
        yAxis: {
            type: "value",
            axisLine: {
                show: true,
                lineStyle: {
                    color: "#F0F0F0",
                },
            },
            splitLine: {
                show: false,
            },
            axisLabel: {
                color: "#333333",
                lineStyle: {
                    color: "#333333",
                },
            },
        },
        tooltip: {
            trigger: "axis",
            triggerOn: "click mousemove",
            axisPointer: {
                type: "line",
                lineStyle: {
                    color: "#FD9C65",
                },
                label: {
                    show: true,
                },
                crossStyle: {
                    show: false,
                },
            },
            show: true,
            showDelay: 0,
            formatter: function (params) {
                let dataIndex = params[0].dataIndex;
                let xValue = params[0].axisValue;
                let yValue = params[0].data;
                return `${xValue}-¥${yValue}K`;
            },
            textStyle: {
                color: "#FD9C65",
            },
        },
        color: ["#FD9C65"],
        series: [
            {
                data: resAmount.mockData,
                type: "line",
                areaStyle: {
                    color: {
                        type: "linear",
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0.1,
                                color: "#FD9C65",
                            },
                            {
                                offset: 0.8,
                                color: "#FFFFFF",
                            },
                        ],
                    },
                    opacity: 0.5,
                },
            },
        ],
    };

    let resRanking = {
        xData: fourInfo.value.x,
        yData: fourInfo.value.y,
        mockData: fourInfo.value.y,
    };

    let optionsRanking = {
        title: {
            show: !resRanking.xData.length,
            text: '暂无数据',
            left: 'center',
            top: 'center',
            textStyle: {
                color: '#333',
                fontSize: 20
            }
        },
        color: [
            {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                    {
                        offset: 0,
                        color: "#6784FD", // 100% 处的颜色
                    },
                    {
                        offset: 1,
                        color: "#FFFFFF", // 0% 处的颜色
                    },
                ],
                global: false, // 缺省为 false
            },
        ],
        grid: {
            top: 10,
            bottom: 30,
        },
        yAxis: {
            axisLine: {
                show: true,
                lineStyle: {
                    color: "#F0F0F0",
                },
            },
            splitLine: {
                show: false,
            },
            axisLabel: {
                color: "#333333",
                lineStyle: {
                    color: "#333333",
                },
            },
        },
        xAxis: [
            {
                type: "category",
                data: resRanking.xData,
                axisTick: {
                    alignWithLabel: true,
                },
                axisLabel: {
                    color: "#333333",
                    interval: 0,
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: "#F0F0F0",
                    },
                },
            },
        ],
        series: [
            {
                type: "bar",
                barWidth: "20%",
                data: resRanking.mockData,
                itemStyle: {
                    barBorderRadius: [1, 1, 0, 0],
                },
            },
        ],
    };

    // 3. 通过 实例.setOptions(option) 方法加载配置
    mChartQuantity.setOption(optionsQuantity);
    myChartAmount.setOption(optionsAmount);
    myChartRanking.setOption(optionsRanking);

    window.addEventListener('resize', () => {
        mChartQuantity.resize();
        myChartAmount.resize();
        myChartRanking.resize();
    });
}

// 等待所有异步请求完成后再执行 renderChart
onMounted(() => {
    Promise.all([getOne(), getTwo(), getThree(), getFour(), getFive()]).then(() => {
        mChartQuantity = echarts.init(quantity.value, 'light');
        myChartAmount = echarts.init(amount.value, 'light');
        myChartRanking = echarts.init(ranking.value, 'light');
        renderChart();
    }).catch(error => {
        console.error('Error in fetching data:', error);
    });
});

</script>
<style lang="scss" scoped>
.box {
    box-sizing: border-box;
    height: 100vh;
}

.line {
    background-color: #6784FD;
    height: 25px;
    width: 2px;
    margin-right: 10px;
}

.top_info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    gap: 10px;
    height: 10vh;

    .item {
        flex: 1 1 24%;
        max-width: 24%;
        min-width: 200px;
        background: #FFFFFF;
        border-radius: 2px;
        box-sizing: border-box;
        padding: 10px 2%;

        .item_l {
            text-align: left;
            width: 70%;

            .num {
                font-weight: 600;
                font-size: 25px;
                color: #6784FD;

                .corner {
                    font-weight: 550;
                    font-size: 14px;
                    color: #6784FD;
                    margin-left: 2%;
                }
            }



            .color_two {
                color: #FD9C65;
            }

            .color_three {
                color: #55CD8C;
            }

            .color_four {
                color: #FF4C4C;
            }

            .title {
                font-weight: 400;
                font-size: 14px;
                margin-top: 8px;
                color: #999999;
            }
        }

        .item_r {
            width: 30%;
            text-align: right;


            img {
                width: 50px;
                height: 50px;
            }
        }
    }
}

.echarts_header {
    // padding: 0 10px;

    .echarts_header_l {
        font-size: 16px;
        color: #333333;

        .warning_icon {
            width: 18px;
            height: 18px;

            img {
                width: 18px;
                height: 18px;
            }
        }
    }

    .line {
        background-color: #6784FD;
        height: 25px;
        width: 2px;
        margin-right: 10px;
    }

    // .echarts_header_l::before {
    //     content: '';
    //     height: 0.5px;
    //     background-color: #6784FD;
    //     border-radius: 12px;
    //     margin-right: 10px;
    // }

}

.echarts {
    width: 100%;
    margin-top: 15px;
    // background-color: red;
    height: 35%;

    .echarts_item {
        box-sizing: border-box;
        width: calc(48% + 20px);
        /* 计算等于头部两个 item 的宽度和间距 */
        // height: 210px;
        /* 固定高度 */
        background: #FFFFFF;
        border-radius: 2px;
        padding: 10px;
    }


}

.echarts_b_box {
    height: 40%;



    .echarts_pillar {
        width: calc(60% + 15px);
        margin-top: 15px;
        /* 计算等于头部两个 item 的宽度和间距 */
        // height: 270px;
        height: 100%;
        box-sizing: border-box;
        /* 固定高度 */
        background: #FFFFFF;
        border-radius: 2px;
        padding: 10px;
    }
}

.forewarning {
    margin-top: 15px;
    box-sizing: border-box;
    width: 37%;
    padding: 10px;
    background: #FFFFFF;
    height: 100%;


    .forewarning_list {
        padding: 10px 20px;
        box-sizing: border-box;
        height: 100%;
      //overflow: scroll;

        .desc_header {
            padding: 3px 0;
            background: #F5F5F5;
            border-radius: 2px;
            font-size: 15px;

            color: #666666;
        }

        .item_box {
            height: 92%;
            overflow: scroll;
            box-sizing: border-box;

            .forewarning_item {
                padding: 10px 20px;
                font-size: 16px;
                color: #333333;
                width: 100%;

                .item_l {
                    width: 35%;
                    text-align: left;

                    .l_desc {
                        margin-left: 15px;

                    }
                }

                .item_c {
                    text-align: left;
                    width: 30%;

                }

                .item_b {
                    width: 20%;
                    text-align: left;
                    color: #FF4C4C;
                }

            }
        }
    }



}
</style>
