<template>
    <div class="list_products flex f-c">
        <div class="search_header flex">
            <a-col :span="6">
                <div class="flex a-i-c" style="padding-left: 20px;">
                    <div class="item_desc desc_right">店铺名称:</div>
                    <a-select :style="{ width: '240px', marginLeft: '15px' }" value-key="id" placeholder="请选择店铺"
                        :loading="storeLoading" v-model="selectStore" @change="storeChange">
                        <a-option v-for="item of storeOptions" :value="item" :label="item.name" />
                    </a-select>
                </div>
            </a-col>
            <!-- <a-col :span="6">
                <div class=""> <a-input :style="{ width: '80%' }" placeholder="请输入店铺名称" allow-clear
                        v-model="paramsData.name">
                        <template #prepend>
                            &nbsp&nbsp店铺名称:
                        </template>
</a-input></div>
</a-col> -->
            <a-col :span="6">
                <div class=""> <a-input :style="{ width: '300px' }" placeholder="请输入联系方式" allow-clear
                        v-model="paramsData.mobile">
                        <template #prepend>
                            联系方式:
                        </template>
                    </a-input></div>
            </a-col>
            <div></div>
            <a-col :span="2">
                <div class="item btn flex a-i-c" style="transform: translate(30px);">
                    <a-button type="primary" @click="searchList" style="height: 40px;">
                        <template #icon>
                            <icon-search />
                        </template>
                        搜索
                    </a-button>
                </div>
            </a-col>
            <a-col :span="2">
                <div class="item btn">
                    <a-button type="primary" status="success" @click="reset" style="height: 40px;margin-left: 10px;">
                        <template #icon>
                            <icon-refresh />
                        </template>
                        重置
                    </a-button>
                </div>

            </a-col>
        </div>
        <div class="list_content">
            <a-space direction="vertical" size="large" fill>
                <a-table :columns="columns" :data="datas" :row-selection="rowSelection" :row-key="key"
                    @selectAll="selectedAllKeysEvent" @select="selectedEvent" v-model:selectedKeys="selectedKeys"
                    :pagination="pagination" :bordered="false" :stripe="true">
                    <template #name="{ record, rowIndex }">
                        <span>
                            <a-image width="30" height="30" :src="record.img" />
                            {{ record.name }} </span>
                    </template>
                    <template #mobile="{ record, rowIndex }">
                        <span>
                            {{ record.mobile }}
                        </span>
                    </template>
                    <template #address="{ record, rowIndex }">
                        <span>
                            {{ record.address + '/' + record.addressdetail }}
                        </span>
                    </template>

                </a-table>
            </a-space>
        </div>
    </div>
</template>
<script setup>
import { ref, watch, toRefs, reactive, getCurrentInstance, onMounted } from 'vue';
import { IconSearch, IconRefresh, IconPrinter, IconPlus, } from '@arco-design/web-vue/es/icon';
import { customRef } from 'vue';
import axios from "axios";
const Message = getCurrentInstance().appContext.config.globalProperties.$message
const Api = getCurrentInstance()?.appContext.config.globalProperties.$Api
const Com = getCurrentInstance()?.appContext.config.globalProperties.$Com

onMounted(() => {
    handlePageChange()
    getStore()

});
//#region 店铺列表
const storeOptions = ref([])
const storeLoading = ref(false)
const selectStore = ref('')
const getStore = () => {
    storeLoading.value = true
    Api.getShopList({
        pageSize: 1000,
        status: 0,
    }).then(res => {
        storeOptions.value = res.data.datalist
        storeLoading.value = false
    })
}
const storeChange = () => {
    console.log(selectStore.value);
}


//#endregion
//#region   查询表单 搜索 重制
const paramsData = ref({
    name: '',
    mobile: ''
})
const reset = () => {
    selectStore.value = ''
    paramsData.value = {
        name: '',
        mobile: ''
    }
}
const columns = [{
    title: '店铺名称',
    dataIndex: 'name',

},
{
    title: '联系方式',
    dataIndex: 'mobile',
    slotName: 'mobile'

}, {
    title: '创建日期',
    dataIndex: 'createtime',
    slotName: 'createtime'

},
{
    //     title: '是否开通商城',
    //     dataIndex: 'operatingstate',
    //     slotName: 'operatingstate'

    // }, {
    title: '地址',
    dataIndex: 'address',
    slotName: 'address'

},
];
const datas = ref([])
const pagination = ref({
    total: 0,  // 数据总条目数
    showTotal: true,            // 是否显示总条目数
    defaultPageSize: 10,        // 默认每页显示条目数
    pageSize: 10,               // 当前每页显示条目数
    current: 1,                 // 当前页码
    showJumper: true,
    onChange: (page, pageSize) => {
        handlePageChange(page, pageSize);
    }
});
const handlePageChange = (page, pageSize) => {
    console.log('page:', page, 'pageSize:', pageSize);
    paramsData.value.name = selectStore.value.name
    const pageIndex = page ? page : pagination.value.current;
    Api.getShopList({
        pageIndex: pageIndex,
        status: 0,
        ...paramsData.value
    }).then(res => {
        console.log(res);
        pagination.value.current = page;        // 更新当前页码
        res.data.datalist.forEach(item => {
            item.editable = false;
        });
        pagination.value.total = res.data.total; // 更新总条目数
        datas.value = [...res.data.datalist];
        console.log(datas.value);
    }).catch(error => {
        console.error('Error fetching data:', error);
    });
};
const searchList = () => {
    handlePageChange(1, 10)
}
//#endregion  

</script>
<style lang="scss" scoped>
.list_products {
    background: #FFFFFF;
    border-radius: 2px;
    height: 100%;
    box-sizing: border-box;
    padding: 15px 0;

    .item_desc,
    :deep(.arco-input-prepend) {
        font-weight: 400;
        white-space: nowrap;
        color: #000000;
        font-size: 14px;
        background-color: red;
        background: #FFFFFF !important;
        border: none;
    }

    .desc_width {
        width: 100px;
        width: 70px;
    }

    :deep(.arco-input-wrapper) .arco-input::placeholder,
    :deep(.arco-select-view-single) .arco-select-view-input::placeholder {
        font-size: 12px !important;

    }

    .search_header {
        height: 12vh;
    }

    .list_content {
        flex: 1;
        padding: 0 1%;
        transform: translateY(-40px);

    }
}
</style>
