import { createRouter, createWebHashHistory } from "vue-router";
import Login from "@/views/Login.vue";
import MainBox from "@/views/MainBox.vue";
import RoutesConfig from "./config.js";
import store from "@/store/index.js";
const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/mainbox",
    name: "mainbox",
    component: MainBox,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.name === "login") {
    //登陆过token存在
    next();
  } else {
    //token不存在 没授权
    if (!localStorage.getItem("token")) {
      console.log("token不存在");
      next({
        path: "/login",
      });
    } else {
      if (!store.state.isGetterRouter) {
        router.removeRoute("mainbox");
        console.log("isGetterRouter为false");
        ConfigRouter();
        next({
          path: to.fullPath,
        });
      } else {
        next();
      }
    }
  }
});

const ConfigRouter = () => {
  if (!router.hasRoute("mainbox")) {
    router.addRoute({
      path: "/mainbox",
      name: "mainbox",
      component: MainBox,
    });
  }
  Object.keys(RoutesConfig).forEach((key) => {
    const route = RoutesConfig[key];
    checkPermission(route) && router.addRoute("mainbox", route);
  });
  //改变全局第一次动态加载状态isGetterRouter
  store.commit("changeGetterRouter", true);
  console.log(store.state.isGetterRouter);
};
const checkPermission = (item) => {
  if (item.requireAuth) {
    return store.state.userInfo.role === 1;
  }
  return true;
};
export default router;
