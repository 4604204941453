<template>
    <div class="list_products">
        <div class="img_box">
            <img src="../../assets/QR/1720232671349.png" alt="" />
            <div class="desc">客服手机号:17337906616</div>
            <div class="desc">或扫码添加客服微信</div>
        </div>

    </div>
</template>
<script setup>
</script>
<style lang="scss" scoped>
.list_products {
    background: #FFFFFF;
    border-radius: 2px;
    height: 100%;
    box-sizing: border-box;
    padding: 15px 0;
}

.img_box {
    width: 500px;
    margin: 10% auto 25px;
    text-align: center;

    img {
        width: 250px;
        height: 250px;
    }

    .desc {
        margin-top: 25px;
        font-weight: 600;
        font-size: 18px;
        color: #000000;
    }

}
</style>
