<template>
  <a-layout-header>
    <a-page-header :style="{ background: '#FFFFFF', height: '35px' }" :show-back="false">
      <template #breadcrumb>
        <div class="right">
          <a-breadcrumb>
            <a-breadcrumb-item v-for="item, index in levelList" :key="index">
              <a v-if="item.redirect === 'noRedirect' || index === levelList.length - 1"
                @click.prevent="handleLink(item)" class="no-redirect">
                {{ item.meta.title }}
              </a>
              <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
            </a-breadcrumb-item>
          </a-breadcrumb>
        </div>
      </template>
      <template #extra>
        <div class="left">
          <a-popover v-model:visible="visible" trigger="hover">
            <template #content>
              <a-button type="primary" @click="outLogin">退出登录</a-button>
            </template>
            <a-avatar>
              <img alt="avatar"
                :src="userInfo.avatar ? userInfo.avatar : 'https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/3ee5f13fb09879ecb5185e440cef6eb9.png~tplv-uwbnlip3yd-webp.webp'" />
            </a-avatar>

          </a-popover>

        </div>
      </template>
    </a-page-header>
  </a-layout-header>
</template>

<script setup>
import { ref, watch, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();
const levelList = ref([]);

const getBreadcrumb = () => {
  let matched = router.currentRoute.value.matched.filter(item => item.meta && item.meta.title);
  if (!matched.some(item => item.meta.breadcrumb !== false)) {
    // 如果没有任何一个路由要求显示面包屑，则添加默认项
    matched = [{ path: '/dataManagement', meta: { title: '数据管理' } }].concat(matched);
  }
  levelList.value = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false);
};


const isDataManagement = (route) => {
  const name = route && route.name;
  if (!name) {
    return false;
  }
  return name.trim().toLocaleLowerCase() === '数据管理'.toLocaleLowerCase();
};
const outLogin = () => {
  // 清除本地存储中的 token 和用户信息
  localStorage.removeItem("token");
  localStorage.removeItem("userInfo");

  // 重置状态管理中的用户信息
  store.commit("changeUserInfo", null);
  store.commit('SET_PERMISSIONS', [])
  store.commit("changeMenuStatus", false);

  // 重新定向到登录页面
  router.push("/login");

}
const pathCompile = (path) => {
  try {
    const { params } = router.currentRoute.value;
    return router.resolve({ path, params }).href;
  } catch (error) {
    console.error("Error compiling path:", error);
    return path; // 返回原始路径作为备用
  }
};

const handleLink = (item) => {
  const { redirect, path } = item;
  console.log(path)
  // console.log(pathCompile(path));
  if (redirect) {
    router.push(redirect);
    return;
  }
  router.push(path);
  store.dispatch('updateCurrentPath', path);
};

const generateKey = (item) => {
  return item.meta.title; // 使用 meta.title 作为唯一键
};
const userInfo = reactive({})
onMounted(() => {
  Object.assign(userInfo, JSON.parse(localStorage.getItem("userInfo")))
  console.log(userInfo);
  if (store.state.isGetterRouter) {
    getBreadcrumb();
  }
});

watch(() => store.state.isGetterRouter, (newValue) => {
  if (newValue) {
    getBreadcrumb();
  }
}, { deep: true });

watch(() => router.currentRoute.value, () => {
  getBreadcrumb();
});
</script>

<style lang="scss" scoped>
.arco-layout-header {
  background-color: #ffffff;

  ::v-deep .arco-page-header-wrapper {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .arco-avatar {}

  .app-breadcrumb.el-breadcrumb {
    display: inline-block;
    font-size: 14px;
    line-height: 35px;
    margin-left: 8px;

    .no-redirect {
      color: #97a8be;
      cursor: text;
    }
  }
}
</style>
