<template>
    <div class="list_products flex f-c">
        <div class="center_btn flex f-x-e">
            <div class="center_item">
                <a-button type="primary" status="warning" @click="addAccount = true">
                    <template #icon>
                        <icon-plus />
                    </template>
                    添加
                </a-button>
            </div>
        </div>
        <div class="list_content">
            <a-space direction="vertical" size="large" fill>
                <a-table :columns="columns" :data="datas" :row-selection="rowSelection" :row-key="key"
                    @selectAll="selectedAllKeysEvent" @select="selectedEvent" v-model:selectedKeys="selectedKeys"
                    :pagination="pagination" :bordered="false" :stripe="true">
                    <!-- :data="form.noData ? [] : data" -->

                    <template #avatar="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-image width="30" height="30" v-if="record.editable"
                                :src="`https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/a8c8cdb109cb051163646151a4a5083b.png~tplv-uwbnlip3yd-webp.webp?timestamp=${timestamp}`"
                                show-loader />
                        </span>
                        <span v-else>
                            <a-image width="30" height="30"
                                :src="`https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/a8c8cdb109cb051163646151a4a5083b.png~tplv-uwbnlip3yd-webp.webp?timestamp=${timestamp}`"
                                show-loader />
                        </span>
                    </template> <template #account="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.account" />
                        </span>
                        <span v-else>
                            {{ record.account }}
                        </span>
                    </template>

                    <template #passWord="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.passWord" />
                        </span>
                        <span v-else>
                            {{ record.passWord }}
                        </span>
                    </template>


                    <template #controls="{ record, rowIndex }">

                        <div class="flex f-x-c">
                            <a-button type="primary" @click="saveEditRecord(record.key, rowIndex)"
                                style="margin-left: 5px;" v-if="record.editable">保存</a-button>
                            <a-button type="primary" @click="editRecord(record.key)" style="margin-left: 5px;" v-else>
                                编辑</a-button>
                            <a-button status="danger" @click="deleteRecord(record.key, rowIndex)"
                                style="margin-left: 5px;">删除</a-button>
                        </div>
                    </template>

                </a-table>
            </a-space>
        </div>
        <!-- 添加子账号 -->
        <a-modal :footer="null" v-model:visible="addAccount" :width="500" title="添加子账号" alignCenter draggable
            escToClose>
            <a-form :model="addAccountForm" @submit="handleSubmitAddAccount" class="flex f-x-c"> <a-form-item
                    field="account" style="margin-top: 30px;">
                    <div class="good_item_desc">账号:</div>
                    <a-input :style="{ width: '250px', }" v-model="addAccountForm.account" placeholder="请输入账号"
                        allow-clear />
                </a-form-item>
                <a-form-item field="phone" style="margin-top: 20px;">
                    <div class="good_item_desc  ">密码:</div>
                    <a-input :style="{ width: '250px' }" v-model="addAccountForm.passWord" placeholder="请输入密码"
                        allow-clear />
                </a-form-item>

                <a-form-item field="imgPath">
                    <!-- :rules="[{ required: true, message: '请上传商品图片' }]" -->
                    <div class="good_item_desc">头像设置:</div>
                    <a-upload class="upload" action="/" :fileList="file ? [file] : []" :show-file-list="false"
                        @change="onChange" @progress="onProgress">
                        <template #upload-button>
                            <div :class="`arco-upload-list-item${file && file.status === 'error' ? ' arco-upload-list-item-error' : ''
                                }`">
                                <div class="arco-upload-list-picture custom-upload-avatar" v-if="file && file.url">
                                    <img :src="file.url" />
                                    <div class="arco-upload-list-picture-mask">
                                        <IconEdit />
                                    </div>
                                    <a-progress v-if="file.status === 'uploading' && file.percent < 100"
                                        :percent="file.percent" type="circle" size="mini" :style="{
                                            position: 'absolute',
                                            left: '50%',
                                            top: '50%',
                                            transform: 'translateX(-50%) translateY(-50%)',
                                        }" />
                                </div>
                                <div class="arco-upload-picture-card" v-else>
                                    <div class="arco-upload-picture-card-text">
                                        <IconPlus size="28" strokeWidth="2" />
                                        <div style="margin-top: 1px; font-weight: 600;font-size: 12px;color: #999999;">
                                            点击添加</div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </a-upload>
                </a-form-item>

                <a-form-item style="text-align: center !important;padding: 50px 80px 0">
                    <a-button type="outline" status="primary" size="large" style="margin-right: 20px;">取消</a-button>
                    <a-button html-type="submit" type="primary" size="large">确定</a-button>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>
<script setup>
import { ref, watch, toRefs, reactive } from 'vue';
import { IconSearch, IconRefresh, IconPrinter, IconPlus, } from '@arco-design/web-vue/es/icon';
import { customRef } from 'vue';
const province = ref('Sichuan');
const city = ref('Chengdu');
const data = {
    Beijing: ['Haidian', 'Chaoyang', 'Changping'],
    Sichuan: ['Chengdu', 'Mianyang', 'Aba'],
    Guangdong: ['Guangzhou', 'Shenzhen', 'Shantou']
};
watch(province, () => {
    city.value = ''
})

const listHeader =
    [
        {
            title: '全部',
            id: 'all'
        },
        {
            title: '待付款',
            id: 'dfk'

        },
        {
            title: '待发货',
            id: 'dfh'

        },
        {
            title: '待收货',
            id: 'dsh'

        }, {
            title: '已完成',
            id: 'ywc'

        }, {
            title: '退款中',
            id: 'tkz'

        }
    ];
//**列表功能**//
const columns = [{
    title: '头像',
    dataIndex: 'avatar',
    slotName: 'avatar'

}, {
    title: '账号',
    dataIndex: 'account',
    slotName: 'account'
}, {
    title: '密码',
    dataIndex: 'passWord',
    slotName: 'passWord'
},
{
    title: '操作',
    slotName: 'controls'
}];
const datas = reactive(Array(30).fill(null).map((_, index) => ({
    key: index + 1,
    avatar: index + 1,
    account: `账号${index + 1}`,
    passWord: `密码${index + 1}`,
    editable: false
})));
const currentSelectHeaderItem = ref(0)

const selectedKeys = ref([]);

const rowSelection = reactive({
    type: 'checkbox',
    showCheckedAll: true,
    onlyCurrent: false,
    onChange: (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys:', selectedRowKeys);
        console.log('selectedRows:', selectedRows);
        selectedKeys.value = selectedRowKeys;
    }
});


const file = ref();

const onChange = (_, currentFile) => {
    file.value = {
        ...currentFile,
        // url: URL.createObjectURL(currentFile.file),
    };
};
const onProgress = (currentFile) => {
    file.value = currentFile;
    console.log(file.value);
};
const editingKey = ref('');

//分页
const pagination = reactive({
    total: datas.length,  // 数据总条目数
    showTotal: true,      // 是否显示总条目数
    defaultPageSize: 10,  // 默认每页显示条目数
    pageSize: 10,          // 当前每页显示条目数
    current: 1,           // 当前页码
    showJumper: true,
    onChange: (page, pageSize) => {
        handlePageChange(page, pageSize);
    }
});
const handlePageChange = (page, pageSize) => {
    console.log('page:', page, 'pageSize:', pageSize);
    // 发送分页请求的逻辑，假设有一个名为 fetchData 的函数用于从接口获取数据
    // fetchData(page, pageSize)
    //     .then(response => {
    //         // 处理请求成功后的逻辑，假设 response.data 包含了从接口获取的数据
    //         datas.value = response.data;
    //         // 更新 pagination 的属性
    //         pagination.total = response.total; // 更新总条目数
    pagination.current = page;         // 更新当前页码
    pagination.pageSize = pageSize;    // 更新每页显示条目数
    //     })
    //     .catch(error => {
    //         // 处理请求失败的逻辑
    //         console.error('Error fetching data:', error);
    //     });
};
//头部子项点击
const handelChangeHeaderListItem = (item, index) => {
    if (currentSelectHeaderItem.value == index) return
    currentSelectHeaderItem.value = index
    console.log(currentSelectHeaderItem.value);
}

//全选
const selectedAllKeysEvent = val => {
    console.log('selectedAllKeysEvent:', val);
};
//单选
const selectedEvent = val => {
    console.log('selectedEvent:', val);
};
// 编辑单个
const editRecord = (key) => {
    // console.log('editRecord:', record);
    datas.forEach(item => {
        if (item.key === key) {
            item.editable = true;
        } else {
            item.editable = false;
        }
    });
    editingKey.value = key;
    // $modal.info({ title: '编辑', content: record.name });
};
//保存编辑
const saveEditRecord = (key, index) => {
    datas.forEach(item => {
        if (item.key === Number(key)) {
            item.editable = false;
        }

    });
    editingKey.value = '';


};
const cancelRecord = (key) => {
    datas.forEach(item => {
        if (item.key === key) {
            item.editable = false;
        }
    });
    editingKey.value = '';
}
// 删除单个
const deleteRecord = (key, index) => {
    datas.splice(index, 1);


    // $modal.info({ title: '删除', content: record.name });
};


//**添加客户功能**//
const addAccount = ref(false);
const addAccountForm = reactive({
    avatar: '',//头像
    account: '',//账号
    passWord: '',//密码
});

const handleSubmitAddAccount = (data) => {
    console.log(data);
};

</script>
<style scoped lang="scss">
.list_products {
    background: #FFFFFF;
    border-radius: 2px;
    height: 100%;
    box-sizing: border-box;
    padding: 15px 10px;

    .editable-row-operations {
        margin-right: 8px;
    }

    .item {
        margin: 0 20px;
        width: 100%;
    }



    .mt {
        margin-top: 20px;
    }

    .nbsp {
        width: 30px;
        color: #999999;
        text-align: center;
    }

    .item_desc,
    :deep(.arco-input-prepend) {
        font-weight: 400;
        white-space: nowrap;
        color: #000000;
        font-size: 14px;
        background-color: red;
        background: #FFFFFF !important;
        border: none;
    }

    .desc_width {
        width: 100px;
        width: 70px;
    }

    :deep(.arco-input-wrapper) .arco-input::placeholder,
    :deep(.arco-select-view-single) .arco-select-view-input::placeholder {
        font-size: 12px !important;

    }

    .desc_right {
        margin-right: 12px;
    }

    .desc_right_t {
        margin-right: 2px;

    }

    .desc_width_two {
        width: 23%;
        margin-right: 12px;
        text-align: right;
    }

    .money_icon {
        font-weight: 500;
        font-size: 12px;
        color: #FF4D4C;
    }


    :deep(.arco-input-wrapper),
    :deep(.arco-select-view-single) {
        border-radius: 2px;
        border: 1px solid #E6E6E6;
        background: #FFFFFF !important;
    }

    :deep(.arco-select-view-single) {
        box-sizing: border-box;
        width: 53% !important;
    }

    .center_btn {
        padding: 1%;

        .center_item {
            margin: 10px 10px 10px;
            //font-style: 10px;
        }
    }

    .list_content {
        flex: 1;
        padding: 0 1%;
        // transform: translateY(-40px);

    }

    .btn_controls {
        margin: 0 3px;
    }



}

:deep(.arco-table .arco-table-cell) {
    padding: 10px 3px;
}

:deep(.arco-table-td-content) {
    box-sizing: border-box;
}

.list_content_header {

    .list_item {
        font-size: 14px;
        color: #333333;
        padding: 13px 6px;
        border: 1px solid #E6E6E6;
        width: 106px;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;

    }

    .active {
        color: #6784FD
    }
}

:deep(.arco-checkbox-group) {
    display: flex !important;
    // flex-wrap: wrap;
    flex-wrap: wrap !important;

    width: 100%;


}



:deep(.arco-table-td-content) {
    width: 250px !important;
}

:deep(.arco-form-item-content-flex) {
    align-items: normal !important;
    // transform: translateY(30px);
}

.good_item_desc {
    line-height: 40px;
    font-size: 15px;
    text-align: right;
    // font-weight: 600;
    color: #000000;
    width: 60px !important;

}
</style>