<template>
  <div class="list_products">
    <div class="center_btn">
      <div class="flex f-x-e" style="padding: 0 20px 15px 0">
        <div class="center_item">
          <a-button
            type="primary"
            status="warning"
            @click="AddWareHousePopup = true"
            style="height: 40px"
          >
            <template #icon>
              <icon-plus />
            </template>
            添加
          </a-button>
        </div>
      </div>
    </div>
    <div class="list_content">
      <a-space direction="vertical" size="large" fill>
        <a-table
          :columns="columns"
          :data="datas"
          :pagination="pagination"
          :bordered="false"
          :stripe="true"
        >
          <template #level="{ record, rowIndex }">
            <!-- <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.level" />
                        </span> -->
            <!-- <span v-else> -->
            {{ record.level }}
            <!-- </span> -->
          </template>
          <template #name="{ record, rowIndex }">
            <span v-if="record.editable">
              <a-input
                v-if="record.editable"
                style="margin: -5px 0"
                v-model="record.name"
              />
            </span>
            <span v-else>
              {{ record.name }}
            </span>
          </template>
          <template #goodscount="{ record, rowIndex }">
            <!-- <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.goodscount" />
                        </span>
                        <span v-else> -->
            {{ record.goodscount }}
            <!-- </span> -->
          </template>
          <template #operation="{ record, rowIndex }">
            <div class="editable-row-operations">
              <div class="flex f-x-c" v-if="record.editable">
                <div class="btn_controls">
                  <a-button
                    status="success"
                    @click="saveEditRecord(record.id, rowIndex)"
                    >保存</a-button
                  >
                </div>
                <div class="btn_controls">
                  <a-button
                    status="danger"
                    @click="deleteRecord(record.id, rowIndex)"
                    >删除</a-button
                  >
                </div>
              </div>
              <div class="flex f-x-c" v-else style="cursor: pointer">
                <div class="btn_controls">
                  <a-button
                    status="success"
                    @click="editRecord(record.id, rowIndex)"
                    >编辑</a-button
                  >
                </div>
                <div class="btn_controls">
                  <a-button
                    status="danger"
                    @click="deleteRecord(record.id, rowIndex)"
                    >删除</a-button
                  >
                </div>
              </div>
            </div>
          </template>
        </a-table>
      </a-space>
    </div>
    <!--添加仓库-->
    <a-modal
      :footer="null"
      v-model:visible="AddWareHousePopup"
      :width="550"
      title="添加仓库"
      alignCenter
      draggable
      escToClose
    >
      <a-form
        :model="AddWareHouseForm"
        @submit="handleSubmitAddCategory"
        class="flex f-x-c"
      >
        <a-form-item
          field="name"
          :rules="[{ required: true, message: '仓库名称' }]"
        >
          <div class="good_item_desc">添加仓库:</div>
          <a-input
            :style="{ width: '248px' }"
            placeholder="请输入仓库名称"
            allow-clear
            v-model="AddWareHouseForm.name"
          >
          </a-input>
        </a-form-item>
        <a-form-item style="text-align: center !important; padding: 0 80px">
          <a-button
            type="outline"
            status="primary"
            size="large"
            style="margin-right: 20px"
            @click="AddWareHousePopup = false"
            >取消</a-button
          >
          <a-button html-type="submit" type="primary" size="large"
            >确定</a-button
          >
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import { IconPlus } from "@arco-design/web-vue/es/icon";
const Message =
  getCurrentInstance().appContext.config.globalProperties.$message;
const Api = getCurrentInstance()?.appContext.config.globalProperties.$Api;
const Com = getCurrentInstance()?.appContext.config.globalProperties.$Com;
onMounted(() => {
  handlePageChange();
});

//#region 列表**//
const datas = ref([]);
const editingKey = ref("");
const columns = [
  {
    title: "仓库排序",
    dataIndex: "level",
    slotName: "level",
  },
  {
    title: "仓库名称",
    dataIndex: "name",
    slotName: "name",
  },
  {
    title: "商品数量",
    dataIndex: "goodscount",
    slotName: "goodscount",
  },
  {
    title: "操作",
    slotName: "operation",
  },
];
const pagination = ref({
  total: 0, // 数据总条目数
  showTotal: true, // 是否显示总条目数
  defaultPageSize: 10, // 默认每页显示条目数
  pageSize: 10, // 当前每页显示条目数
  current: 1, // 当前页码
  showJumper: true,
  onChange: (page, pageSize) => {
    handlePageChange(page, pageSize);
  },
});
const handlePageChange = (page, pageSize) => {
  const pageIndex = page ? page : pagination.value.current;
  Api.erpstockListManagement({
    pageIndex: pageIndex,
    status: 0,
  })
    .then((res) => {
      if (res.data) {
        console.log(res);
        pagination.value.current = page; // 更新当前页码
        res.data.datalist.forEach((item) => {
          item.editable = false;
        });
        pagination.value.total = res.data.total; // 更新总条目数
        datas.value = [...res.data.datalist];
        console.log(datas.value);
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};
//#endregion

//#region 编辑  删除 保存**/
//开始 编辑状态
const editRecord = (id) => {
  const recursiveSetEditable = (items) => {
    items.forEach((item) => {
      if (item.id === Number(id)) {
        item.editable = true;
      } else {
        item.editable = false;
      }
    });
  };

  // 初始化递归调用
  recursiveSetEditable(datas.value);
  editingKey.value = id;
};
//保存编辑
const saveEditRecord = (id) => {
  const recursiveSetEditable = (items) => {
    items.forEach((item) => {
      if (item.id === Number(id)) {
        if (!item.name) return;
        Api.updateErpstock({ id: item.id, name: item.name }).then((res) => {
          handlePageChange(
            pagination.value.current === undefined
              ? 1
              : pagination.value.current,
            10
          );
        });
        item.editable = false;
        console.log("保存仓库的id", item.id);
      }
    });
  };
  // 初始化递归调用
  recursiveSetEditable(datas.value);
  editingKey.value = "";
};
const deleteRecord = (id, index) => {
  const recursiveDelete = (items, key) => {
    return items.filter((item) => {
      if (item.id === id) {
        Api.updateErpstock({ id: item.id, status: 1 }).then((res) => {
          handlePageChange(
            pagination.value.current === undefined
              ? 1
              : pagination.value.current,
            10
          );
        });
        return false; // 过滤掉这个项目，表示删除
      }

      return true;
    });
  };

  const updatedDatas = recursiveDelete(datas.value, id);
  datas.value = updatedDatas; // 直接赋值更新数据，不需要使用 splice
};

//#endregion

//#region 添加仓库 */
const AddWareHousePopup = ref(false);
const AddWareHouseForm = reactive({
  name: "",
});
const handleSubmitAddCategory = (data) => {
  if (!AddWareHouseForm.name) return;
  Api.addErpstock({
    ...AddWareHouseForm,
    userid: JSON.parse(localStorage.getItem("userInfo")).id,
  }).then((res) => {
    console.log(res);
    if (res.code == 200) {
      Message.success(res.message);
    } else {
      Message.warning(res.message);
    }
    AddWareHouseForm.name = "";
    handlePageChange(
      pagination.value.current === undefined ? 1 : pagination.value.current,
      10
    );
    AddWareHousePopup.value = false;
  });
};
//#endregion
</script>

<style lang="scss" scoped>
.list_products {
  background: #ffffff;
  border-radius: 2px;
  height: 100%;
  box-sizing: border-box;
  padding: 15px 0;
}

.list_content {
  flex: 1;
  padding: 0 1%;
}

.btn_controls {
  margin: 0 3px;
}

:deep(.arco-table-td-content) {
  width: 200px !important;
}
</style>
