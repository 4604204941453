<template>
    <div class="list_products flex f-c">
        <div class="search_header flex f-w a-i-c">
            <a-col :span="6">
                <div class=""> <a-input :style="{ width: '80%' }" placeholder="请输入客户名称" allow-clear
                        v-model="paramsData.name">
                        <template #prepend>
                            &nbsp&nbsp客户名称:
                        </template>
                    </a-input></div>
            </a-col>
            <a-col :span="6">
                <div class=""> <a-input :style="{ width: '80%' }" placeholder="请输入联系方式" allow-clear
                        v-model="paramsData.mobile">
                        <template #prepend>
                            联系方式:
                        </template>
                    </a-input></div>
            </a-col>
            <a-col :span="6">
                <div class="flex a-i-c">
                    <div class="good_item_desc ">筛选日期:</div>
                    <a-range-picker
                        style="width: 360px;height: 40px;;background-color: #ffffff;border: 1px solid #E6E6E6; ;"
                        format="YYYY-MM-DD" @change="onOk" v-model="rangeValue" />
                    <!-- <a-date-picker
                        style="width: 248px;height: 40px;background-color: #ffffff;border: 1px solid #E6E6E6;">
                    </a-date-picker> -->
                </div>
            </a-col>
            <a-col :span="2">
                <div class="item btn flex a-i-c" style="transform: translate(30px);">
                    <a-button type="primary" @click="handlePageChange(1, 10)" style="height: 40px;">
                        <template #icon>
                            <icon-search />
                        </template>
                        搜索
                    </a-button>
                </div>
            </a-col>
            <a-col :span="2">
                <div class="item btn">
                    <a-button type="primary" status="success" @click="reset" style="height: 40px;">
                        <template #icon>
                            <icon-refresh />
                        </template>
                        重置
                    </a-button>
                </div>

            </a-col>
        </div>
        <div class="center_btn flex f-x-b">
            <div class="flex">
            </div>
            <div class="flex" style="position: relative; z-index: 1;">
                <div class="center_item">
                    <!-- <a-button type="outline" status="warning">
                        <template #icon>
                            <icon-printer />
                        </template>
                        打印
                    </a-button> -->
                </div>
                <!-- <div class="center_item">
                    <a-button type="primary" status="warning" @click="addCustomer = true">
                        <template #icon>
                            <icon-plus />
                        </template>
                        添加
                    </a-button>
                </div> -->
            </div>
        </div>
        <div class="list_content">
            <div class="list_content_header flex ">
                <div class="list_item" v-for="item, index in listHeader" :key="index"
                    @click="handelChangeHeaderListItem(item, index)"
                    :style="{ color: currentSelectIndex === index ? ' #6784FD' : '' }">
                    {{
                        item.title }}
                </div>
            </div>
            <a-space direction="vertical" size="large" fill>
                <a-table :columns="columns" :data="datas" :row-selection="rowSelection" :row-key="key"
                    @selectAll="selectedAllKeysEvent" @select="selectedEvent" v-model:selectedKeys="selectedKeys"
                    :pagination="pagination" :bordered="false" :stripe="true">
                    <template #key="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.ordersn" />
                        </span>
                        <span v-else>
                            {{ record.ordersn }}
                        </span>
                    </template>
                    <template #payfinishtime="{ record, rowIndex }">
                        <!-- <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.payfinishtime" />
                        </span>
                        <span v-else> -->
                        {{ record.payfinishtime }}
                        <!-- </span> -->
                    </template> <template #name="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.name" />
                        </span>
                        <span v-else>
                            {{ record.name }}
                        </span>
                    </template> <template #mobile="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.mobile" />
                        </span>
                        <span v-else>
                            {{ record.mobile }}
                        </span>
                    </template>
                    <!-- <template #houseNumber="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.houseNumber" />
                        </span>
                        <span v-else>
                            {{ record.houseNumber }}
                        </span>
                    </template>  -->
                    <template #address="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.address" />
                        </span>
                        <span v-else>
                            {{ record.address + '/' + record.addressdetail }}
                        </span>
                    </template> <template #logistic="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.logistic" />
                        </span>
                        <span v-else>
                            {{ record.logistic }}
                        </span>
                    </template> <template #payamount="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.payamount" />
                        </span>
                        <span v-else>
                            {{ record.payamount }}
                        </span>
                    </template>
                    <template #controls="{ record, rowIndex }" v-if="currentSelectIndex == 2">
                        <div class="flex f-x-c " v-if="record.editable" style="white-space: nowrap;">
                            <a-button type="primary" @click="saveRecord(record)">保存</a-button>
                            <a-button status="danger" @click="cancelRecord(record.id)"
                                style="margin-left: 5px;">取消</a-button>
                        </div>
                        <div v-else class="flex f-x-c">
                            <a-button type="primary" @click="editRecord(record.id)">编辑</a-button>
                            <a-button status="danger" @click="deleteRecord(record.id,)"
                                style="transform: translateX(15px);">删除</a-button>
                        </div>
                    </template>
                    <template #controls="{ record, rowIndex }" v-else>
                        <div class="flex f-x-c" v-if="record.editable" style="white-space: nowrap;">
                            <a-button type="primary" @click="saveRecord(record)">保存</a-button>
                            <a-button status="danger" @click="cancelRecord(record.id)"
                                style="margin-left: 5px;">取消</a-button>
                        </div>
                        <div v-else class="flex f-x-c">
                            <a-button type="primary" status="success"
                                @click="openCollectionPop(record, index)">收款</a-button>
                            <a-button type="primary" @click="editRecord(record.id)"
                                style="margin-left: 5px;">编辑</a-button>
                            <a-button status="danger" @click="deleteRecord(record.id, rowIndex)"
                                style="transform: translateX(15px);">删除</a-button>
                        </div>
                    </template>

                </a-table>
            </a-space>
        </div>
        <!-- //收款弹窗 -->
        <a-modal :footer="null" v-model:visible="collectionPop" :width="724" title="收款" alignCenter draggable
            escToClose>
            <a-form :model="collectionFrom" @submit="submitPay">
                <div class="flex a-i-c">
                    <a-form-item field="customerName">
                        <div class="good_item_desc">客户名称:</div>
                        <a-input :style="{ width: '120px', border: 'none !important' }"
                            v-model="collectionFrom.customerName" placeholder="客户名称" allow-clear disabled />
                    </a-form-item>
                    <a-form-item field="warning">
                        <div class="good_item_desc  ">累计欠款:</div>
                        <a-input :style="{ width: '120px', border: 'none !important' }" v-model="collectionFrom.amount"
                            placeholder="累计欠款" allow-clear disabled />
                    </a-form-item>
                    <a-form-item field="warning">
                        <div class="good_item_desc  ">预收款项:</div>
                        <a-input :style="{ width: '120px', border: 'none !important' }"
                            v-model="collectionFrom.payamount" placeholder="预收款项" allow-clear disabled />
                    </a-form-item>
                </div>
                <a-form-item field="warning" class="flex f-x-a" style="transform: translateX(-14%)">
                    <div class="good_item_desc ">收款金额:</div>
                    <!-- <a-input :style="{ width: '248px', border: 'none !important' }" v-model="collectionFrom.warning"
                            placeholder="输入收款金额" allow-clear disabled /> -->
                    <a-input-number v-model="payment" :style="{ width: '24%', border: 'none !important' }"
                        class="input-demo" :min="1" hide-button="true" placeholder="输入收款金额">
                        <template #prefix>
                            <span class="money_icon" style="color: red; font-size: 18px;">¥</span>
                        </template>
                    </a-input-number>
                </a-form-item>
                <div style="font-size: 14px;
color: #333333;padding-left: 7%;font-weight: bold;">欠款单据:</div>
                <a-checkbox-group v-model="checkedValue">
                    <!-- <div v-for="(spec, index) in collectionFrom.notesOfArrearsLists" :key="index"
                        class="notes flex  f-r f-w"> -->
                    <a-form-item style="width: 45%;padding-left: 7% ;margin: 5px 5px 5px 0; box-sizing: border-box;"
                        class="notes flex f-r f-w" v-for="(spec, index) in collectionFrom.notesOfArrearsLists"
                        :key="index">
                        <!-- :field="'specifications[' + index + '].modelNumber'" -->
                        <div class="notes_box">
                            <div class="notes_item_t flex f-x-b a-i-c">
                                <div class="notes_item_t_l">2024.04.03</div>
                                <div class="notes_item_t_r">
                                    <a-checkbox :value="spec"></a-checkbox>
                                </div>
                            </div>
                            <div class="notes_item_b flex f-w">
                                <div class="flex f-r a-i-c">总金额:
                                    <span class="moneys">¥{{ spec.goodsamount }}</span>
                                </div>
                                <div class="flex f-r a-i-c">未收金额:
                                    <span class="moneys">¥{{ spec.amount }}</span>
                                </div>
                                <div class="flex f-r a-i-c">已付金额:
                                    <span class="moneys">¥{{ spec.payamount }}</span>
                                </div>

                            </div>
                        </div>
                    </a-form-item>
                    <!-- </div> -->
                </a-checkbox-group>
                <a-form-item>
                    <div style="width: 75%; text-align: center;margin-top: 50px">
                        <a-button type="outline" status="primary" size="large" style="margin-right: 20px;"
                            @click="collectionPop = false">取消</a-button>
                        <a-button html-type="submit" type="primary" size="large">确定</a-button>
                    </div>
                </a-form-item>
            </a-form>
        </a-modal>
        <!-- 添加客户弹窗 -->
        <a-modal :footer="null" v-model:visible="addCustomer" :width="724" title="添加客户" alignCenter draggable
            @submit="handleSubmitAddGoods" escToClose>
            <a-form :model="submitForm" @submit="handleAddCustomer" class="flex f-x-c"> <a-form-item field="name"
                    style="margin-top: 30px;">
                    <div class="good_item_desc">客户名称:</div>
                    <a-input :style="{ width: '320px', }" v-model="submitForm.name" placeholder="请输入客户名称" allow-clear />
                </a-form-item>
                <a-form-item field="phone" style="margin-top: 20px;">
                    <div class="good_item_desc  ">联系方式:</div>
                    <a-input :style="{ width: '320px' }" v-model="submitForm.mobile" placeholder="请输入联系方式"
                        allow-clear />
                </a-form-item>
                <a-form-item field="address" style="margin-top: 20px;">
                    <!-- :rules="[{ required: true, message: '请选择地址' }]" -->
                    <div class="good_item_desc">地址:</div>
                    <div class="flex f-c">
                        <a-cascader :options="options" allow-clear expand-trigger="hover"
                            :default-value="currentSelectAddress" @change="changeOPtions" :style="{ width: '320px' }"
                            placeholder="请选择所在地址" />
                        <a-textarea placeholder="请输入详细地址" allow-clear :max-length="200" show-word-limit :style="{
                            width: '320px', height: '100px', marginTop: '30px', border: '1px solid #E6E6E6', background: '#FFFFFF'
                        }" v-model="submitForm.addressdetail" />
                        <!-- v-model="form.feedback" -->
                    </div>
                </a-form-item>
                <!-- <a-form-item field="houseNumber" style="margin-top: 20px;">
                    <div class="good_item_desc  ">门牌号:</div>
                    <a-input :style="{ width: '320px' }" v-model="submitForm.houseNumber" placeholder="请输入门牌号"
                        allow-clear />
                </a-form-item> -->
                <a-form-item field="logistic" style="margin-top: 20px;">
                    <div class="good_item_desc ">物流信息:</div>
                    <a-input :style="{ width: '320px' }" v-model="submitForm.logistic" placeholder="请输入物流信息"
                        allow-clear />
                </a-form-item>

                <a-form-item style="text-align: center !important;padding: 50px 80px 0">
                    <a-button type="outline" status="primary" size="large" style="margin-right: 20px;"
                        @click="addCustomer = false">取消</a-button>
                    <a-button html-type="submit" type="primary" size="large">确定</a-button>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>
<script setup>
import { ref, watch, toRefs, reactive, getCurrentInstance, onMounted } from 'vue';
import { IconSearch, IconRefresh, IconPrinter, IconPlus, } from '@arco-design/web-vue/es/icon';
import { customRef } from 'vue';
import axios from "axios";
const Message = getCurrentInstance().appContext.config.globalProperties.$message
const Api = getCurrentInstance()?.appContext.config.globalProperties.$Api
const Com = getCurrentInstance()?.appContext.config.globalProperties.$Com

onMounted(() => {
    handlePageChange()
    axios.get(`https://restapi.amap.com/v3/config/district?key=bb5e823739e620ce2371bb376b05e577&subdistrict=3`).then(
        response => {
            // console.log('服务器响应回来的数据：', response.data);
            const districts = response.data.districts[0].districts;
            // 将地区列表赋值给 options.value
            options.value = districts.map(item => ({
                value: item.name,
                label: item.name,
                children: item.districts.map(subItem => ({
                    value: subItem.name,
                    label: subItem.name,
                    children: subItem.districts.map(subSubItem => ({
                        value: subSubItem.name,
                        label: subSubItem.name,
                    }))
                }))
            }));

            // console.log('处理后的选项：', options.value);
        },
        error => {
            // console.log('错误信息：', error.message);
        }
    );
});
//#region 头部选择
const listHeader =
    [
        // {
        //     title: '合同金额',
        //     id: ''
        // },
        {
            title: '预收款',
            id: 5
        },
        {
            title: '未收款',
            id: 0
        },
        {
            title: '已收款',
            id: 1
        }
    ];
const currentSelectBar = ref(5)
const currentSelectIndex = ref(0)
//头部子项点击
const handelChangeHeaderListItem = (item, index) => {
    if (currentSelectIndex.value == index) return
    currentSelectIndex.value = index
    if (item.id !== '') {
        currentSelectBar.value = item.id;
    } else {
        currentSelectBar.value = '';
    }
    paramsData.value.paystatus = currentSelectBar.value
    handlePageChange(pagination.value.current === undefined ? 1 : pagination.value.current, 10)

    console.log(currentSelectBar.value);
}
//#endregion
//#region  重制
const reset = () => {
    paramsData.value = {
        name: '',//客户名称
        mobile: '',//联系方式
        start_time: '',//日期
        end_time: '',//日期
        paystatus: 5
    }
    rangeValue.value = []
}
//#endregion
//#region 列表功能**//
const columns = [{
    title: '编号',
    dataIndex: 'ordersn',
}, {
    title: '日期',
    dataIndex: 'payfinishtime',
    slotName: 'payfinishtime'

}, {
    title: '客户名称',
    dataIndex: 'name',
    slotName: 'name'

}, {
    title: '联系方式',
    dataIndex: 'mobile',
    slotName: 'mobile'

},
// {
//     title: '门牌号',
//     dataIndex: 'houseNumber',
//     slotName: 'houseNumber'

// }, 
{
    title: '地址',
    dataIndex: 'address',
    slotName: 'address'

}, {
    title: '物流信息',
    dataIndex: 'logistic',
    slotName: 'logistic'

}, {
    title: '总金额',
    dataIndex: 'payamount',
    // slotName: 'payamount'
},
{
    title: '操作',
    slotName: 'controls'
}];
const paramsData = ref({
    name: '',//客户名称
    mobile: '',//联系方式
    start_time: '',//日期
    end_time: '',//日期
    paystatus: 5

})
const datas = ref([])
const selectedKeys = ref([]);
const rowSelection = reactive({
    type: 'checkbox',
    showCheckedAll: true,
    onlyCurrent: false,
    selectedRowKeys: selectedKeys.value, // 当前选中的行 key 数组
    onChange: (selectedRowKeys, selectedRows, record) => {
        selectedKeys.value = selectedRowKeys;
        console.log('selectedRows:', selectedRows, record);
    }
});
//分页
const pagination = ref({
    total: 0,  // 数据总条目数
    showTotal: true,            // 是否显示总条目数
    defaultPageSize: 10,        // 默认每页显示条目数
    pageSize: 10,               // 当前每页显示条目数
    current: 1,                 // 当前页码
    showJumper: true,
    onChange: (page, pageSize) => {
        handlePageChange(page, pageSize);
    }
});
const handlePageChange = (page, pageSize) => {
    console.log('page:', page, 'pageSize:', pageSize);
    const pageIndex = page ? page : pagination.value.current;
    Api.getUseManagementList({
        pageIndex: pageIndex,
        status: 0,
        type: 1,
        ...paramsData.value
    }).then(res => {
        console.log(res);
        pagination.value.current = page;        // 更新当前页码
        res.data.datalist.forEach(item => {
            item.editable = false;
        });
        pagination.value.total = res.data.total; // 更新总条目数
        datas.value = [...res.data.datalist];
        console.log(datas.value);
    }).catch(error => {
        console.error('Error fetching data:', error);
    });
};
//#endregion
//#region 单个 编辑 保存 删除
const editingKey = ref('');
//全选
const selectedAllKeysEvent = val => {
    console.log('selectedAllKeysEvent:', val);
};
//单选
const selectedEvent = val => {
    console.log('selectedEvent:', val);
};
// 编辑单个
const editRecord = (id) => {
    datas.value.forEach(item => {
        if (item.id === id) {
            item.editable = true;
        } else {
            item.editable = false;
        }
    });
    editingKey.value = id;
};
const cancelRecord = (id) => {
    datas.value.forEach(item => {
        if (item.id === id) {
            item.editable = false;
        }
    });
    editingKey.value = '';
}
// 删除单个
const deleteRecord = (id, index) => {
    Api.deleteStockorderInfo({ ids: id, status: 1 }).then(res => {
        Message.success(res.message);
        datas.value = []
        handlePageChange(pagination.value.current === undefined ? 1 : pagination.value.current, 10)

    }).catch(error => {
        console.error('Error changing goods:', error);
    });
};
const editForm = ref({
    name: '',
    mobile: '',
    address: '',
    logistic: '',
    type: 1,
    // id: ''
})
const saveRecord = (item) => {
    const { name, mobile, address, logistic } = item;
    editForm.value = { name, mobile, address, logistic };
    editForm.value.type = 1

    // editForm.value.id = item.customerid
    // if (!editForm.value.name || !editForm.value.mobile || !editForm.value.address || !editForm.value.logistic) return
    Api.savePeoPle({ ...editForm.value, id: item.customerid }).then(res => {
        datas.value = []
        handlePageChange(pagination.value.current === undefined ? 1 : pagination.value.current, 10)
    })
}
//#endregion
//#region 时间选择弃
const rangeValue = ref([])
const onOk = (dateString, date) => {
    paramsData.value.start_time = dateString[0]
    paramsData.value.end_time = dateString[1]
    console.log(paramsData.value);
}
//#endregions
//#region 添加用户
const submitForm = ref({
    name: '', mobile: '', address: '', logistic: '', addressdetail: '',
    type: 1
})
const addCustomer = ref(false);
const options = ref([])
const currentSelectAddress = ref('')
const changeOPtions = (val) => {
    submitForm.value.address = val[0] + val[1] + val[2]
}
const handleSubmitAddGoods = (data) => {
    console.log(data);

    Api.newPeoPleAdd({ ...submitForm.value }).then(res => {
        addCustomer.value = false
    })
};

//#endregion

//**收款 */
const payment = ref('')
const collectionPop = ref(false)
const collectionFrom = ref({
    customerName: '',//客户名称
    amount: '',//累计欠款
    payamount: '', //预收款项
    // amountOfMoneyReceived: '',//收款金额
    notesOfArrearsLists: []
})
const info = ref({})
const getInfo = (opt) => {
    Api.payMentInfo({ ordersn: opt }).then(res => {
        // info.value = res.data
        collectionFrom.value = { ...res.data }
    })
}
const getpayList =  (opt) => {
    Api.payMenRealList({ ordersn: opt, searchPayment: 1, paystatus: 0 }).then(async res => {
       collectionFrom.value.notesOfArrearsLists = res.data.datalist
    })
}
const ordersn = ref('')
const openCollectionPop = (item, index) => {
    getInfo(item.ordersn)
    ordersn.value = item.ordersn
    getpayList(item.ordersn)
    collectionPop.value = true;
    console.log(collectionFrom, item);
}
const checkedValue = ref([])
const submitPay = () => {
    // checkedValue.value = checkedValue.value.join(',');
    Api.proceeds({ ordersn: ordersn.value, amount: payment.value, datalist: checkedValue.value }).then(res => {
        getpayList(ordersn.value)
        payment.value = ''
        getInfo(ordersn.value)
    })
}

</script>
<style scoped lang="scss">
.list_products {
    background: #FFFFFF;
    border-radius: 2px;
    height: 100%;
    box-sizing: border-box;
    padding: 15px 0;

    .editable-row-operations a {
        margin-right: 8px;
    }

    .item {
        margin: 0 20px;
        width: 100%;
    }



    .mt {
        margin-top: 20px;
    }

    .nbsp {
        width: 30px;
        color: #999999;
        text-align: center;
    }

    .item_desc,
    :deep(.arco-input-prepend) {
        font-weight: 400;
        white-space: nowrap;
        color: #000000;
        font-size: 14px;
        background-color: red;
        background: #FFFFFF !important;
        border: none;
    }

    .desc_width {
        width: 100px;
        width: 70px;
    }

    :deep(.arco-input-wrapper) .arco-input::placeholder,
    :deep(.arco-select-view-single) .arco-select-view-input::placeholder {
        font-size: 12px !important;

    }

    .desc_right {
        margin-right: 12px;
    }

    .desc_right_t {
        margin-right: 2px;

    }

    .desc_width_two {
        width: 23%;
        margin-right: 12px;
        text-align: right;
    }

    .money_icon {
        font-weight: 500;
        font-size: 12px;
        color: #FF4D4C;
    }


    :deep(.arco-input-wrapper),
    :deep(.arco-select-view-single) {
        border-radius: 2px;
        border: 1px solid #E6E6E6;
        background: #FFFFFF !important;
    }

    :deep(.arco-select-view-single) {
        box-sizing: border-box;
        width: 53% !important;
    }

    .center_btn {
        padding: 1%;

        .center_item {
            margin: 25px 10px 10px;
            font-style: 10px;
        }
    }

    .list_content {
        flex: 1;
        padding: 0 1%;
        transform: translateY(-40px);

    }

    .btn_controls {
        margin: 0 3px;
    }



}

:deep(.arco-table .arco-table-cell) {
    padding: 10px 3px;
}

:deep(.arco-table-td-content) {
    box-sizing: border-box;
}

.list_content_header {

    .list_item {
        font-size: 14px;
        color: #333333;
        padding: 13px 6px;
        border: 1px solid #E6E6E6;
        width: 106px;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;

    }

    .active {
        color: #6784FD
    }
}

:deep(.arco-checkbox-group) {
    display: flex !important;
    // flex-wrap: wrap;
    flex-wrap: wrap !important;

    width: 100%;


}



.notes {
    display: flex;
    flex-wrap: wrap;

    :deep(.arco-col-19) {
        flex: none !important;
        width: 100%;
        width: 300px;

    }

    :deep(.arco-checkbox-icon) {
        border-radius: 12px;
    }

    .notes_box {
        width: 300px;
        border-radius: 3px;
        border: 1px solid #E6E6E6;
        padding: 8px 0;
        box-sizing: border-box;

        .notes_item_t {
            font-size: 12px;
            color: #999999;
            border-bottom: 1px solid #F0F0F0;
            padding-left: 5px
        }

        .notes_item_b {
            font-weight: 400;
            font-size: 12px;
            color: #666666;
            padding: 0 5px;

            div {
                width: 50%;
                padding: 5px 0;
            }

            .moneys {
                color: #333333;
                font-weight: 600;
            }
        }
    }
}

:deep(.arco-form-item-content-flex) {
    align-items: normal !important;
    // transform: translateY(30px);
}

.good_item_desc {
    line-height: 40px;
    font-size: 15px;
    // font-weight: 600;
    color: #000000;
    width: 70px !important;

    :deep(.arco-input-disabled) {
        color: #333333 !important;
    }

}
</style>