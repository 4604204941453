<template>
    <div class="list_products">
        <a-empty />

    </div>
</template>
<script setup></script>
<style lang="scss" scoped>
.list_products {
    background: #FFFFFF;
    border-radius: 2px;
    height: 100%;
    box-sizing: border-box;
    padding: 100px 0;
}
</style>
