<template>
  <div class="list_products flex f-c">
    <div class="search_header flex">
      <a-col :span="6">
        <div class="flex a-i-c" style="padding-left: 20px">
          <div class="good_item_desc">角色状态:</div>
          <a-select :style="{ width: '180px' }" value-key="id" placeholder="请选择状态" v-model="selectStatus"
            @change="selectChange">
            <a-option v-for="item of statusOptions" :value="id" :label="item.name" />
          </a-select>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="flex a-i-c" style="padding-left: 20px">
          <div class="good_item_desc">管理员:</div>
          <a-input :style="{ width: '320px' }" v-model="name" placeholder="请输入姓名或账号" allow-clear />
        </div>
      </a-col>
      <div></div>
      <a-col :span="2">
        <div class="item btn flex a-i-c" style="transform: translate(30px)">
          <a-button type="primary" @click="handlePageChange(1, 10)" style="height: 40px">
            <template #icon>
              <icon-search />
            </template>
            搜索
          </a-button>
        </div>
      </a-col>
      <a-col :span="2">
        <div class="item btn">
          <a-button type="primary" status="success" @click="reset" style="height: 40px; margin-left: 5px">
            <template #icon>
              <icon-refresh />
            </template>
            重置
          </a-button>
        </div>
      </a-col>
      <a-col :span="4">
        <div class="item btn">
          <a-button type="primary" status="warning" @click="openAdd" style="height: 40px; margin-left: 5px">
            添加管理员
          </a-button>
        </div>
      </a-col>
    </div>
    <div class="list_content">
      <a-space direction="vertical" size="large" fill>
        <a-table :columns="columns" :data="datas" :pagination="pagination" :bordered="false" :stripe="true">
          <template #userStatus="{ record, rowIndex }">
            <a-switch :default-checked="record.userStatus == 0" @change="ChannelMergerNode($event, record)"
              :style="{ width: '60px' }" />
          </template>
          <template #userIdentity="{ record, rowIndex }">
            <!-- <a-switch  @change="ChannelMergerNode($event, record)"
              :style="{ width: '60px' }" /> -->
            <a-select :style="{ width: '180px' }" value-key="id" :placeholder="editName ? editName : '请选择管理员身份'"
              v-model="record.id" @change="changeRole2(record.id,record.userid)">
              <a-option v-for="item of roleList" :value="item.id" :label="item.roleName" />
            </a-select>
          </template>

          <template #controls="{ record, rowIndex }">
            <div class="flex">
              <a-button type="primary" @click="openEdit(record, rowIndex)" style="margin-left: 5px">编辑</a-button>
              <a-button status="danger" @click="deleteRole(record.userid, rowIndex)"
                style="margin-left: 5px">删除</a-button>
            </div>
          </template>
        </a-table>
      </a-space>
    </div>
    <!--添加角色-->
    <a-modal :footer="null" v-model:visible="addRole" :width="600" :title="roleTitle" alignCenter draggable escToClose>
      <a-form :model="roleFrom" @submit="handleSubmitAddRole" class="flex f-x-c">
        <!-- <a-form-item field="roleName" :rules="[{ required: true, message: '请输入管理员账号' }]">
                    <div class="good_item_desc">账号:</div>
                    <a-input :style="{ width: '248px' }" placeholder="请输入管理员账号" allow-clear v-model="roleFrom.roleName">
                    </a-input>
                </a-form-item> -->
        <a-form-item field="phone" :rules="[{ required: true, message: '请输入手机号' }]">
          <div class="good_item_desc">手机号:</div>
          <a-input :style="{ width: '248px' }" placeholder="请输入手机号" allow-clear v-model="roleFrom.phone">
          </a-input>
        </a-form-item>
        <a-form-item field="password" :rules="[{ required: true, message: '请输入管理员密码' }]">
          <div class="good_item_desc">密码:</div>
          <a-input-password :style="{ width: '248px' }" placeholder="请输入管理员密码" allow-clear v-model="roleFrom.password">
          </a-input-password>
        </a-form-item>
        <a-form-item field="username" :rules="[{ required: true, message: '请输入管理员姓名' }]">
          <div class="good_item_desc">姓名:</div>
          <a-input :style="{ width: '248px' }" placeholder="请输入管理员姓名" allow-clear v-model="roleFrom.username">
          </a-input>
        </a-form-item>
        <a-form-item field="roles" :rules="[{ required: true, message: '请选择管理员身份' }]">
          <div class="good_item_desc">身份:</div>
          <a-select :style="{ width: '180px' }" value-key="id" :placeholder="editName ? editName : '请选择管理员身份'"
            v-model="selectRole" @change="changeRole">
            <a-option v-for="item of roleList" :value="item.id" :label="item.roleName" />
          </a-select>
        </a-form-item>
        <!-- <a-form-item field="roleName">
                    <div class="good_item_desc">关联账户:</div>
                    <a-input :style="{ width: '248px' }" allow-clear v-model="roleFrom.roleName">
                    </a-input>
                </a-form-item> -->

        <a-form-item field="status">
          <div class="good_item_desc">状态:</div>
          <a-switch v-model="roleFrom.status" checked-value="yes" unchecked-value="no" :style="{ width: '60px' }" />
        </a-form-item>

        <a-form-item style="text-align: center !important; padding: 0 80px">
          <a-button type="outline" status="primary" size="large" style="margin-right: 20px"
            @click="addRole = false">取消</a-button>
          <a-button html-type="submit" type="primary" size="large">确定</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script setup>
import {
  ref,
  watch,
  toRefs,
  reactive,
  getCurrentInstance,
  computed,
  onMounted,
  nextTick,
} from "vue";
import {
  IconSearch,
  IconRefresh,
  IconPrinter,
  IconPlus,
} from "@arco-design/web-vue/es/icon";
import { useStore } from "vuex";
import CryptoJS from "crypto-js";

const store = useStore();
const Message =
  getCurrentInstance().appContext.config.globalProperties.$message;
const Api = getCurrentInstance()?.appContext.config.globalProperties.$Api;
//#region 查询
const statusOptions = ref([
  { name: "全部", value: "全部", id: "" },
  { name: "已启用", value: "已启用", id: "0" },
  { name: "已禁用", value: "已禁用", id: "1" },
]);
const selectStatus = ref("");
const name = ref("");
//#endregion
//#region 修改状态
const ChannelMergerNode = (event, item) => {
  if (event) {
    item.userStatus = 0;
  } else {
    item.userStatus = 1;
  }
  console.log(event, item);

  Api.updateUserStatus({
    id: item.userid,
    status: item.userStatus,
  })
    .then((res) => {
      console.log(res);
      Message.success("修改成功");
      handlePageChange(
        pagination.value.current === undefined ? 1 : pagination.value.current,
        10
      );
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};
const deleteRole = (id, index) => {
  Api.delRole({
    ids: id,
  })
    .then((res) => {
      console.log(res);
      Message.success("删除成功");
      handlePageChange(
        pagination.value.current === undefined ? 1 : pagination.value.current,
        10
      );
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};
//#endregion
//#region  重制
const reset = () => {
  name.value = "";
  selectStatus.value = "";
  resultSelect.value = "";
};
//#endregion
//#region 列表
const selectChange = (e) => {
  console.log(selectStatus.value);
};
const columns = [
  {
    title: "ID",
    dataIndex: "userid",
  },
  {
    title: "姓名",
    dataIndex: "username",
  },
  {
    title: "手机号",
    dataIndex: "phone",
  },
  {
    title: "最后登录时间",
    dataIndex: "create_time",
  },
  {
    title: "状态",
    dataIndex: "userStatus",
    slotName: "userStatus",
  },
  {
    title: "身份",
    dataIndex: "userIdentity",
    slotName: "userIdentity",
  },
  {
    title: "操作",
    slotName: "controls",
  },
];
const datas = ref([]);
const pagination = ref({
  total: 0, // 数据总条目数
  showTotal: true, // 是否显示总条目数
  defaultPageSize: 10, // 默认每页显示条目数
  pageSize: 10, // 当前每页显示条目数
  current: 1, // 当前页码
  showJumper: true,
  onChange: (page, pageSize) => {
    handlePageChange(page, pageSize);
  },
});
const resultSelect = ref("");

const handlePageChange = (page, pageSize) => {
  const pageIndex = page ? page : pagination.value.current;
  if (selectStatus.value == "已启用") {
    resultSelect.value = 0;
  } else if (selectStatus.value == "已禁用") {
    resultSelect.value = 1;
  } else {
    resultSelect.value = "";
  }
  datas.value = [];
  Api.specRoleList({
    pageIndex: pageIndex,
    nameS: name.value,
    userStatus: resultSelect.value,
    ruserid:
      JSON.parse(localStorage.getItem("userInfo")).roleid == 1
        ? ""
        : JSON.parse(localStorage.getItem("userInfo")).id,
    adminId:
      JSON.parse(localStorage.getItem("userInfo")).roleid == 1
        ? ""
        : JSON.parse(localStorage.getItem("userInfo")).id,
  })
    .then((res) => {
      console.log(res);
      pagination.value.current = page; // 更新当前页码
      pagination.value.total = res.data.total; // 更新总条目数
      datas.value = [...res.data.datalist];
      console.log(datas.value);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};

const roleList = ref([]);
const getRoleLists = (page, pageSize) => {
  Api.getRoleList({
    pageSize: 1000,
    roleName: name.value,
    status: 1,
    ruserid:
      JSON.parse(localStorage.getItem("userInfo")).roleid == 1
        ? ""
        : JSON.parse(localStorage.getItem("userInfo")).id,
    adminId:
      JSON.parse(localStorage.getItem("userInfo")).roleid == 1
        ? ""
        : JSON.parse(localStorage.getItem("userInfo")).id,
  })
    .then((res) => {
      roleList.value = [...res.data.datalist];
      console.log(roleList.value);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};
onMounted(() => {
  // 更新 powerTree 的值
  handlePageChange();
  getRoleLists();
});
const editName = ref("");
const openEdit = (item) => {
  addRole.value = true;
  roleTitle.value = "编辑身份";
  console.log(item);
  roleFrom.value.phone = item.phone;
  roleFrom.value.username = item.username;
  roleFrom.value.userid = item.userid;
  if (item.userStatus == 0) {
    roleFrom.value.status = "yes";
  } else {
    roleFrom.value.status = "no";
  }
  editName.value = item.role_name;
  const params = roleList.value
    .filter((child) => child.roleName == item.role_name)
    .map((child) => child.id);
  roleFrom.value.roles = params.toString();
  roleFrom.value.id = item.userid;
  console.log(roleFrom.value.roles);
};
//#endregion
//#region  添加管理员

const addRole = ref(false);
const roleTitle = ref("创建身份");
const selectRole = ref("");

const changeRole2 = (id,userid) => {
  Api.updateUserStatus({
    roles: id,
    id:userid
  }).then((res) => {
    console.log(res);
    handlePageChange(
      pagination.value.current === undefined ? 1 : pagination.value.current,
      10
    );
  });
};
const changeRole = () => {
  roleFrom.value.roles = selectRole.value;
};
const openAdd = () => {
  roleTitle.value = "创建身份";
  addRole.value = true;
  editName.value = "";
  roleFrom.value = {
    phone: "",
    password: "",
    username: "",
    roles: -1,
    status: 0,
  };
};
const roleFrom = ref({
  phone: "",
  password: "",
  username: "",
  roles: -1,
  status: 0,
  id: "",
});
const resultRoleStatus = ref(-1);
const handleSubmitAddRole = () => {
  if (roleFrom.value.roles == -1) {
    return;
  }

  if (roleFrom.value.status == "yes") {
    resultRoleStatus.value = 0;
  } else {
    resultRoleStatus.value = 1;
  }
  if (!roleFrom.value.password) return;
  const encryptedPassword = CryptoJS.MD5(roleFrom.value.password).toString();
  //查询是否存在该用户
  if (roleFrom.value.userid) {
    //修改用户信息
    Api.updateUserStatus({
      phone: roleFrom.value.phone,
      username: roleFrom.value.username,
      status: resultRoleStatus.value,
      roles: roleFrom.value.roles,
      passwordS: encryptedPassword,
      ruserid: JSON.parse(localStorage.getItem("userInfo")).id,
      adminId: JSON.parse(localStorage.getItem("userInfo")).id,
      id: roleFrom.value.id,
    }).then((res) => {
      console.log(res);
      addRole.value = false;
      roleFrom.value = {
        phone: "",
        password: "",
        username: "",
        roles: -1,
        status: 0,
      };
      editName.value = "";
      selectRole.value = "";
      roleTitle.value = "创建身份";
      handlePageChange(
        pagination.value.current === undefined ? 1 : pagination.value.current,
        10
      );
    });
  } else {
    //创建新用户
    Api.addUser({
      phone: roleFrom.value.phone,
      username: roleFrom.value.username,
      status: resultRoleStatus.value,
      roles: roleFrom.value.roles,
      ruserid: JSON.parse(localStorage.getItem("userInfo")).id,
      adminId: JSON.parse(localStorage.getItem("userInfo")).id,
      password: encryptedPassword,
    }).then((res) => {
      console.log(res);
      addRole.value = false;
      roleFrom.value = {
        phone: "",
        password: "",
        username: "",
        roles: -1,
        status: 0,
      };
      editName.value = "";
      selectRole.value = "";
      roleTitle.value = "创建身份";
      handlePageChange(
        pagination.value.current === undefined ? 1 : pagination.value.current,
        10
      );
    });
  }
};

//#endregion
</script>
<style lang="scss" scoped>
.list_products {
  background: #ffffff;
  border-radius: 2px;
  height: 100%;
  box-sizing: border-box;
  padding: 15px 0;

  .item_desc,
  :deep(.arco-input-prepend) {
    font-weight: 400;
    white-space: nowrap;
    color: #000000;
    font-size: 14px;
    background-color: red;
    background: #ffffff !important;
    border: none;
  }

  .desc_width {
    width: 100px;
    width: 70px;
  }

  .list_content_header {
    .list_item {
      font-size: 14px;
      color: #333333;
      padding: 13px 6px;
      border: 1px solid #e6e6e6;
      width: 106px;
      box-sizing: border-box;
      text-align: center;
      cursor: pointer;
    }

    .active {
      color: #6784fd;
    }
  }

  :deep(.arco-input-wrapper) .arco-input::placeholder,
  :deep(.arco-select-view-single) .arco-select-view-input::placeholder {
    font-size: 12px !important;
  }

  .search_header {
    height: 12vh;
  }

  .list_content {
    flex: 1;
    padding: 0 1%;
    transform: translateY(-40px);
  }
}
</style>
