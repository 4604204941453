import axios from "axios";
import $Com from "./common";
import router from "@/router"; // 根据你的项目结构调整 import 路径
import config from "./requrstConfig";

let reqList = {};
let reqInd = 0;
let loadingReqList = 0;

// 处理失败
const requestErr = (error, retry) => {
  if (error.response) {
    const response = error.response;
    const statusMessages = {
      400: response.data.message,
      401: response.data.message,
      403: response.data.message,
      404: response.data.message,
      405: response.data.message,
      408: response.data.message,
      422:
        Object.keys(response.data.errors.message || {})
          .map((k) => response.data.errors.message[k].join(","))
          .join("\n") || "网络不给力！！请稍后再试",
      500:
        response.data.message === "Unauthenticated."
          ? "请重新登录"
          : "网络不给力！！请稍后再试",
      501: response.data.message,
      502: response.data.message,
      503: response.data.message,
      504: response.data.message,
      505: response.data.message,
      default: response.data.message,
    };
    const message = statusMessages[response.status] || statusMessages.default;

    if ([401, 403, 405, 422, 500].includes(response.status)) {
      // 处理需要重新登录的情况
      // uni.removeStorageSync("token");
      // uni.removeStorageSync("user");
      // $Com.login()
      //   .then(retry)
      //   .catch((err) => console.error("重新登录失败", err));
    } else {
      // 显示错误信息
      console.error(message);
    }
  } else if (error.request) {
    // 请求发送但没有收到响应
    console.error("网络错误，请检查网络连接");
  } else {
    // 其他错误
    console.error("请求失败:", error.message);
  }
};

// 请求方法封装
const requestMethod = (allParams) => {
  const token = localStorage.getItem("token"); // 替换为适合你的 token 获取方式

  if (allParams.auth && !token) {
    // 清空所有缓存
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    // 跳转到登录页
    router.push("/login");
    // 终止请求
    return;
  }

  let newUrl = allParams.url;
  if (allParams.method === "get" && allParams.params) {
    newUrl += "?" + new URLSearchParams(allParams.params).toString();
  }

  const headers = {
    "Content-Type": allParams.file ? "multipart/form-data" : "application/json",
    "Accept-Language": localStorage.getItem("lang") || "en", // 替换为适合你的语言设置方式
    ...(allParams.auth ? { token: token } : {}),
  };

  const needUserId = allParams.needUserId !== false;

  const paramsWithUserId = {
    ...allParams.params,
    ...(needUserId
      ? {
          // userid
          useridList : localStorage.getItem("userInfo")
            ? JSON.parse(localStorage.getItem("userInfo")).useridList
            : "",
        }
      : {}),
  };

  if (allParams.silently) {
    // 显示 loading
    // loadingReqList++;
  }

  if (!allParams.reqId) {
    reqInd++;
    allParams.reqId = reqInd;
  }

  const axiosConfig = {
    url: newUrl,
    method: allParams.method,
    headers: headers,
    timeout: 20000,
    // params: allParams.method === "get" ? paramsWithUserId : null,
    data: allParams.method !== "get" ? paramsWithUserId : null,
  };

  axios(axiosConfig)
    .then((response) => {
      if (allParams.silently) {
        // 隐藏 loading
        // loadingReqList--;
        // if (loadingReqList === 0) {
        //   uni.hideLoading();
        // }
      }

      if (response.status === 200) {
        if (!newUrl.includes("upload/image")) {
          response.data = JSON.parse(
            JSON.stringify(response.data).replace(
              /\/storage\//g,
              config.imgHost + "/storage/"
            )
          );
        }
        allParams.success && allParams.success(response.data);
        delete reqList[allParams.reqId];
      } else {
        requestErr(response, () => requestMethod(allParams));
        allParams.error && allParams.error(response.data);
      }
    })
    .catch((error) => {
      requestErr(error, () => requestMethod(allParams));
      allParams.error && allParams.error(error);
    });

  reqList[allParams.reqId] = axiosConfig;
};

// 定义HTTP方法
const get = (url, params, options = {}) => {
  return new Promise((resolve, reject) => {
    requestMethod({
      url,
      method: "get",
      params,
      ...options,
      success: resolve,
      error: reject,
    });
  });
};

const post = (url, params, options = {}) => {
  return new Promise((resolve, reject) => {
    requestMethod({
      url,
      method: "post",
      params,
      ...options,
      success: resolve,
      error: reject,
    });
  });
};

const put = (url, params, options = {}) => {
  return new Promise((resolve, reject) => {
    requestMethod({
      url,
      method: "put",
      params,
      ...options,
      success: resolve,
      error: reject,
    });
  });
};

const del = (url, params, options = {}) => {
  return new Promise((resolve, reject) => {
    requestMethod({
      url,
      method: "delete",
      params,
      ...options,
      success: resolve,
      error: reject,
    });
  });
};

export default {
  get,
  post,
  put,
  del,
};
