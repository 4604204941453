<template>
  <router-view />
</template>

<style lang="scss">
@import '@/assets/css/global.css';

@font-face {
  font-family: 'Source Han Sans CN';
  src: url('./assets/font/SourceHanSansCN-Regular.woff2') format('woff2'),
    url('./assets/font/SourceHanSansCN-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: Source Han Sans CN;
}

* {
  margin: 0;
  padding: 0;
  font-family: Source Han Sans CN;

}

input {
  background: none;
  outline: none;
  border: none;
}

::-webkit-scrollbar {
  display: none;
}

/*每个页面公共css */
/* flex */
.flex {
  display: flex;
}

/* flex水平居中 */
.f-x-c {
  justify-content: center;
}

/* flex水平开头 */
.f-x-s {
  justify-content: flex-start;
}

/* flex水平末尾 */
.f-x-e {
  justify-content: flex-end;
}

/* flex自动沾满两端 */
.f-x-b {
  justify-content: space-between;
}

/* flex水平均分 */
.j-c-s-a {
  justify-content: space-around;
}

/* flex垂直居中 */
.a-i-c {
  align-items: center;
}

/* flex垂直开头 */
.f-y-s {
  align-items: flex-start;
}

/* flex垂直末尾 */
.f-y-e {
  align-items: flex-end;
}

/* flex水平排列 */
.f-r {
  flex-direction: row;
}

/* flex垂直排列 */
.f-c {
  flex-direction: column;
}

/* flex反转水平排列 */
.f-r-r {
  flex-direction: row-reverse;
}

/* flex反转垂直排列 */
.f-c-r {
  flex-direction: column-reverse;
}

/* flex换行 */
.f-w {
  flex-wrap: wrap;
}

/* 文字居中 */
.t-c {
  text-align: center;
}

// 文字居左
.t-l {
  text-align: left;
}

// 文字居右
.t-r {
  text-align: right;
}

// 文字换行
.t-w {
  //  不识别单词一行显示不下默认换行
  word-break: break-all;
  //  识别单词 不会造成单词断开换行
  word-wrap: break-word;
}

/* 背景图尺寸100% */
.b-s-100 {
  background-size: 100% 100%;
}

/* 背景图尺寸自适应 */
.b-s-c {
  background-size: cover;
}

/* 绝对定位 */
.absolute {
  position: absolute;
}

/* 相对定位 */
.relative {
  position: relative;
}

/* 固定定位 */
.fixed {
  position: fixed;
}

/* 外边距居中 */
.center {
  margin: 0 auto;
}

/* 背景图常见样式 */
.bgImg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.BgImg {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

// 单行文本溢出省略
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 多行文本溢出隐藏
.linesEllipsis {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.borderBox {
  box-sizing: border-box;
}
</style>
