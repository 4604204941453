<template>
    <div class="list_products flex f-c">
        <div class="search_header flex f-w a-i-c">
            <a-col :span="6">
                <div class="item flex a-i-c">
                    <div class="item_desc  desc_width_three">商品名称:</div>
                    <a-select allow-search :style="{ width: '160px' }" value-key="id" placeholder="请选择商品名称"
                        v-model="selectCommodityVal" @change="commodityChange" :loading="commodityLoading">
                        <a-option v-for="item of commodityOptions" :value="item" :label="item.name" />
                    </a-select>
                    <!-- :virtual-list-props="{ height: 250 }" -->
                </div>
                <!-- <div class=""> <a-input :style="{ width: '80%' }" placeholder="请输入商品名称" allow-search >
                        <template #prepend>
                            &nbsp&nbsp商品名称:
                        </template>
</a-input></div> -->
            </a-col>
            <a-col :span="6">
                <div class="item flex a-i-c">
                    <div class="item_desc  desc_width_two">商品分类:</div>
                    <a-select :style="{ width: '160px' }" value-key="id" placeholder="请选择商品分类" allow-search
                        v-model="selectCategoryVal" @change="categoryChange" :loading="categoryLoading">
                        <a-option v-for="item of categoryOptions" :value="item" :label="item.name" />
                    </a-select>
                </div>
            </a-col>
            <a-col :span="6">
                <div class="item flex a-i-c">
                    <div class="item_desc desc_right">所在仓库:</div>
                    <a-select :style="{ width: '160px' }" value-key="id" placeholder="请选择所在仓库"
                        :loading="erpstockLoading" allow-search v-model="selectErpstockVal" @change="erpstockChange">
                        <a-option v-for="item of erpstockOptions" :value="item" :label="item.name" />
                    </a-select>

                </div>
            </a-col>
            <a-col :span="2">
                <div class="item btn flex a-i-c" @click="handlePageChange(1, 10)">
                    <a-button type="primary" style="height: 40px;transform: translate(30px);">
                        <template #icon>
                            <icon-search />
                        </template>
                        搜索
                    </a-button>
                </div>
            </a-col>
            <a-col :span="2">
                <div class="item btn">
                    <a-button type="primary" status="success" @click="reset" style="height: 40px;">
                        <template #icon>
                            <icon-refresh />
                        </template>
                        重置
                    </a-button>
                </div>

            </a-col>

            <a-col :span="6">
                <div class="item flex a-i-c mt">
                    <div class="item_desc  desc_width">价格区间:</div>
                    <a-input-number v-model="paramsData.start_price" :style="{ width: '24%' }" class="input-demo"
                        :min="1" hide-button="true">
                        <template #prefix>
                            <span class="money_icon">¥</span>
                        </template>
                    </a-input-number>
                    <span class="nbsp">~</span>
                    <a-input-number v-model="paramsData.end_price" :style="{ width: '24%' }" class="input-demo" :min="1"
                        hide-button="true">
                        <template #prefix>
                            <span class="money_icon">¥</span>
                        </template>
                    </a-input-number>
                </div>
            </a-col>

        </div>
        <div class="center_btn flex f-x-b">
            <div class="flex a-i-c ">
                <!-- <div class="center_item ">
                    <a-button type="primary" @click="excalPop = true" style="height: 40px;">
                        批量导入
                    </a-button>
                </div> -->
                <div class="center_item">
                    <a-button type="primary" @click="excalEvent" style="height: 40px;">
                        导出excl表格
                    </a-button>
                </div>
                <div class="center_item">
                    <a-button type="primary" status="success" @click="changePricePop = true" style="height: 40px;">
                        一键更改价格
                    </a-button>
                </div>
                <!-- <div class="center_item">
                    <a-button type="primary" status="success">
                        一键加入进货单
                    </a-button>
                </div>
                <div class="center_item">
                    <a-button type="primary" status="success">
                        一键加入商城
                    </a-button>
                </div>
                <div class="center_item">
                    <a-button type="outline" status="primary" @click="customizedProducts = true">
                        定制商品
                    </a-button>
                </div> -->
            </div>
            <div class="flex">

                <!-- <div class="center_item">
                    <a-button type="outline" status="warning">
                        <template #icon>
                            <icon-printer />

                        </template>
                        打印
                    </a-button>
                </div> -->

                <div class="center_item">
                    <!-- <a-button type="primary" status="warning" @click="addGoodsEvent">
                        <template #icon>
                            <icon-plus />

                        </template>
                        添加
                    </a-button> -->
                </div>
            </div>
        </div>
        <div class="list_content">
            <!-- // <a-spin /> -->
            <a-space direction="vertical" size="large" fill>
                <a-table :columns="columns" :data="datas" :row-selection="rowSelection" :row-key="'rowKey'"
                    @selectAll="selectedAllKeysEvent" @select="selectedEvent" v-model:selectedKeys="selectedKeys"
                    :pagination="pagination" :bordered="false" :stripe="true">
                    <!-- <template #barcode="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.barcode" />
                        </span>
                        <span v-else>
                            {{ record.barcode }}
                        </span>
                    </template> -->
                    <template #barcode="{ record, rowIndex }">
                        {{ record.barcode }}
                    </template>
                    <!-- //商品名称 -->
                    <template #name="{ record, rowIndex }">
                        <span v-if="record.editable" class="flex a-i-c ">
                            <!-- <a-upload action="/" :fileList="singleImg" :show-file-list="false" @change="onChange"
                                @progress="onProgress">
                                <template #upload-button>
                                    <div :class="`arco-upload-list-item${file && file.status === 'error' ? ' arco-upload-list-item-error' : ''
                                        }`">
                                        <div class="arco-upload-list-picture custom-upload-avatar"
                                            v-if="file && file.url">
                                            <img :src="file.url" />
                                            <div class="arco-upload-list-picture-mask">
                                                <IconEdit />
                                            </div>
                                            <a-progress v-if="file.status === 'uploading' && file.percent < 100"
                                                :percent="file.percent" type="circle" size="mini" :style="{
                                                    position: 'absolute',
                                                    left: '50%',
                                                    top: '50%',
                                                    transform: 'translateX(-50%) translateY(-50%)',
                                                }" />
                                        </div>
                                        <div class="arco-upload-picture-card" v-else>
                                            <div class="arco-upload-picture-card-text">
                                                <IconPlus />
                                            </div>
                                        </div>
                                    </div>
                                </template>
            </a-upload> -->
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.name" />

                        </span>
                        <span v-else>
                            <a-image width="30" height="30" :src="record.firstImg" /> {{ record.name }} </span>
                    </template>
                    <!-- //单位 -->
                    <template #unit="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.unit" />
                        </span>
                        <span v-else>
                            {{ record.unit }}
                        </span>
                    </template>
                    <!-- //型号 -->
                    <template #model="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.model" />
                        </span>
                        <span v-else>
                            {{ record.model }}
                        </span>
                    </template>
                    <!-- //商品分类-->
                    <template #erpcategoryName="{ record, rowIndex }">
                        <!-- <span v-if="record.editable"> -->
                        <a-select :style="{ width: '150px !important' }" v-if="record.editable" value-key="id"
                            placeholder="请选择商品分类" allow-search v-model="singleSelectCategoryVal"
                            @change="singleCategoryChange" :loading="categoryLoading">
                            <a-option v-for="item of categoryOptions" :value="item" :label="item.name" />
                        </a-select>
                        <!-- <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.erpcategoryName" /> -->
                        <!-- </span> -->
                        <span v-else>
                            {{ record.erpcategoryName }}
                        </span>
                    </template>
                    <!-- //所在仓库-->
                    <template #erpstockName="{ record, rowIndex }">
                        <a-select :style="{ width: '150px !important' }" v-if="record.editable" value-key="id"
                            placeholder="请选择所在仓库" :loading="erpstockLoading" allow-search
                            v-model="singleSelectErpstockVal" @change="singleErpstockChange">
                            <a-option v-for="item of erpstockOptions" :value="item" :label="item.name" />
                        </a-select>
                        <!-- <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.erpstockName" />
                        </span> -->
                        <span v-else>
                            {{ record.erpstockName }}
                        </span>
                    </template>
                    <!-- //库存-->
                    <template #stock="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.stock" />
                        </span>
                        <span v-else>
                            {{ record.stock }}
                        </span>
                    </template>
                    <!-- //进货价-->
                    <template #inprice="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.inprice" />
                        </span>
                        <span v-else>
                            {{ record.inprice }}
                        </span>
                    </template>
                    <!-- //预售价-->

                    <!-- //价格-->
                    <template #price="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.price" />
                        </span>
                        <span v-else>
                            {{ record.price }}
                        </span>
                    </template>
                    <template #presaleprice="{ record, rowIndex }">
                        <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.presaleprice" />
                        </span>
                        <span v-else>
                            {{ record.presaleprice }}
                        </span>
                    </template>
                    <!-- //供应商-->
                    <template #customerName="{ record, rowIndex }">
                        <a-select :style="{ width: '150px !important' }" v-if="record.editable" value-key="id"
                            placeholder="请选择供应商" :loading="customerLoading" allow-search
                            v-model="singleSelectCustomerVal" @change="singleCustomerChange">
                            <a-option v-for="item of customerOptions" :value="item" :label="item.name" />
                        </a-select>
                        <!-- <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0" v-model="record.customerName" />
                        </span> -->
                        <span v-else>
                            {{ record.customerName }}
                        </span>
                    </template>
                    <!-- 操作 -->
                    <template #controls="{ record, rowIndex }">
                        <div class="flex " v-if="record.editable" style="white-space: nowrap;">
                            <a-button type="primary" @click="saveRecord(record)">保存</a-button>
                            <a-button status="danger" @click="cancelRecord(record.id)"
                                style="transform: translateX(15px);">取消</a-button>
                        </div>
                        <div v-else class="flex">
                            <a-button type="primary" @click="editRecord(record)">编辑</a-button>
                            <a-button status="danger" @click="deleteRecord(record.id, rowIndex)"
                                style="transform: translateX(15px);">删除</a-button>
                        </div>
                    </template>
                </a-table>
            </a-space>

        </div>
        <!-- //添加商品弹窗 -->
        <a-modal :footer="null" v-model:visible="addGoods" :width="1100" title="编辑商品" alignCenter draggable escToClose>
            <a-form :model="addGoodsForm" @submit="handleSubmitAddGoods">
                <div>
                    <div class="flex a-i-c">
                        <a-form-item field="name" :rules="[{ required: true, message: '请输入商品名称' }]">
                            <div class="good_item_desc">商品名称:</div>
                            <a-input :style="{ width: '248px' }" v-model="addGoodsForm.name" placeholder="请输入商品名称"
                                allow-search />
                        </a-form-item>
                        <a-form-item field="warning">
                            <div class="good_item_desc">预警设置:</div>
                            <a-input :style="{ width: '248px' }" v-model="addGoodsForm.warncount"
                                placeholder="请输入库存预警数量" allow-search />
                        </a-form-item>
                    </div>
                    <div class="flex a-i-c">
                        <a-form-item field="img">
                            <!-- :rules="[{ required: true, message: '请上传商品图片' }]" -->
                            <div class="good_item_desc">商品图片:</div>
                            <a-upload class="upload" action="https://ycapi.yidianchen.com/api/sys/oss/upload"
                                @success="onSuccessUploadAddGoodsImg" :fileList="fileList" :limit="4"
                                accept="image/png, image/jpeg" :show-file-list="false" :default-file-list="file"
                                image-preview list-type="picture-card" @change="onChangeUploadAddGoodsImg" multiple>
                                <template #upload-button>
                                    <div :class="`arco-upload-list-item${file && file.status === 'error' ? ' arco-upload-list-item-error' : ''
                                        }`">
                                        <div class="arco-upload-list-picture custom-upload-avatar"
                                            v-for="item in fileList">
                                            <img :src="item.url" />
                                            <div class="arco-upload-list-picture-mask">
                                                <IconEdit />
                                            </div>
                                        </div>
                                        <div class="arco-upload-picture-card" v-if="fileList.length < 3">
                                            <div class="arco-upload-picture-card-text">
                                                <IconPlus size="28" strokeWidth="2" />
                                                <div
                                                    style="margin-top: 1px; font-weight: 600;font-size: 12px;color: #999999;">
                                                    上传</div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </a-upload>
                        </a-form-item>
                        <a-form-item field="categoryid" :rules="[{ required: true, message: '请选择商品分类' }]">
                            <div class="good_item_desc  ">商品分类:</div>
                            <a-select :style="{ width: '150px !important' }" value-key="id" placeholder="请选择商品分类"
                                allow-search v-model="addGoodsSelectCategoryVal" @change="addGoodsCategoryChange"
                                :loading="categoryLoading">
                                <a-option v-for="item of categoryOptions" :value="item" :label="item.name" />
                            </a-select>
                        </a-form-item>
                    </div>
                    <div class="flex a-i-c">
                        <a-form-item field="erpstockid" :rules="[{ required: true, message: '请选择所在仓库' }]">
                            <div class="good_item_desc ">所在仓库:</div>
                            <a-select :style="{ width: '150px !important' }" value-key="id" placeholder="请选择所在仓库"
                                :loading="erpstockLoading" allow-search v-model="addGoodsSelectErpstockVal"
                                @change="addGoodsErpstockChange">
                                <a-option v-for="item of erpstockOptions" :value="item" :label="item.name" />
                            </a-select>
                        </a-form-item>
                        <a-form-item field="customerid" :rules="[{ required: true, message: '请选择所在供应商' }]">
                            <div class="good_item_desc ">供应商:</div>
                            <a-select :style="{ width: '150px !important' }" value-key="id" placeholder="请选择供应商"
                                :loading="customerLoading" allow-search v-model="addGoodsSelectCustomerVal"
                                @change="addGoodsCustomerChange">
                                <a-option v-for="item of customerOptions" :value="item" :label="item.name" />
                            </a-select>
                        </a-form-item>
                    </div>
                    <div v-for="(spec, index) in addGoodsForm.datalist" :key="index">
                        <a-form-item :field="'models[' + index + '].erpcategoryName'"
                            style="display: inline-block; width: 100%; padding-left: 110px;">
                            <div class="flex a-i-c">
                                <div class="good_item_desc">商品规格:</div>
                                <div class="supplier_box flex a-i-c">
                                    <div class="box_l flex f-w">
                                        <!-- <div>  </div> -->
                                        <div class="supplier_item flex a-i-c">
                                            <div class="item_l">型号:</div>
                                            <div class="item_r">
                                                <input type="text" v-model="spec.model"
                                                    style="border:none; border-bottom: 1px solid #333333; width: 80px; text-align: center; padding-bottom: 5px;">
                                            </div>
                                        </div>
                                        <div class="supplier_item flex a-i-c">
                                            <div class="item_l">单位:</div>
                                            <div class="item_r">
                                                <input type="text" v-model="spec.unit"
                                                    style="border:none; border-bottom: 1px solid #333333; width: 80px; text-align: center; padding-bottom: 5px;">
                                            </div>
                                        </div>
                                        <div class="supplier_item flex a-i-c">
                                            <div class="item_l">库存:</div>
                                            <div class="item_r">
                                                <input type="text" v-model="spec.stock"
                                                    style="border:none; border-bottom: 1px solid #333333; width: 80px; text-align: center; padding-bottom: 5px;">
                                            </div>
                                        </div>
                                        <div class="supplier_item flex a-i-c">
                                            <div class="item_l">价格:</div>
                                            <div class="item_r flex a-i-c">
                                                <span class="money_icon"
                                                    style="color: red; font-size: 18px;">¥</span><input type="text"
                                                    v-model="spec.price"
                                                    style="border:none; border-bottom: 1px solid #333333; width: 80px; text-align: center; padding-bottom: 8px;">
                                                <!-- <a-input-number v-model="spec.price"
                                                    :style="{ border: 'none !important', padding: '0 !important', borderBottom: '1px solid #333333 !important', width: '80px !important', textAlign: 'center' }"
                                                    class="input-demo" :min="1" hide-button="true">
                                                    <template #prefix>
                                                        <span class="money_icon"
                                                            style="color: red; font-size: 18px;">¥</span>
                                                    </template>
                                                </a-input-number> -->
                                            </div>
                                        </div>
                                        <div class="supplier_item flex a-i-c">
                                            <div class="item_l">进货价:</div>
                                            <div class="item_r flex a-i-c">
                                                <span class="money_icon"
                                                    style="color: red; font-size: 18px;">¥</span><input type="text"
                                                    v-model="spec.inprice"
                                                    style="border:none; border-bottom: 1px solid #333333; width: 80px; text-align: center; padding-bottom: 8px;">
                                                <!-- <a-input-number v-model="spec.inprice"
                                                    :style="{ border: 'none !important', padding: '0 !important', borderBottom: '1px solid #333333 !important', width: '80px !important', textAlign: 'center' }"
                                                    class="input-demo" :min="1" hide-button="true">
                                                    <template #prefix>
                                                        <span class="money_icon"
                                                            style="color: red; font-size: 18px;">¥</span>
                                                    </template>
                                                </a-input-number> -->
                                            </div>
                                        </div>
                                        <div class="supplier_item flex a-i-c">
                                            <div class="item_l">调货价:</div>
                                            <div class="item_r flex a-i-c">
                                                <span class="money_icon"
                                                    style="color: red; font-size: 18px;">¥</span><input type="text"
                                                    v-model="spec.presaleprice"
                                                    style="border:none; border-bottom: 1px solid #333333; width: 80px; text-align: center; padding-bottom: 8px;">
                                                <!-- <a-input-number v-model="spec.presaleprice"
                                                    :style="{ border: 'none !important', padding: '0 !important', borderBottom: '1px solid #333333 !important', width: '80px !important', textAlign: 'center' }"
                                                    class="input-demo" :min="1" hide-button="true">
                                                    <template #prefix>
                                                        <span class="money_icon"
                                                            style="color: red; font-size: 18px;">¥</span>
                                                    </template>
                                                </a-input-number> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box_r">
                                        <icon-minus-circle size="50" @click="delSpecification(index)"
                                            v-if="index > 0" />
                                        <icon-plus-circle size="50" @click="addSpecification" v-else />
                                    </div>
                                </div>
                            </div>
                        </a-form-item>
                    </div>
                </div>
                <a-form-item>
                    <div style="width: 75%; text-align: center;">
                        <a-button type="outline" status="primary" size="large" style="margin-right: 20px;"
                            @click="addGoods = false">取消</a-button>
                        <a-button html-type="submit" type="primary" size="large">确定</a-button>
                    </div>
                </a-form-item>
            </a-form>
        </a-modal>
        <!-- 调库 -->
        <!-- 改价-->
        <a-modal :footer="null" v-model:visible="changePricePop" :width="550" title="更改售价" alignCenter draggable
            escToClose>

            <a-form :model="changePriceParams" @submit="handleSubmitChangePrice" class="flex f-x-c">
                <a-form-item style="width: 80%;" field="price">
                    <div class="good_item_desc">改价类型:</div>
                    <a-select :style="{ width: '250px !important' }" value-key="id" placeholder="请选择改价类型" allow-search
                        @change="changePriceEvent" v-model="changePriceType">
                        <a-option v-for="item of changePriceTypeOptions" :value="item" :label="item.name" />
                    </a-select>
                </a-form-item>
                <a-form-item style="width: 80%;"
                    v-if="changePriceType.id === 1 || changePriceType.id === 2 || changePriceType.id === 3">
                    <div class="good_item_desc">价格:</div>
                    <a-input :style="{ width: '250px' }" v-model="changePrice" placeholder="请输入价格" allow-search>
                        <template #prefix v-if="changePriceType.id === 1">
                            <icon-plus /> </template>
                        <template #prefix v-if="changePriceType.id === 2">
                            <icon-minus /> </template>
                        <template #prefix v-if="changePriceType.id === 3">
                            <icon-close /> </template>
                    </a-input>
                </a-form-item>
                <div class="tips flex f-x-c" style="width: 100%; color: red;" v-if="changePriceType.id === 1">
                    请输入正数,表示在原有售价基础上增加金额</div>
                <div class="tips flex f-x-c" style="width: 100%; color: red;" v-if="changePriceType.id === 2">
                    请输入正数,表示在原有售价基础上减少金额</div>
                <div class="tips flex f-x-c" style="width: 100%; color: red;" v-if="changePriceType.id === 3">
                    请输入正数,表示在原有售价基础上按倍数调整价格(0~1 为缩小,1
                    及以上为扩大,包括小数)</div>
                <a-form-item style="text-align: center !important;padding: 0 80px">
                    <a-button type="outline" status="primary" size="large" style="margin-right: 20px;"
                        @click="changePricePop = false">取消</a-button>
                    <a-button html-type="submit" type="primary" size="large">确定</a-button>
                </a-form-item>
            </a-form>

        </a-modal>
        <a-modal v-model:visible="excalPop" @ok="confirmExcl" @cancel="colseExcl" draggable title="批量导入" alignCenter
            :width="800" escToClose>
            <div class="flex f-x-a a-i-c">
                <div>
                    <a-button type="primary" status="warning" @click="">
                        <template #icon>
                            <icon-plus />
                        </template>
                        请先下载模版
                    </a-button>
                </div>

                <div style="margin-left: 10px;">
                    <!-- action="https://ycapi.yidianchen.com/api/rest/v1/goods/imoprtExcalWaterBiao" -->
                    <a-upload multiple action="https://654v7l1019.vicp.fun/api/rest/v1/goods/imoprtExcalWaterBiao"
                        :customRequest="e => customRequest(e, path)" :data="uploadExclData"
                        :on-before-upload="beforeUploadExcl" draggable>
                        <template #upload-button>
                            <a-button type="primary" status="primary" @click="">
                                请导入EXCL
                            </a-button>
                        </template>
                    </a-upload>

                </div>
            </div>

        </a-modal>
    </div>
</template>
<script setup>
import { ref, watch, reactive, getCurrentInstance, onMounted } from 'vue';
import { IconSearch, IconRefresh, IconPrinter, IconPlus, IconClose, IconMinus, IconCodeSandbox, IconMinusCircle, IconPlusCircle } from '@arco-design/web-vue/es/icon';
const Message = getCurrentInstance().appContext.config.globalProperties.$message
const Api = getCurrentInstance()?.appContext.config.globalProperties.$Api
const Com = getCurrentInstance()?.appContext.config.globalProperties.$Com
import axios from 'axios';
onMounted(() => {
    handlePageChange()
    getCommodityLists()
    getErpcategoryLists()
    getCustomerLists()
    getErpstockLists()
});
//#region 模糊选择商品
const commodityOptions = ref([])
const selectCommodityVal = ref()
const commodityLoading = ref(true)

const commodityChange = () => {
    if (selectCommodityVal.value.id === undefined) {
        paramsData.value.id = '';
    } else {
        paramsData.value.id = selectCommodityVal.value.id;
    }
    console.log('商品id', paramsData.value.id);
}
const getCommodityLists = () => {
    Api.getErpStockGoodsLists({
        pagesize: 9999, status: 0, onshop: 1
    }).then(res => {
        commodityOptions.value = res.data.datalist
        commodityLoading.value = false
        console.log(commodityOptions.value);
    })
        .catch(error => {
            // 处理请求失败的逻辑
            console.error('Error fetching data:', error);
        });
}
//#endregion

//#region 分类列表
const categoryOptions = ref([])
const selectCategoryVal = ref()
const categoryLoading = ref(true)
const categoryChange = () => {
    if (selectCategoryVal.value.id === undefined) {
        paramsData.value.categoryid = '';
    } else {
        paramsData.value.categoryid = selectCategoryVal.value.id;
    }
    console.log(paramsData.value.categoryid);

}
const getErpcategoryLists = () => {
    Api.erpcategoryList({ pagesize: 100, }).then(res => {
        // status: 0 
        categoryOptions.value = res.data.datalist
        categoryLoading.value = false
        console.log('分类id', categoryOptions.value);
    })
        .catch(error => {
            // 处理请求失败的逻辑
            console.error('Error fetching data:', error);
        });
}
//#endregion

//#region 仓库列表 
const erpstockOptions = ref([])
const selectErpstockVal = ref()
const erpstockLoading = ref(true)
const erpstockChange = () => {
    if (selectErpstockVal.value.id === undefined) {
        paramsData.value.erpstockid = '';
    } else {
        paramsData.value.erpstockid = selectErpstockVal.value.id;
    }
    console.log('仓库id', paramsData.value.erpstockid);

}
const getErpstockLists = () => {
    Api.erpstockList({ pagesize: 100, }).then(res => {
        // status: 0 
        erpstockOptions.value = res.data.datalist
        erpstockLoading.value = false

        console.log(erpstockOptions.value);
    })
        .catch(error => {
            // 处理请求失败的逻辑
            console.error('Error fetching data:', error);
        });
}
//#endregion
//#region 供应商列表
const customerOptions = ref([])
const selectCustomerVal = ref()
const customerLoading = ref(true)

const customerChange = () => {
    if (selectCustomerVal.value.id === undefined) {
        paramsData.value.customerid = '';
    } else {
        paramsData.value.customerid = selectCustomerVal.value.id;
    }
    console.log('供应商id', paramsData.value.customerid);
}
const getCustomerLists = () => {
    Api.customerList({ pagesize: 100, }).then(res => {
        // status: 0 
        customerOptions.value = res.data.datalist
        customerLoading.value = false

        console.log(customerOptions.value);
    })
        .catch(error => {
            // 处理请求失败的逻辑
            console.error('Error fetching data:', error);
        });
}
//#endregion 
//#region  重制
const reset = () => {
    selectCategoryVal.value = ''
    selectErpstockVal.value = ''
    selectCustomerVal.value = ''
    selectCommodityVal.value = ''

    paramsData.value = {
        name: '',//客户名称
        customerid: '',//供应商id
        categoryid: '',//分类id
        erpstockid: '',//仓库id
        start_price: '',
        end_price: '',
        start_stock: '',
        end_stock: '',
        id: '',//商品id'
        onshop: 1
    }
}
//#endregion  
//#region 列表功能**//
const paramsData = ref({
    name: '',//客户名称
    customerid: '',//供应商id
    categoryid: '',//分类id
    erpstockid: '',//仓库id
    start_price: '',
    end_price: '',
    start_stock: '',
    end_stock: '',
    id: '',//商品id'
    onshop: 1

})
const columns = [{
    title: '编号',
    dataIndex: 'barcode',
    slotName: 'barcode'
}, {
    title: '商品名称',
    dataIndex: 'name',
    slotName: 'name'

},
{
    title: '单位',
    dataIndex: 'unit',
    slotName: 'unit'

},
{
    title: '型号',
    dataIndex: 'model',
    slotName: 'model'

},
{
    title: '商品分类',
    dataIndex: 'erpcategoryName',
    slotName: 'erpcategoryName'

}, {
    title: '所在仓库',
    dataIndex: 'erpstockName',
    slotName: 'erpstockName'

}, {
    title: '库存',
    dataIndex: 'stock',
    slotName: 'stock'

}, {
    title: '进货价',
    dataIndex: 'inprice',
    slotName: 'inprice'

}, {
    title: '预售价',
    dataIndex: 'presaleprice',
    slotName: 'presaleprice'

}, {
    title: '销售价',
    dataIndex: 'price',
    slotName: 'price'

}, {
    title: '供应商',
    dataIndex: 'customerName',
    slotName: 'customerName'

},
{
    title: '操作',
    slotName: 'controls'
}
];
const datas = ref([])
const selectedKeys = ref([]);
const rowSelection = reactive({
    type: 'checkbox',
    showCheckedAll: true,
    onlyCurrent: false,
    selectedRowKeys: selectedKeys.value, // 当前选中的行 key 数组
    onChange: (selectedRowKeys, selectedRows, record) => {
        selectedKeys.value = selectedRowKeys;
        console.log('selectedRows:', selectedRows, record);
    }
});
//分页
const pagination = ref({
    total: 0,  // 数据总条目数
    showTotal: true,            // 是否显示总条目数
    defaultPageSize: 10,        // 默认每页显示条目数
    pageSize: 10,               // 当前每页显示条目数
    current: 1,                 // 当前页码
    showJumper: true,
    onChange: (page, pageSize) => {
        handlePageChange(page, pageSize);
    }
});
const handlePageChange = (page, pageSize) => {
    console.log('page:', page, 'pageSize:', pageSize);
    const pageIndex = page ? page : pagination.value.current;
    Api.getErpStockGoodsLists({
        pageIndex: pageIndex,
        status: 0,
        ...paramsData.value
    }).then(res => {
        console.log(res);
        pagination.value.current = page;        // 更新当前页码
        res.data.datalist.forEach(item => {
            item.editable = false;
            item.rowKey = `${item.id}_${item.goodsid}`
            item.firstImg = Com.getFirstImage(item.img)
        });
        pagination.value.total = res.data.total; // 更新总条目数
        datas.value = [...res.data.datalist];
        console.log(datas.value);
    }).catch(error => {
        console.error('Error fetching data:', error);
    });
};
//#endregion

//#region 选择数据 单选多选
//全选
const selectedIds = ref([]);
const selectedGoodsIds = ref([]);
const selectedIdsString = ref('');
const selectedGoodsIdsString = ref('');
const clearSelectData = () => {
    // 清空数组
    selectedIds.value = [];
    selectedGoodsIds.value = [];
    // 清空字符串变量
    selectedIdsString.value = '';
    selectedGoodsIdsString.value = '';
    selectedKeys.value = []
}
const selectedAllKeysEvent = val => {
    if (val) {
        // 清空数组
        selectedIds.value = datas.value.map(row => row.id);
        selectedGoodsIds.value = datas.value.map(row => row.goodsid);
        // 更新字符串变量
        selectedIdsString.value = selectedIds.value.join(',');
        selectedGoodsIdsString.value = selectedGoodsIds.value.join(',');
    } else {
        clearSelectData()
    }

    // 输出变量值
    console.log('Selected IDs:', selectedIdsString.value);
    console.log('Selected Goods IDs:', selectedGoodsIdsString.value);
};
//单选
const selectedEvent = val => {
    // 清空之前的数据
    selectedIds.value = [];
    selectedGoodsIds.value = [];

    // 对 val 进行类型检查或者空值检查
    if (!Array.isArray(val)) {
        console.error('Invalid value for selectedEvent:', val);
        return;
    }

    // 使用 reduce 方法获取 selectedIds 和 selectedGoodsIds
    val.forEach(item => {
        const [id, goodsid] = item.split('_');
        selectedIds.value.push(id);
        selectedGoodsIds.value.push(goodsid);
    });

    // 转换为字符串
    selectedIdsString.value = selectedIds.value.join(',');
    selectedGoodsIdsString.value = selectedGoodsIds.value.join(',');

    // 输出变量值
    console.log('Selected IDs:', selectedIdsString.value);
    console.log('Selected Goods IDs:', selectedGoodsIdsString.value);
};
//#endregion
//#region 单个数据操作 编辑 取消 删除
//#region 单个商品编辑
const singleSelectCustomerVal = ref() // 单个选择供应商
const singleSelectErpstockVal = ref() // 单个选择仓库
const singleSelectCategoryVal = ref()//单个选择分类
const singleEditForm = ref({
    // img: '',//商品图片
    name: '',//商品名称
    unit: '',//单位
    stock: '',//库存 
    model: '',//型号
    customerid: '',//供应商id
    erpstockid: '',//仓库id
    categoryid: '',//分类id
    inprice: '',//
    price: '',
    presaleprice: ''
})
const clearSingleEditFrom = () => {
    singleEditForm.value = {
        img: '',//商品图片
        name: '',//商品名称
        unit: '',//单位
        stock: '',//库存
        customerid: '',//供应商id
        erpstockid: '',//仓库id
        categoryid: ''//分类id
    }
    singleSelectCustomerVal.value = ''
    singleSelectCustomerVal.value = ''
    singleSelectCategoryVal.value = ''
}
const singleCustomerChange = () => {
    if (singleSelectCustomerVal.value.id === undefined) {
        singleEditForm.value.customerid = '';
    } else {
        singleEditForm.value.customerid = singleSelectCustomerVal.value.id;
    }
    console.log('供应商id', singleEditForm.value.customerid);
}
const singleErpstockChange = () => {
    if (singleSelectErpstockVal.value.id === undefined) {
        singleEditForm.value.erpstockid = '';
    } else {
        singleEditForm.value.erpstockid = singleSelectErpstockVal.value.id;
    }
    console.log('仓库id', singleEditForm.value.erpstockid);

}
const singleCategoryChange = () => {
    if (singleSelectCategoryVal.value.id === undefined) {
        singleEditForm.value.categoryid = '';
    } else {
        singleEditForm.value.categoryid = singleSelectCategoryVal.value.id;
    }
    console.log('分类id', singleEditForm.value.categoryid);

}
//#endregion
//保存单个
const saveRecord = (item) => {
    // console.log(item);
    singleEditForm.value = { ...item }
    console.log(singleSelectCategoryVal.value);
    if (singleSelectCategoryVal.value) {
        singleEditForm.value.categoryid = singleSelectCategoryVal.value.id
    }
    if (singleSelectErpstockVal.value) {
        singleEditForm.value.erpstockid = singleSelectErpstockVal.value.id
    }
    if (singleSelectCustomerVal.value) {
        singleEditForm.value.customerid = singleSelectCustomerVal.value.id
    }
    console.log(singleEditForm.value);
    //TODO::    Api.待完善 编辑接口
    const goods = {
        name: singleEditForm.value.name,
        customerid: singleEditForm.value.customerid,
        erpstockid: singleEditForm.value.erpstockid,
        categoryid: singleEditForm.value.categoryid,
        id: singleEditForm.value.goodsid,
    }
    const goodssize = {
        unit: singleEditForm.value.unit,
        model: singleEditForm.value.model,
        stock: singleEditForm.value.stock,
        presaleprice: singleEditForm.value.presaleprice,
        inprice: singleEditForm.value.inprice,
        price: singleEditForm.value.price,
        id: singleEditForm.value.id,

    }
    Api.updateGoods({ goods: { ...goods }, goodssize: { ...goodssize } }).then(res => {
        datas.value = []
        handlePageChange(pagination.value.current === undefined ? 1 : pagination.value.current, 10)
    })
    console.log(singleEditForm.value);
}
// 编辑单个
const addGoodsStatus = ref(0) // 0新增,1编辑
const addGoodsEvent = () => {
    addGoodsForm.value = {
        img: '',//商品图片
        name: '',//商品名称
        type: 0,
        unit: '',//单位
        stock: '',//库存
        customerid: '',//供应商id
        erpstockid: '',//仓库id
        categoryid: '',//分类id
        status: 0,//状态 0新增 1编辑 3删除
        datalist: [{
            model: "", //型号
            unit: '', //单位
            stock: '',//库存
            price: '', //销售价
            inprice: '', //进货价
            presaleprice: '' //预售价
        }]//规格
    }
    addGoodsSelectCustomerVal.value = ''
    addGoodsSelectErpstockVal.value = ''
    addGoodsSelectCategoryVal.value = ''

    addGoods.value = true
    clearAddGoodsEditFrom()
}
const editRecord = (item) => {
    fileList.value = []
    addGoodsForm.value = item
    addGoodsForm.value.datalist.forEach(item => {
        item.status = 1;
    });
    addGoodsStatus.value = 1
    console.log(addGoodsForm.value);
    addGoodsSelectCategoryVal.value = addGoodsForm.value.erpcategoryName
    addGoodsSelectErpstockVal.value = addGoodsForm.value.erpstockName
    addGoodsSelectCustomerVal.value = addGoodsForm.value.customerName
    // 清空 customizedProductsData.value 数组
    // customizedProductsData.value = [];
    if (addGoodsForm.value.img) {
        const urls = addGoodsForm.value.img.trim().split(',').map(url => url.trim());// 清空 fileList1 数组，确保不重复添加图片
        urls.forEach(url => {
            fileList.value.push({
                url: url,
            });
        });
    }

    // 将 addGoodsForm.value.datalist 数组的数据复制给 customizedProductsData.value 数组
    // 使用 map 方法创建新数组，复制每个元素并添加到数组末尾
    // const clonedDataList = addGoodsForm.value.datalist.map(item => ({
    //     name: item.name || '',
    //     img: item.img || '',
    //     model: item.model || '',
    //     unit: item.unit || '',
    //     selectQty: item.selectQty || '',
    //     price: item.price || '',
    //     totalPrice: item.totalPrice || '',
    //     remark: item.remark || '',
    //     status: item.status == 0 ? 1 : item.status //改变状态 0新增状态变为1编辑状态
    // }));
    // 将复制后的对象添加到原数组末尾
    // addGoodsForm.value.datalist = addGoodsForm.value.datalist.concat(clonedDataList);

    console.log('编辑的数组', addGoodsForm.value);
    // const addGoodsSelectCustomerVal = ref() // 单个选择供应商
    // const addGoodsSelectErpstockVal = ref() // 单个选择仓库
    // const addGoodsSelectCategoryVal = ref()//单个选择分类

    addGoods.value = true
    // clearSingleEditFrom()
    // datas.value.forEach(item => {
    //     if (item.id === id) {
    //         item.editable = true;
    //     } else {
    //         item.editable = false;
    //     }
    // });
    // editingKey.value = id;

};
//取消单个
const cancelRecord = (id) => {
    datas.value.forEach(item => {
        if (item.id === id) {
            item.editable = false;
        }
    });
    editingKey.value = '';
    clearSingleEditFrom()
}
// 删除单个
const deleteRecord = (id) => {
    Api.changePcGoods({ ids: id, type: 4, status: 1 }).then(res => {
        Message.success(res.message);
        changePricePop.value = false
        clearSelectData();
        datas.value = []
        handlePageChange(pagination.value.current === undefined ? 1 : pagination.value.current, 10)

    }).catch(error => {
        console.error('Error changing goods:', error);
    });
};
//#endregion
//#endregion

//#region 一键更改价格 
const changePricePop = ref(false);
const changePrice = ref('')
const changePriceType = ref('')
const changePriceTypeOptions = ref([
    {
        id: 1,
        name: '在原售价基础上加价'
    },
    {
        id: 2,
        name: '在原售价基础上减价'
    },
    {
        id: 3,
        name: '在原售价基础上倍数改价'
    },
]);
const changePriceParams = ref({
    type: -1,
    ids: '',
    goodsIds: '',
    price: ''
});
const changePriceEvent = (e) => {
    console.log(changePriceType.value);
}
const handleSubmitChangePrice = () => {
    // 检查选中的商品 IDs 是否已经设置
    if (!selectedIdsString.value || !selectedGoodsIdsString.value) {
        return Message.warning('请选择商品');
    }
    if (!changePriceType.value.id)
        return Message.warning('请选择改价类型');
    changePriceParams.value.type = changePriceType.value.id;
    if (!changePrice.value)
        return Message.warning('请输入价格/倍数');
    changePriceParams.value.price = changePrice.value;

    // 设置参数
    changePriceParams.value.ids = selectedIdsString.value;
    changePriceParams.value.goodsIds = selectedGoodsIdsString.value;
    console.log('Submitting change price request with params:', changePriceParams.value);
    Api.changePrice({ ...changePriceParams.value }).then(res => {
        Message.success(res.message);
        changePricePop.value = false
        changePrice.value = ''
        changePriceParams.value = {
            type: -1,
            ids: '',
            goodsIds: '',
            price: ''
        }
        datas.value = []
        clearSelectData();
        handlePageChange(pagination.value.current === undefined ? 1 : pagination.value.current, 10)
    }).catch(error => {
        console.error('Error changing goods:', error);
    });
};

//#endregion
//#region 添加商品功能**//
const addGoodsSelectCustomerVal = ref() // 单个选择供应商
const addGoodsSelectErpstockVal = ref() // 单个选择仓库
const addGoodsSelectCategoryVal = ref()//单个选择分类
const addGoods = ref(false); //弹窗真假
const addGoodsForm = ref({
    name: '',//商品名称
    img: '',//商品图片
    warncount: '',//预警设置
    categoryid: '',//商品分类
    erpstockid: '',//所在仓库
    type: 0,
    customerid: '',//供应商
    datalist: [{
        model: "", //型号
        unit: '', //单位
        stock: '',//库存
        price: '', //销售价
        inprice: '', //进货价
        presaleprice: '' //预售价
    }]//规格
});
const clearAddGoodsEditFrom = () => {
    addGoodsForm.value = {
        img: '',//商品图片
        name: '',//商品名称
        type: 0,
        unit: '',//单位
        stock: '',//库存
        customerid: '',//供应商id
        erpstockid: '',//仓库id
        categoryid: '',//分类id
        datalist: [{
            model: "", //型号
            unit: '', //单位
            stock: '',//库存
            price: '', //销售价
            inprice: '', //进货价
            presaleprice: '' //预售价
        }]//规格
    }
    addGoodsSelectCustomerVal.value = ''
    addGoodsSelectCustomerVal.value = ''
    addGoodsSelectCategoryVal.value = ''
}
const addGoodsCustomerChange = () => {
    if (addGoodsSelectCustomerVal.value.id === undefined) {
        addGoodsForm.value.customerid = '';
    } else {
        addGoodsForm.value.customerid = addGoodsSelectCustomerVal.value.id;
    }
    console.log('供应商id', addGoodsForm.value.customerid);
}
const addGoodsErpstockChange = () => {
    if (addGoodsSelectErpstockVal.value.id === undefined) {
        addGoodsForm.value.erpstockid = '';
    } else {
        addGoodsForm.value.erpstockid = addGoodsSelectErpstockVal.value.id;
    }
    console.log('仓库id', addGoodsForm.value.erpstockid);

}
const addGoodsCategoryChange = () => {
    if (addGoodsSelectCategoryVal.value.id === undefined) {
        addGoodsForm.value.categoryid = '';
    } else {
        addGoodsForm.value.categoryid = addGoodsSelectCategoryVal.value.id;
    }
    console.log('分类id', addGoodsForm.value.categoryid);

}
const file = ref();
const fileList = ref([])
const onChangeUploadAddGoodsImg = (_, currentFile) => {
    // file.value = {
    //     ...currentFile,
    //     url: URL.createObjectURL(currentFile.file),
    // };
};
const onSuccessUploadAddGoodsImg = (res, currentFile) => {
    console.log('onSuccessUploadAddGoodsImg', res);
    const fileUrl = res.response.data.fileUrl;
    const index = fileList.value.findIndex(item => item.uid === res.uid);
    console.log('index: 替换操作' + index);
    // if (index !== -1) {
    // fileList.value[index] = {
    //     ...fileList.value[index],
    //     status: 'done',
    //     url: fileUrl,
    // };
    // } else {
    fileList.value.push({
        uid: res.uid,
        // name: currentFile.name,
        status: 'done',
        url: fileUrl,
    });
    // }
    // file.value = {
    //     url: response.response.data.fileUrl,
    //     uid: response.uid
    // };
    // console.log('Upload Success:', file.value, ' fileConst:', fileList.value);
    // 更新 addGoodsForm.img 字符串
    addGoodsForm.value.img = fileList.value.map(file => file.url).join(',');
    console.log('Upload Success:', fileUrl, ' fileList:', fileList.value, ' addGoodsForm.value.img ', addGoodsForm.value.img);
};


const addSpecification = () => {
    addGoodsForm.value.datalist.push({
        model: "", //型号
        unit: '', //单位
        stock: '',//库存
        price: '', //销售价
        inprice: '', //进货价
        presaleprice: '',//预售价
        status: 0//新添加的状态 0
    });
};
// 创建一个数组来存储被删除的元素
const deletedItems = ref([]); //存起来被删除的数组 并且改变status 删除状态为3
const delSpecification = (index) => {
    // 将被删除的元素存入 deletedItems 数组，并修改状态为3
    deletedItems.value.push({ deletedld: addGoodsForm.value.datalist[index].id, status: 3 });
    addGoodsForm.value.datalist.splice(index, 1);
    console.log('删除的数组', deletedItems.value);
    console.log('删除后的数组', addGoodsForm.value.datalist);


};
const handleSubmitAddGoods = (data) => {
    if (addGoodsForm.value.datalist.length === 0) {
        return Message.warning('请添加规格');
    }
    console.log(addGoodsStatus.value);
    if (addGoodsStatus.value == 0) {
        Api.addGoods({ ...addGoodsForm.value }).then(res => {
            addGoods.value = false
            clearAddGoodsEditFrom()
            handlePageChange(pagination.value.current === undefined ? 1 : pagination.value.current, 10)
        })
    } else if (addGoodsStatus.value == 1) {
        if (addGoodsForm.value.id) {
            addGoodsForm.value.id = addGoodsForm.value.goodsid
        }
        Api.editGoodsInfo({ ...addGoodsForm.value }).then(res => {
            addGoods.value = false
            clearAddGoodsEditFrom()
            handlePageChange(pagination.value.current === undefined ? 1 : pagination.value.current, 10)
            addGoodsStatus.value == 0
        })
    }
};
//#endregion
//#region 导入
const excalPop = ref(false)
const uploadExclData = ref({
    userid: JSON.parse(localStorage.getItem("userInfo")).id,
    exfile: null,
});

const customRequest = ({ file }) => {
    axios.post('https://654v7l1019.vicp.fun/api/rest/v1/goods/imoprtExcalWaterBiao', { ...uploadExclData.value })
        .then(response => {
            console.log('上传成功', response.data);
            // 根据上传成功后的逻辑进行处理
        })
        .catch(error => {
            console.error('上传失败', error);
            // 根据上传失败后的逻辑进行处理
        });
};
const beforeUploadExcl = (file) => {
    return new Promise((resolve, reject) => {
        // 判断文件类型是否为 Excel 文件
        if (!isExcelFile(file)) {
            reject(new Error('只能上传 EXCL 文件'));
            return;
        }
        const reader = new FileReader();
        reader.onload = (event) => {
            const base64Data = event.target.result;
            console.log('Excel 文件的 base64 数据:', base64Data);
            // 构造一个对象来存储文件和 base64 数据
            const excelData = {
                base64: base64Data
            };
            // 更新 uploadExclData.value.exfile 的值为 excelData 对象
            uploadExclData.value.exfile = base64Data;
            resolve(excelData); // 将包含文件和 base64 数据的对象作为 resolve 的参数，用于继续上传或其他操作
        };

        // 将文件读取为 base64 格式
        reader.readAsDataURL(file);
    });
};
// 判断文件类型是否为 Excel 文件
const isExcelFile = (file) => {
    const fileType = file.type; // 获取文件的 MIME 类型
    return fileType === 'application/vnd.ms-excel' || // xls
        fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; // xlsx
};
const confirmExcl = () => { excalPop.value = false }
const colseExcl = () => { excalPop.value = false }
//#endregion

//#region 导出
const excalEvent = () => {
    if (!selectedIdsString.value || !selectedGoodsIdsString.value) {
        return Message.warning('请选择商品');
    }
    Api.excal({ ids: selectedIdsString.value, goodsIds: selectedGoodsIdsString.value, }).then(res => {
        const downloadUrl = res.data.data;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'filename.xlsx'); // 设置下载文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })
}

//#endregion
</script>
<style scoped lang="scss">
.list_products {
    background: #FFFFFF;
    border-radius: 2px;
    height: 100%;
    box-sizing: border-box;
    padding: 15px 0;

    .editable-row-operations a {
        margin-right: 8px;
    }

    .item {
        margin: 0 20px;
        width: 100%;
    }



    .mt {
        margin-top: 20px;
    }

    .nbsp {
        width: 30px;
        color: #999999;
        text-align: center;
    }

    .item_desc,
    :deep(.arco-input-prepend) {
        font-weight: 400;
        white-space: nowrap;
        color: #000000;
        font-size: 14px;
        background-color: red;
        background: #FFFFFF !important;
        border: none;
    }

    .desc_width {
        width: 100px;
        width: 70px;
    }

    :deep(.arco-input-wrapper) .arco-input::placeholder,
    :deep(.arco-select-view-single) .arco-select-view-input::placeholder {
        font-size: 12px !important;

    }

    .desc_right {
        margin-right: 12px;
    }

    .desc_right_t {
        margin-right: 2px;

    }

    .desc_width_two {
        width: 23%;
        margin-right: 12px;
        text-align: right;
    }

    .desc_width_three {
        margin-right: 12px;
        text-align: right;
    }

    .money_icon {
        font-weight: 500;
        font-size: 12px;
        color: #FF4D4C;
    }


    :deep(.arco-input-wrapper),
    :deep(.arco-select-view-single) {
        border-radius: 2px;
        border: 1px solid #E6E6E6;
        background: #FFFFFF !important;
    }

    :deep(.arco-select-view-single) {
        box-sizing: border-box;
        width: 53% !important;
    }

    .center_btn {
        padding: 1%;

        .center_item {
            margin: 25px 10px 10px;
            font-style: 10px;
        }
    }

    .list_content {
        flex: 1;
        padding: 0 1%;
    }

    .btn_controls {
        margin: 0 3px;
    }



}

:deep(.arco-table .arco-table-cell) {
    padding: 10px 3px;
}

:deep(.arco-table-td-content) {
    box-sizing: border-box;
}
</style>