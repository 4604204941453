<template>
  <div class="list_products">
    <div class="center_btn">
      <div class="flex f-x-e" style="padding: 0 20px 15px 0">
        <div class="center_item">
          <a-button
            type="primary"
            status="warning"
            @click="AddCategoryPopup = true"
            style="height: 40px"
          >
            <template #icon>
              <icon-plus />
            </template>
            添加
          </a-button>
        </div>
      </div>
    </div>
    <div class="list_content">
      <a-space direction="vertical" size="large" fill>
        <a-table
          :columns="columns"
          :data="datas"
          :pagination="pagination"
          :bordered="false"
          :stripe="true"
        >
          <!-- :data="form.noData ? [] : data" -->
          <template #level="{ record, rowIndex }">
            <!-- <span v-if="record.editable">
                            <a-input v-if="record.editable" style="margin: -5px 0"
                                v-model="record.sortingByClassification" />
                        </span> -->
            <!-- <span v-else> -->
            {{ record.level }}
            <!-- </span> -->
          </template>
          <template #name="{ record, rowIndex }">
            <span v-if="record.editable">
              <a-input
                v-if="record.editable"
                style="margin: -5px 0"
                v-model="record.name"
              />
            </span>
            <span v-else>
              {{ record.name }}
            </span>
          </template>
          <template #operation="{ record, rowIndex }">
            <div class="editable-row-operations">
              <div class="flex f-x-c" v-if="record.editable">
                <div class="btn_controls" v-if="record.isParent">
                  <a-button
                    status="success"
                    @click="addRecord(record.id, rowIndex)"
                    >添加下级</a-button
                  >
                </div>
                <div class="btn_controls">
                  <a-button
                    status="success"
                    @click="saveEditRecord(record.id, rowIndex)"
                    >保存</a-button
                  >
                </div>
                <div class="btn_controls">
                  <a-button
                    status="danger"
                    @click="deleteRecord(record.id, rowIndex)"
                    >删除</a-button
                  >
                </div>
              </div>
              <div class="flex f-x-c" v-else style="cursor: pointer">
                <div class="btn_controls" v-if="record.isParent">
                  <a-button status="success" @click="addRecord(record.id)"
                    >添加下级</a-button
                  >
                </div>
                <div class="btn_controls">
                  <a-button
                    status="success"
                    @click="editRecord(record.id, record)"
                    >编辑</a-button
                  >
                </div>
                <div class="btn_controls">
                  <a-button
                    status="danger"
                    @click="deleteRecord(record.id, rowIndex)"
                    >删除</a-button
                  >
                </div>
              </div>
            </div>
          </template>
        </a-table>
      </a-space>
    </div>
    <!--添加分类-->
    <a-modal
      :footer="null"
      v-model:visible="AddCategoryPopup"
      :width="550"
      title="添加分类"
      alignCenter
      draggable
      escToClose
    >
      <a-form
        :model="AddCategoryForm"
        @submit="handleSubmitAddCategory"
        class="flex f-x-c"
      >
        <a-form-item
          field="name"
          :rules="[{ required: true, message: '请输入分类名称' }]"
        >
          <div class="good_item_desc">添加分类:</div>
          <a-input
            :style="{ width: '248px' }"
            placeholder="请输入分类名称"
            allow-clear
            v-model="AddCategoryForm.name"
          >
          </a-input>
        </a-form-item>
        <a-form-item style="text-align: center !important; padding: 0 80px">
          <a-button
            type="outline"
            status="primary"
            size="large"
            style="margin-right: 20px"
            @click="AddCategoryPopup = false"
            >取消</a-button
          >
          <a-button html-type="submit" type="primary" size="large"
            >确定</a-button
          >
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import { IconPlus } from "@arco-design/web-vue/es/icon";
const Message =
  getCurrentInstance().appContext.config.globalProperties.$message;
const Api = getCurrentInstance()?.appContext.config.globalProperties.$Api;
const Com = getCurrentInstance()?.appContext.config.globalProperties.$Com;
onMounted(() => {
  handlePageChange();
});

const findRecordByKey = (data, key) => {
  for (const record of data) {
    if (record.key === key) {
      return record;
    }
    if (record.datalist.length > 0) {
      const childRecord = findRecordByKey(record.datalist, key);
      if (childRecord) {
        return childRecord;
      }
    }
  }
  return null;
};

//#region 列表**//
const datas = ref([]);
const editingKey = ref("");
const columns = [
  {
    title: "分类排序",
    dataIndex: "level",
    slotName: "level",
  },
  {
    title: "分类名称",
    dataIndex: "name",
    slotName: "name",
  },
  {
    title: "操作",
    slotName: "operation",
    slotName: "operation",
  },
];
const pagination = ref({
  total: 0, // 数据总条目数
  showTotal: true, // 是否显示总条目数
  defaultPageSize: 10, // 默认每页显示条目数
  pageSize: 10, // 当前每页显示条目数
  current: 1, // 当前页码
  showJumper: true,
  onChange: (page, pageSize) => {
    handlePageChange(page, pageSize);
  },
});
const handlePageChange = (page, pageSize) => {
  const pageIndex = page ? page : pagination.value.current;
  Api.erpcategoryListManagement({
    pageIndex: pageIndex,
    status: 0,
    pid: 0,
  })
    .then((res) => {
      if (res.data) {
        console.log(res);
        pagination.value.current = page; // 更新当前页码
        res.data.datalist.forEach((item) => {
          item.editable = false;
          item.isParent = true;
          if (item.list.length > 0) {
            item.children = item.list;
            item.children.map((dataItem) => {
              dataItem.editable = false;
            });
          }
        });
        pagination.value.total = res.data.total; // 更新总条目数
        datas.value = [...res.data.datalist];
        console.log(datas.value);
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};

//#endregion

//#region 编辑 添加下级 删除 保存**/
//编辑
const editRecord = (id) => {
  const recursiveSetEditable = (items) => {
    items.forEach((item) => {
      if (item.id === id) {
        item.editable = true;
      } else {
        item.editable = false;
      }
      if (item.children && item.children.length > 0) {
        recursiveSetEditable(item.children);
      }
    });
  };
  recursiveSetEditable(datas.value);
  // 初始化递归调用
  editingKey.value = id;
};
let lastParentId = null; // 记录上一个操作的父级 id
//添加下级 一次一条
const addRecord = (id) => {
  console.log(id);
  let parentItem = null;
  // 找到指定 id 的父级项
  for (let i = 0; i < datas.value.length; i++) {
    if (datas.value[i].id === id) {
      parentItem = datas.value[i];
      break;
    }
  }
  if (!parentItem) {
    console.error(`Parent item with id ${id} not found.`);
    return;
  }
  // 如果上一个操作的父级 id 不为空且与当前操作的父级 id 不同，则移除上一个父级添加的子项
  if (lastParentId && lastParentId !== id) {
    const lastParentItem = datas.value.find((item) => item.id === lastParentId);
    if (lastParentItem && lastParentItem.children) {
      lastParentItem.children = lastParentItem.children.filter(
        (child) => !child.hasAddedChild
      );
    }
  }
  // 更新上一个父级 id 为当前父级 id
  lastParentId = id;

  // 如果父级项已经有子项了，且已经添加过新子项，则不再添加
  if (
    parentItem.children &&
    parentItem.children.some((child) => child.hasAddedChild)
  ) {
    console.log(`Already added child for parent with id ${id}.`);
    return;
  }

  // 添加新的子项
  const newChild = {
    name: "",
    isParent: false,
    editable: true,
    hasAddedChild: true, // 添加标志
  };

  if (!parentItem.children) {
    parentItem.children = [newChild];
  } else {
    parentItem.children.push(newChild);
  }

  editingKey.value = parentItem.id;
};

// 保存编辑或新增子类别
const saveEditRecord = (id) => {
  console.log(id, editingKey.value);
  if (id) {
    // 编辑已存在的父项或子项
    const recursiveSetEditable = (items, parentId = null) => {
      items.forEach((item) => {
        if (item.id === Number(id)) {
          item.editable = false; // 设置当前项为不可编辑状态
          if (item.children && item.children.length > 0) {
            console.log("Editing existing parent item:", item); // 打印父项
            if (!item.name) return;
            Api.updateErpcaTeory({ id: item.id, name: item.name }).then(
              (res) => {
                handlePageChange(
                  pagination.value.current === undefined
                    ? 1
                    : pagination.value.current,
                  10
                );
              }
            );
          } else {
            if (!item.name) return;
            Api.updateErpcaTeory({
              id: item.id,
              name: item.name,
              pid: parentId,
            }).then((res) => {
              handlePageChange(
                pagination.value.current === undefined
                  ? 1
                  : pagination.value.current,
                10
              );
            });
            console.log(
              "Editing existing child item:",
              item,
              "Parent ID:",
              parentId
            ); // 打印子项并附带父项 ID
          }
        }
        if (item.children && item.children.length > 0) {
          recursiveSetEditable(item.children, item.id); // 递归调用时传递当前项的 ID 作为父项 ID
        }
      });
    };

    // 初始化递归调用
    recursiveSetEditable(datas.value);
    editingKey.value = ""; // 清空编辑状态的键值
  } else {
    // 查找带有特殊标志的新子项
    // 新增保存子类别
    let parentItem = null;
    datas.value.forEach((item) => {
      if (item.children) {
        const newChild = item.children.find((child) => child.hasAddedChild);
        if (newChild) {
          parentItem = item;
          console.log("New child item:", newChild);
          Api.addErpcaTeory({
            pid: editingKey.value,
            name: newChild.name,
          }).then((res) => {
            delete newChild.hasAddedChild;
            handlePageChange(
              pagination.value.current === undefined
                ? 1
                : pagination.value.current,
              10
            );
            newChild.editable = false;
          });
        }
      }
    });
  }
};
const deleteRecord = (id) => {
  let deleteSuccess = false; // 用于中断递归的标志

  const recursiveDelete = (items, id, parentId = null) => {
    // 如果删除操作已经成功，中断递归
    if (deleteSuccess) return items;

    return items.filter((item) => {
      if (item.id === id) {
        Api.updateErpcaTeory({ id: item.id, status: 1 })
          .then((res) => {
            // 请求成功后设置标志位
            deleteSuccess = true;
          })
          .catch((err) => {
            console.error(`Failed to delete item with id ${id}:`, err);
          });

        console.log(`Deleting item with id ${id}, Parent id: ${parentId}`);
        return false; // 过滤掉这个项目，表示删除
      }

      if (item.children && item.children.length > 0) {
        item.children = recursiveDelete(item.children, id, item.id);
        return true; // 保留有子级的父级
      }

      return true; // 保留没有子级的父级
    });
  };

  console.log(`Attempting to delete item with id: ${id}`);
  editingKey.value = ""; // 清空编辑状态的键值

  // 更新数据结构
  const updatedDatas = recursiveDelete(datas.value, id);
  datas.value = updatedDatas; // 直接赋值更新数据，不需要使用 splice

  // 如果删除操作没有成功，重新调用递归函数进行删除
  if (!deleteSuccess) {
    datas.value = recursiveDelete(datas.value, id);
  }
};

//#endregion

//#region  添加分类 */
const AddCategoryPopup = ref(false);
const AddCategoryForm = reactive({
  name: "",
});
const handleSubmitAddCategory = () => {
  if (!AddCategoryForm.name) return;
  Api.addErpcaTeory({
    ...AddCategoryForm,
    userid: JSON.parse(localStorage.getItem("userInfo")).id,
  }).then((res) => {
    console.log(res);
    if (res.code == 200) {
      Message.success(res.message);
    } else {
      Message.warning(res.message);
    }
    AddCategoryForm.name = "";
    handlePageChange(
      pagination.value.current === undefined ? 1 : pagination.value.current,
      10
    );
    AddCategoryPopup.value = false;
  });
};
//#endregion
</script>

<style lang="scss" scoped>
.list_products {
  background: #ffffff;
  border-radius: 2px;
  height: 100%;
  box-sizing: border-box;
  padding: 15px 0;
}

.list_content {
  flex: 1;
  padding: 0 1%;
}

.btn_controls {
  margin: 0 3px;
}

:deep(.arco-table-td-content) {
  width: 250px !important;
}
</style>
