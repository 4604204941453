<template>
  <div class="list_products flex f-c">
    <div class="search_header flex">
      <a-col :span="6">
        <div class="flex a-i-c" style="padding-left: 20px;">
          <div class="good_item_desc">角色昵称:</div>
          <a-input :style="{ width: '320px', }" v-model="name" placeholder="请输入角色昵称" allow-clear />
        </div>
      </a-col>
      <div></div>
      <a-col :span="2">
        <div class="item btn flex a-i-c" style="transform: translate(30px);">
          <a-button type="primary" @click="handlePageChange(1, 10)" style="height: 40px;">
            <template #icon>
              <icon-search />
            </template>
            搜索
          </a-button>
        </div>
      </a-col>
      <a-col :span="2">
        <div class="item btn">
          <a-button type="primary" status="success" @click="reset" style="height: 40px;margin-left: 5px;">
            <template #icon>
              <icon-refresh />
            </template>
            重置
          </a-button>
        </div>

      </a-col>
      <a-col :span="4">
        <div class="item btn">
          <a-button type="primary" status="warning" @click="openAdd" style="height: 40px;margin-left: 5px;">
            添加角色
          </a-button>
        </div>

      </a-col>

    </div>
    <div class="list_content">
      <a-space direction="vertical" size="large" fill>
        <a-table :columns="columns" :data="datas" :pagination="pagination" :bordered="false" :stripe="true">
          <template #status="{ record, rowIndex }">
            <a-switch :default-checked="record.status == 1" @change="ChannelMergerNode($event, record)"
              :style="{ width: '60px' }" />
          </template>
          <template #controls="{ record, rowIndex }">
            <div class="flex"> <a-button type="primary" @click="openEdit(record, rowIndex)"
                style="margin-left: 5px;">编辑</a-button>
              <a-button status="danger" @click="deleteRole(record.id, rowIndex)" style="margin-left: 5px;">删除</a-button>
            </div>
          </template>
        </a-table>

      </a-space>
    </div>
    <!--添加角色-->
    <a-modal :footer="null" v-model:visible="addRole" :width="550" :title="roleTitle" alignCenter draggable escToClose>
      <a-form :model="roleFrom" @submit="handleSubmitAddRole" class="flex f-x-c">
        <a-form-item field="roleName" :rules="[{ required: true, message: '请输入角色名称' }]">
          <div class="good_item_desc">角色名称:</div>
          <a-input :style="{ width: '248px' }" placeholder="请输入角色名称" allow-clear v-model="roleFrom.roleName">
          </a-input>
        </a-form-item>
        <a-form-item field="status">
          <div class="good_item_desc">状态:</div>
          <a-switch v-model="roleFrom.status" checked-value="yes" unchecked-value="no" :style="{ width: '60px', }" />
        </a-form-item>
        <a-form-item>
          <div class="good_item_desc">菜单权限:</div>
          <a-button-group>
            <a-button type="primary" @click="toggleChecked">
              {{
                checkedKeys?.length ? '取消所选' : '全选'
              }}
            </a-button>
            <a-button type="primary" @click="toggleExpanded" style="margin-left: 10px;">
              {{
                expandedKeys?.length ? '折叠' : '展开'
              }}
            </a-button>
          </a-button-group>
        </a-form-item>
        <a-form-item>
          <div class="good_item_desc"></div>
          <a-tree v-if="powerTree.length" :checkable="true" v-model:selected-keys="selectedKeys"
            v-model:checked-keys="checkedKeys" v-model:expanded-keys="expandedKeys" @select="onSelect" @check="onCheck"
            @expand="onExpand" :fieldNames="{
              key: 'oldId',
              title: 'name',
              children: 'childList',
              icon: ''
            }" :data="powerTree" />
        </a-form-item>
        <a-form-item style="text-align: center !important;padding: 0 80px">
          <a-button type="outline" status="primary" size="large" style="margin-right: 20px;"
            @click="addRole = false">取消</a-button>
          <a-button html-type="submit" type="primary" size="large">确定</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script setup>
import { ref, watch, toRefs, reactive, getCurrentInstance, computed, onMounted, nextTick } from 'vue';
import { IconSearch, IconRefresh, IconPrinter, IconPlus, } from '@arco-design/web-vue/es/icon';
import { useStore } from "vuex";
const store = useStore();
const Message = getCurrentInstance().appContext.config.globalProperties.$message
const Api = getCurrentInstance()?.appContext.config.globalProperties.$Api
const name = ref('')

//#region  重制
const reset = () => {
  name.value = ''

}
//#endregion  
//#region 修改状态
const ChannelMergerNode = (event, item) => {
  if (event) {
    item.status = 1
  } else {
    item.status = 0
  }
  console.log(event, item);
  Api.updateRoleStatus({
    id: item.id,
    status: item.status
  }).then(res => {
    console.log(res);
    Message.success('修改成功')
    handlePageChange(pagination.value.current === undefined ? 1 : pagination.value.current, 10)
  }).catch(error => {
    console.error('Error fetching data:', error);
  });
}
const deleteRole = (id, index) => {
  Api.deleteRole({
    ids: id,
  }).then(res => {
    console.log(res);
    Message.success('删除成功')
    handlePageChange(pagination.value.current === undefined ? 1 : pagination.value.current, 10)
  }).catch(error => {
    console.error('Error fetching data:', error);
  });
}
//#endregion  

//#region 列表
const columns = [{
  title: '角色编号',
  dataIndex: 'id',

},
{
  title: '角色昵称',
  dataIndex: 'roleName',

},
{
  title: '状态',
  dataIndex: 'status',
  slotName: 'status'


},
{
  title: '创建日期',
  dataIndex: 'createTime',

},
{
  title: '更新日期',
  dataIndex: 'updateTime',

},
{
  title: '操作',
  slotName: 'controls'
},
];
const datas = ref([])
const pagination = ref({
  total: 0,  // 数据总条目数
  showTotal: true,            // 是否显示总条目数
  defaultPageSize: 10,        // 默认每页显示条目数
  pageSize: 10,               // 当前每页显示条目数
  current: 1,                 // 当前页码
  showJumper: true,
  onChange: (page, pageSize) => {
    handlePageChange(page, pageSize);
  }
});
const handlePageChange = (page, pageSize) => {
  const pageIndex = page ? page : pagination.value.current;
  datas.value = [];
  Api.getRoleList({
    pageIndex: pageIndex,
    roleName: name.value,
    ruserid: JSON.parse(localStorage.getItem("userInfo")).roleid == 1 ? '' : JSON.parse(localStorage.getItem("userInfo")).id

  }).then(res => {
    console.log(res);
    pagination.value.current = page;        // 更新当前页码
    pagination.value.total = res.data.total; // 更新总条目数
    datas.value = [...res.data.datalist];
    console.log(datas.value);
  }).catch(error => {
    console.error('Error fetching data:', error);
  });
};
//#endregion

//#region 创建角色

const selectedKeys = ref([]);
const checkedKeys = ref([]);
const expandedKeys = ref([]);
const allCheckedKeys = ref([]);
const powerTree = ref([]);
onMounted(() => {
  nextTick(() => {
    // 更新 powerTree 的值
    powerTree.value = store.state.permissions;
    handlePageChange()

    // 遍历 powerTree 生成 allCheckedKeys
    function extractIds(treeData, result) {
      function traverse(node) {
        if (!node || !Array.isArray(node)) return;

        node.forEach(item => {
          // 提取 oldId 并加入结果数组
          if (item.oldId) {
            result.push(item.oldId);
          }

          // 递归子节点
          if (item.childList && item.childList.length > 0) {
            traverse(item.childList);
          }
        });
      }

      traverse(treeData);
    }

    // 调用提取函数
    extractIds(powerTree.value, allCheckedKeys.value);

    // 输出结果
    console.log('All Checked Keys:', allCheckedKeys.value);

  });
  return {
    powerTree
  };
});
const addRole = ref(false)
const toggleChecked = () => {
  checkedKeys.value = checkedKeys.value.length ? [] : allCheckedKeys.value;
  console.log('checkedKeys:', checkedKeys.value);
  console.log("🚀 ~ toggleChecked ~ allCheckedKeys.value;:", allCheckedKeys.value)
  roleFrom.value.rules = allCheckedKeys.value
};

// 切换展开状态
const toggleExpanded = () => {
  expandedKeys.value = expandedKeys.value.length ? [] : allCheckedKeys.value;
};

// 处理选择事件
const onSelect = (newSelectedKeys, event) => {
  console.log('select: ', newSelectedKeys, event);
};

// 处理选中事件
const onCheck = (newCheckedKeys, event) => {
  // console.log('check: ', newCheckedKeys, event);
  roleFrom.value.rules = newCheckedKeys
  if (event.halfCheckedKeys.length) {
    console.log(event.halfCheckedKeys);
    roleFrom.value.rules = roleFrom.value.rules.concat(event.halfCheckedKeys);
  }

  // roleFrom.value.rules.concat(event.halfCheckedKeys)
  console.log("🚀 ~ onCheck ~ newCheckedKeys:", newCheckedKeys);
  console.log("🚀 ~ onCheck ~ roleFrom.value.rules :", roleFrom.value.rules)


};

// 处理展开事件
const onExpand = (newExpandedKeys, event) => {
  console.log('expand: ', newExpandedKeys, event);
};

const roleFrom = ref({
  roleName: "",
  status: false,
  rules: [],
  id: JSON.parse(localStorage.getItem("userInfo")).id
})
const resultSelectRole = ref(true)
const handleSubmitAddRole = (data) => {
  if (!roleFrom.value.roleName) return
  if (roleFrom.value.status == 'yes') {
    // roleFrom.value.status = true
    resultSelectRole.value = true
  } else {
    // roleFrom.value.status = false
    resultSelectRole.value = false


  }
  if (!roleFrom.value.rules.length || !checkedKeys.value.length) return
  console.log(roleFrom.value.rules);
  if (Array.isArray(roleFrom.value.rules) && roleFrom.value.rules.length) {
    console.log(roleFrom.value.rules);
    roleFrom.value.rules = roleFrom.value.rules.join(',');
  }
  if (editStatus.value) {
    Api.updateRoleStatus({
      id: roleFrom.value.id,
      role_name: roleFrom.value.roleName,
      status: resultSelectRole.value,
      rules: roleFrom.value.rules,
      ruserid: JSON.parse(localStorage.getItem("userInfo")).id
    }).then(res => {
      console.log(res);
      Message.success('添加成功!')
      handlePageChange(pagination.value.current === undefined ? 1 : pagination.value.current, 10)
      addRole.value = false
      roleFrom.value = {
        roleName: "",
        status: false,
        rules: []
      }
      toggleChecked()
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  } else {
    const roleData = {
      role_name: roleFrom.value.roleName,
      status: resultSelectRole.value,
      rules: roleFrom.value.rules,
      ruserid: JSON.parse(localStorage.getItem("userInfo")).id

    };
    Api.addRole(roleData).then(res => {
      console.log(res);
      Message.success('添加成功!')
      handlePageChange(pagination.value.current === undefined ? 1 : pagination.value.current, 10)
      addRole.value = false
      roleFrom.value = {
        roleName: "",
        status: false,
        rules: []
      }
      toggleChecked()
    })
  }

}
//#endregion

//#region 编辑角色
const roleTitle = ref('创建角色')





const openAdd = () => {
  roleTitle.value = '创建角色'
  addRole.value = true
  editStatus.value = false

  checkedKeys.value.length = 0
  roleFrom.value = {
    roleName: "",
    status: false,
    rules: [],
    id: JSON.parse(localStorage.getItem("userInfo")).id
  }
}
const editStatus = ref(false)
const openEdit = (item) => {
  addRole.value = true
  roleTitle.value = '编辑角色'
  console.log(item);
  roleFrom.value.id = item.id
  roleFrom.value.roleName = item.roleName
  roleFrom.value.rules = item.rules
  roleFrom.value.status = item.status
  if (item.status == 1) {
    roleFrom.value.status = 'yes'
  } else if (item.status == 0) {
    roleFrom.value.status = 'no'
  }
  editStatus.value = true
  checkedKeys.value = item.rules.split(",").map(Number);


  console.log(selectedKeys.value, checkedKeys.value);
  // roleFrom.value.phone = item.phone
  // roleFrom.value.username = item.username
  // roleFrom.value.userid = item.userid
  // if (item.userStatus == 0) {
  //   roleFrom.value.status = 'yes'
  // } else {
  //   roleFrom.value.status = 'no'
  // }
  // editName.value = item.role_name
  // const params = roleList.value
  //   .filter(child => child.roleName == item.role_name)
  //   .map(child => child.id);
  // roleFrom.value.roles = params.toString()
  // roleFrom.value.id = item.userid
  // console.log(roleFrom.value.roles);
}
//#endregion
</script>
<style lang="scss" scoped>
.list_products {
  background: #FFFFFF;
  border-radius: 2px;
  height: 100%;
  box-sizing: border-box;
  padding: 15px 0;

  .item_desc,
  :deep(.arco-input-prepend) {
    font-weight: 400;
    white-space: nowrap;
    color: #000000;
    font-size: 14px;
    background-color: red;
    background: #FFFFFF !important;
    border: none;
  }

  .desc_width {
    width: 100px;
    width: 70px;
  }

  .list_content_header {

    .list_item {
      font-size: 14px;
      color: #333333;
      padding: 13px 6px;
      border: 1px solid #E6E6E6;
      width: 106px;
      box-sizing: border-box;
      text-align: center;
      cursor: pointer;

    }

    .active {
      color: #6784FD
    }
  }

  :deep(.arco-input-wrapper) .arco-input::placeholder,
  :deep(.arco-select-view-single) .arco-select-view-input::placeholder {
    font-size: 12px !important;

  }

  .search_header {
    height: 12vh;
  }

  .list_content {
    flex: 1;
    padding: 0 1%;
    transform: translateY(-40px);

  }

}
</style>