<template>
    <div class="list_products">

        <a-form :model="storeInformationForm" @submit="handleSubmitStoreInformation" :style="{ width: '500px' }">
            <a-form-item field="name" style="margin-top: 30px;">
                <div class="good_item_desc">店铺名称:</div>
                <a-input :style="{ width: '320px', }" v-model="storeInformationForm.name" placeholder="请输入店铺名称"
                    allow-clear />
            </a-form-item>
            <!-- :rules="[{ required: true, message: '请输入商品名称' }]" -->
            <a-form-item field="imgPath" style="margin-top: 20px;">
                <!-- :rules="[{ required: true, message: '请上传商品图片' }]" -->
                <div class="good_item_desc">店铺门头:</div>
                <a-upload class="upload" action="https://ycapi.yidianchen.com/api/sys/oss/upload"
                    accept="image/png, image/jpeg" :fileList="file" @success="onSuccessUploadImg"
                    list-type="picture-card" :show-file-list="false">
                    <template #upload-button>
                        <div :class="`arco-upload-list-item${file && file.status === 'error' ? ' arco-upload-list-item-error' : ''
                            }`">
                            <div class="arco-upload-list-picture custom-upload-avatar" v-for="item in file">
                                <img :src="item.url" />
                                <div class="arco-upload-list-picture-mask">
                                    <IconEdit />
                                </div>
                            </div>
                            <div class="arco-upload-picture-card" v-if="file.length < 1">
                                <div class="arco-upload-picture-card-text">
                                    <IconPlus size="28" strokeWidth="2" />
                                    <div style="margin-top: 10px; font-weight: 600;font-size: 12px;color: #999999;">
                                        点击添加</div>
                                </div>
                            </div>
                        </div>
                    </template>
                </a-upload>
            </a-form-item>
            <a-form-item field="address" style="margin-top: 20px;">

                <!-- :rules="[{ required: true, message: '请选择地址' }]" -->
                <div class="good_item_desc">地址:</div>

                <div class="flex f-c">
                    <a-alert type="success" :show-icon="false" v-if="currentSelectAddress">{{
                        currentSelectAddress
                        }}</a-alert>
                    <a-cascader :options="options" :default-value="currentSelectAddress" allow-clear
                        expand-trigger="hover" :style="{ width: '320px' }" @change="changeOPtions" placeholder="请选择所在地址"
                        path-mode />
                    <a-textarea placeholder="请输入详细地址" allow-clear :max-length="200" show-word-limit :style="{
                        width: '320px', height: '100px', marginTop: '30px', border: '1px solid #E6E6E6', background: '#FFFFFF'
                    }" v-model="storeInformationForm.addressdetail" />
                    <!-- v-model="form.feedback" -->
                </div>
            </a-form-item>

            <a-form-item field="mainscope" style="margin-top: 20px;">
                <!-- :rules="[{ required: true, message: '请选择所在仓库' }]" -->
                <div class="good_item_desc ">主营范围:</div>
                <a-textarea placeholder="请输入主营范围" allow-clear :max-length="200" show-word-limit
                    v-model="storeInformationForm.mainscope" :style="{
                        width: '320px', height: '100px', border: '1px solid #E6E6E6', background: '#FFFFFF'
                    }" />
            </a-form-item>
            <a-form-item field="businessHours" style="margin-top: 20px;width: 100%;">
                <div class="good_item_desc  ">营业时间:</div>
                <div>

                    <a-alert type="success" :show-icon="false" v-if="storeInformationForm.timeDesc">{{
                        storeInformationForm.timeDesc
                    }}</a-alert>
                    <a-tree-select v-model="selected" :allow-clear="true" :tree-checkable="true"
                        :tree-check-strictly="treeCheckStrictly" :data="treeData" placeholder="请选择营业时间"
                        style="width: 320px;"></a-tree-select>
                    <a-time-picker type="time-range" format="HH:mm" :defaultValue="defaultTimeValue" s=""
                        @change="(valueString, value) => print('onChange:', valueString, value)"
                        style="width: 320px;margin-top: 30px;" />
                </div>

                <!-- <a-input :style="{ width: '320px' }" v-model="combinedTime" placeholder="请输入营业时间" allow-clear /> -->
            </a-form-item>
            <a-form-item field="mobile" style="margin-top: 20px;">
                <div class="good_item_desc  ">联系方式:</div>
                <a-input :style="{ width: '320px' }" v-model="storeInformationForm.mobile" placeholder="请输入联系方式"
                    allow-clear />
            </a-form-item>
            <a-form-item style="margin-top: 30px;">
                <div style="width: 95%; text-align: center;margin-top: 30px;">
                    <!-- <a-button type="outline" status="primary" size="large" style="margin-right: 20px;">取消</a-button> -->
                    <a-button html-type="submit" type="primary" size="large">确定</a-button>
                </div>
            </a-form-item>
        </a-form>
    </div>
</template>
<script setup>
import { ref, reactive, getCurrentInstance, computed, onMounted } from 'vue';
import { IconPlus, IconEdit } from '@arco-design/web-vue/es/icon';
import axios from "axios";
const Message = getCurrentInstance().appContext.config.globalProperties.$message
const Api = getCurrentInstance()?.appContext.config.globalProperties.$Api
const Com = getCurrentInstance()?.appContext.config.globalProperties.$Com
const combinedTime = ref('')
const storeInformationForm = ref({
    name: '',
    img: '',
    address: '',//地址
    addressdetail: '',
    mainscope: '',
    starttime: '',
    stoptime: '',
    mobile: '',
    weekday: '',
    city: '',
    provnce: '',
    district: '',
    timeDesc: '',
    // authstatus: '',//0：未认证，1：已认证

});
const currentSelectAddress = ref('')
const file = ref([]);
// 计算属性，根据 file 的值返回布尔值
onMounted(() => {
    getDefaultInfo()
    //获取当前的位置信息
    axios.get(`https://restapi.amap.com/v3/config/district?key=bb5e823739e620ce2371bb376b05e577&subdistrict=3`).then(
        response => {
            // console.log('服务器响应回来的数据：', response.data);
            const districts = response.data.districts[0].districts;
            // 将地区列表赋值给 options.value
            options.value = districts.map(item => ({
                value: item.name,
                label: item.name,
                children: item.districts.map(subItem => ({
                    value: subItem.name,
                    label: subItem.name,
                    children: subItem.districts.map(subSubItem => ({
                        value: subSubItem.name,
                        label: subSubItem.name,
                    }))
                }))
            }));

            // console.log('处理后的选项：', options.value);
        },
        error => {
            // console.log('错误信息：', error.message);
        }
    );
});

//#region 位置处理
const options = ref([
]);
const changeOPtions = (val) => {
    storeInformationForm.value.provnce = val[0]
    storeInformationForm.value.city = val[1]
    storeInformationForm.value.district = val[2]
    storeInformationForm.value.address = val[0] + val[1] + val[2]
    console.log('storeInformationForm', storeInformationForm.value, 'currentSelectAddress', currentSelectAddress.value);
}
//#endregion

//#region  日期处理
const selected = ref([]);
const defaultTimeValue = ref('')
const treeCheckStrictly = ref(false);
const treeData = [
    {
        title: '周一',
        value: '周一',
        key: '0',
    }, {
        title: '周二',
        value: '周二',
        key: '1',
    }, {
        title: '周三',
        value: '周三',
        key: '2',
    }, {
        title: '周四',
        value: '周四',
        key: '3',
    }, {
        title: '周五',
        value: '周五',
        key: '4',
    }, {
        title: '周六',
        value: '周六',
        key: '5',
    }, {
        title: '周日',
        value: '周日',
        key: '6',
    },

];
const print = (...args) => {
    console.log(...args);
    // 将第一个参数赋值给 starttime，第二个参数赋值给 stoptime
    storeInformationForm.value.starttime = args[1][0];
    storeInformationForm.value.stoptime = args[1][1];

};
// 时间范围处理函数
const timeRange = () => {

    // storeInformationForm.value.weekday = '';
    const dayNames = ['一', '二', '三', '四', '五', '六', '日'];

    const sortedTitles = selected.value.sort((a, b) => a.id - b.id)
    storeInformationForm.value.weekday = sortedTitles.join(',');
    if (!storeInformationForm.value.weekday) {
        console.log('请选择营业天数');
        return;
    }

    const ids = storeInformationForm.value.weekday.split(',').map(Number).sort((a, b) => a - b);
    let result = [];
    let temp = [];

    for (let i = 0; i < ids.length; i++) {
        temp.push(ids[i]);
        // 检查下一个元素是否是连续的
        if (i === ids.length - 1 || ids[i + 1] !== ids[i] + 1) {
            if (temp.length > 1) {
                result.push(`周${dayNames[temp[0]]}至周${dayNames[temp[temp.length - 1]]}`);
            } else {
                result.push(`周${dayNames[temp[0]]}`);
            }
            temp = [];
        }
    }

    const finalResult = result.join(',');
    console.log(finalResult);
    // 最终结果
    storeInformationForm.value.timeDesc = `${finalResult}; ${storeInformationForm.value.starttime} 至 ${storeInformationForm.value.stoptime}`;
    // storeInformationForm.value.starttime = e[1];
    // storeInformationForm.value.stoptime = e[2];
    console.log(storeInformationForm.value);
};
//#endregion

//#region 图片处理
const onSuccessUploadImg = (res, currentFile) => {
    const fileUrl = res.response.data.fileUrl;
    file.value = []

    file.value.push({
        uid: res.uid,
        status: 'done',
        url: fileUrl,
    });
    storeInformationForm.value.img = file.value.map(file => file.url).join(',');
};
//#endregion



const status = ref(false);//是否有店铺信息
const getDefaultInfo = () => {
    Api.getShopInfo({ status: 0 ,
    userid:JSON.parse(localStorage.getItem("userInfo")).id
    }).then(res => {
        if (res.data && Object.keys(res.data).length > 0) {
            storeInformationForm.value = { ...res.data }
            status.value = true
            file.value.push({ url: res.data.img })
            combinedTime.value = res.data.starttime + '至' + res.data.endtime;
            // 将地址分解为数组
            currentSelectAddress.value = res.data.address; // 假设地址是用 '/' 分隔的

            console.log('默认值', combinedTime.value, currentSelectAddress.value);
            // if (form.timeDesc) {
            //     timeFrom = this.form.timeDesc
            // }
        }
    })
}




const delSpecification = (index) => {
    storeInformationForm.specifications.splice(index, 1);
};
const handleSubmitStoreInformation = (data) => {
    timeRange(selected.value)
    const form = storeInformationForm.value;
    console.log(storeInformationForm.value);
    if (!form.name || !form.address || !form.mobile || !form.mainscope || !form.starttime || !form.weekday) {
        Message.warning('请完善店铺信息');
        return;
    }



    if (status.value = true) {

        // 对 selected.value 进行排序并转换为字符串，赋值给 weekday
        Api.updateShopInfo({ ...storeInformationForm.value }).then((res) => {
            file.value = []
            getDefaultInfo()
        })
    } else if (status.value = false) {
        // 对 selected.value 进行排序并转换为字符串，赋值给 weekday
        Api.saveShopInfo({ ...storeInformationForm.value }).then((res) => {
            file.value = []
            getDefaultInfo()
        })
    }

}
</script>
<style scoped lang="scss">
.list_products {
    background: #FFFFFF;
    border-radius: 2px;
    height: 100%;
    box-sizing: border-box;
    padding: 15px 0;
}

:deep(.arco-form-item-content-flex) {
    align-items: normal !important;
    // transform: translateY(30px);
}

.good_item_desc {
    line-height: 40px;
    font-size: 15px;
    // font-weight: 600;
    color: #000000;
    width: 70px !important;

}
</style>