<template>
    <div class="list_products flex f-c">
        <div class="search_header flex">
            <a-col :span="6">
                <div class="flex a-i-c" style="padding-left: 20px;">
                    <div class="item_desc desc_right">店铺名称:</div>
                    <a-select :style="{ width: '260px', marginLeft: '15px' }" value-key="id" placeholder="请选择店铺"
                        :loading="storeLoading" v-model="selectStore" @change="storeChange">
                        <a-option v-for="item of storeOptions" :value="item" :label="item.name" />
                    </a-select>
                </div>
            </a-col>
            <div></div>
            <a-col :span="2">
                <div class="item btn flex a-i-c" style="transform: translate(30px);">
                    <a-button type="primary" @click="searchList" style="height: 40px;">
                        <template #icon>
                            <icon-search />
                        </template>
                        搜索
                    </a-button>
                </div>
            </a-col>
            <a-col :span="2">
                <div class="item btn">
                    <a-button type="primary" status="success" @click="reset" style="height: 40px;margin-left: 5px;">
                        <template #icon>
                            <icon-refresh />
                        </template>
                        重置
                    </a-button>
                </div>

            </a-col>
        </div>
        <div class="list_content">
            <div class="list_content_header flex ">
                <div class="list_item" v-for="item, index in listHeader" :key="index"
                    @click="handelChangeHeaderListItem(item, index)"
                    :style="{ color: currentSelectHeaderItem === item.id ? ' #6784FD' : '' }">
                    {{
                        item.title }}
                </div>
            </div>
            <a-space direction="vertical" size="large" fill>
                <a-table :columns="columns" :data="datas" :row-selection="rowSelection" :row-key="key"
                    @selectAll="selectedAllKeysEvent" @select="selectedEvent" v-model:selectedKeys="selectedKeys"
                    :pagination="pagination" :bordered="false" :stripe="true">
                    <template #shopName="{ record, rowIndex }">
                        <span>
                            {{ record.shopName }} </span>
                    </template>
                    <template #shoporderitemName="{ record, rowIndex }">
                        <span>
                            {{ record.shoporderitemName }} </span>
                    </template>
                    <template #shoporderitemQty="{ record, rowIndex }">
                        <span>
                            {{ record.shoporderitemQty }} </span>
                    </template>
                    <template #shoporderitemPrice="{ record, rowIndex }">
                        <span>
                            {{ record.shoporderitemPrice }} </span>
                    </template>
                    <template #shoporderitemAmount="{ record, rowIndex }">
                        <span>
                            {{ record.shoporderitemAmount }} </span>
                    </template>
                    <template #shoporderPaystatus="{ record, rowIndex }">
                        <span v-if="record.shoporderPaystatus == '0'">
                            未支付</span> <span v-if="record.shoporderPaystatus == '1'">
                            已支付</span> <span v-if="record.shoporderPaystatus == '2'">
                            退款中</span> <span v-if="record.shoporderPaystatus == '3'">
                            已退款</span> <span v-if="record.shoporderPaystatus == '4'">
                            预收款</span>
                        <span v-if="record.shoporderPaystatus == '5'">
                            预付款</span>
                    </template>
                    <template #shoporderitemRemark="{ record, rowIndex }">
                        <span>
                            {{ record.shoporderitemRemark }} </span>
                    </template>
                    <!-- <template #mobile="{ record, rowIndex }">
                        <span>
                            {{ record.mobile }}
                        </span>
                    </template> -->
                    <!-- <template #address="{ record, rowIndex }">
                        <span>
                            {{ record.address + '/' + record.addressdetail }}
                        </span>
                    </template> -->

                </a-table>
            </a-space>
        </div>
    </div>
</template>
<script setup>
import { ref, watch, toRefs, reactive, getCurrentInstance, onMounted } from 'vue';
import { IconSearch, IconRefresh, IconPrinter, IconPlus, } from '@arco-design/web-vue/es/icon';
import { customRef } from 'vue';
import axios from "axios";
const Message = getCurrentInstance().appContext.config.globalProperties.$message
const Api = getCurrentInstance()?.appContext.config.globalProperties.$Api
const Com = getCurrentInstance()?.appContext.config.globalProperties.$Com

onMounted(() => {
    handlePageChange()
    getStore()
});
//#region 店铺列表
const storeOptions = ref([])
const storeLoading = ref(false)
const selectStore = ref('')
const getStore = () => {
    storeLoading.value = true
    Api.getShopList({
        pageSize: 1000,
        status: 0,
    }).then(res => {
        storeOptions.value = res.data.datalist
        storeLoading.value = false
    })
}
const storeChange = () => {
    console.log(selectStore.value.id);
}


//#endregion
//#region 头部bar
const listHeader =
    [
        {
            title: '全部',
            id: 'all'
        },
        {
            title: '待付款',
            id: 'dfk'

        },
        {
            title: '待发货',
            id: 'dfh'

        },
        {
            title: '待收货',
            id: 'dsh'

        },
        // {
        //     title: '已完成',
        //     id: 'ywc'

        // }
    ];
const currentSelectHeaderItem = ref('all')
//子项点击
const handelChangeHeaderListItem = (item, index) => {
    if (currentSelectHeaderItem.value == item.id) return
    currentSelectHeaderItem.value = item.id
    if (item.id === 'dfk') {
        paramsData.value.kind = 2
        paramsData.value.paystatusArray = `0,2`
    } else if (item.id === 'dfh') {
        paramsData.value.kind = 1
        paramsData.value.paystatusArray = `1`
        paramsData.value.harvestsArray = `0`
    } else if (item.id === 'dsh') {
        paramsData.value.kind = 1
        paramsData.value.paystatusArray = `1`
        paramsData.value.harvestsArray = `1`
    } else if (item.id === 'ywc') {
        paramsData.value.kind = 2
        paramsData.value.paystatusArray = `1`
        paramsData.value.harvestsArray = `2`
    } else if (item.id === 'tkz') {
        //TODO:
    } else if (item.id === 'all') {
        paramsData.value.kind = ''
        paramsData.value.paystatusArray = ``
        paramsData.value.harvestsArray = ``
    }
    handlePageChange(pagination.value.current === undefined ? 1 : pagination.value.current, 10)

}
//#endregion
//#region   查询表单 搜索 重制
const paramsData = ref({
    shopName: '',
    kind: '',
    paystatusArray: '',
    harvestsArray: ''
    // mobile: ''
})
const reset = () => {
    selectStore.value = ''
}
const columns = [{
    title: '店铺名称',
    dataIndex: 'shopName',
    slotName: 'shopName'
},
{
    title: '订单编号',
    dataIndex: 'shoporderOrdersn',
    slotName: 'shoporderOrdersn'

},
{
    title: '商品名称',
    dataIndex: 'shoporderitemName',
    slotName: 'shoporderitemName'

},

{
    title: '型号',
    dataIndex: 'shoporderitemModel',
    slotName: 'shoporderitemModel'

},
{
    title: '收货人',
    dataIndex: 'shoporderUsername',
    slotName: 'shoporderUsername'

},
{
    title: '收货地址',
    dataIndex: 'shoporderAddress',
    slotName: 'shoporderAddress'

},
{
    title: '数量',
    dataIndex: 'shoporderitemQty',
    slotName: 'shoporderitemQty'

},
// {
//     title: '价格',
//     dataIndex: 'shoporderitemPrice',
//     slotName: 'shoporderitemPrice'

// }, 
{
    title: '金额',
    dataIndex: 'shoporderitemAmount',
    slotName: 'shoporderitemAmount'

}, {
    title: '状态',
    dataIndex: 'shoporderPaystatus',
    slotName: 'shoporderPaystatus'

}, {
    title: '备注',
    dataIndex: 'shoporderRemark',
    slotName: 'shoporderRemark'

},
];
const datas = ref([])
const pagination = ref({
    total: 0,  // 数据总条目数
    showTotal: true,            // 是否显示总条目数
    defaultPageSize: 10,        // 默认每页显示条目数
    pageSize: 10,               // 当前每页显示条目数
    current: 1,                 // 当前页码
    showJumper: true,
    onChange: (page, pageSize) => {
        handlePageChange(page, pageSize);
    }
});
const handlePageChange = (page, pageSize) => {
    // console.log('page:', page, 'pageSize:', pageSize);
    const pageIndex = page ? page : pagination.value.current;
    Api.shopOrderList({
        pageIndex: pageIndex,
        status: 0,
        shopid: selectStore.value.id,
        ...paramsData.value
    }).then(res => {
        console.log(res);
        pagination.value.current = page;        // 更新当前页码
        res.data.datalist.forEach(item => {
            item.editable = false;
        });
        pagination.value.total = res.data.total; // 更新总条目数
        datas.value = [...res.data.datalist];
        console.log(datas.value);
    }).catch(error => {
        console.error('Error fetching data:', error);
    });
};
const searchList = () => {
    handlePageChange(1, 10)
}
//#endregion  

</script>
<style lang="scss" scoped>
.list_products {
    background: #FFFFFF;
    border-radius: 2px;
    height: 100%;
    box-sizing: border-box;
    padding: 15px 0;

    .item_desc,
    :deep(.arco-input-prepend) {
        font-weight: 400;
        white-space: nowrap;
        color: #000000;
        font-size: 14px;
        background-color: red;
        background: #FFFFFF !important;
        border: none;
    }

    .desc_width {
        width: 100px;
        width: 70px;
    }

    .list_content_header {

        .list_item {
            font-size: 14px;
            color: #333333;
            padding: 13px 6px;
            border: 1px solid #E6E6E6;
            width: 106px;
            box-sizing: border-box;
            text-align: center;
            cursor: pointer;

        }

        .active {
            color: #6784FD
        }
    }

    :deep(.arco-input-wrapper) .arco-input::placeholder,
    :deep(.arco-select-view-single) .arco-select-view-input::placeholder {
        font-size: 12px !important;

    }

    .search_header {
        height: 12vh;
    }

    .list_content {
        flex: 1;
        padding: 0 1%;
        transform: translateY(-40px);

    }
}
</style>
