// 数据管理
import dataManagement from "@/views/dataManagement/dataManagement.vue";
// 商品管理
import listOfProducts from "@/views/commodityManagement/listOfProducts.vue";
import productClassification from "@/views/commodityManagement/productClassification.vue";
import warehouseManagement from "@/views/commodityManagement/warehouseManagement.vue";
// 商城
import storeInformation from "@/views/shoppingMall/storeInformation.vue";
import mallMerchandiseManagement from "@/views/shoppingMall/mallMerchandiseManagement.vue";
// 客户管理
import customerList from "@/views/customerManagement/customerList.vue";
import suppliersList from "@/views/customerManagement/suppliersList.vue";
// 销货开单
import invoicingForSales from "@/views/invoiceForSales/invoicingForSales.vue";
import invoicingForIncomingGoods from "@/views/invoiceForSales/invoicingForIncomingGoods.vue";
import returnBilling from "@/views/invoiceForSales/returnBilling.vue";
// 订单管理
import orderManagement from "@/views/orderManagement/orderManagement.vue";
// 投诉建议
import contactCustomerService from "@/views/complaintsAndSuggestions/contactCustomerService.vue";
import feedback from "@/views/complaintsAndSuggestions/feedback.vue";
//子账号设置
import subAccountSettings from "@/views/subAccountSettings/subAccountSettings.vue";
// 系统设置
import systemSettings from "@/views/systemSettings/systemSettings.vue";
//404
import NotFound from "@/views/notfound/NotFound.vue";
//店铺管理
import order from "@/views/storeManagement/order.vue";
import stores from "@/views/storeManagement/stores.vue";
import customerLists from "@/views/storeManagement/customerLists.vue";
import identityManager from "@/views/storeManagement/identityManager.vue";
import adminList from "@/views/storeManagement/adminList.vue";
import adsAndVideos from "@/views/storeManagement/adsAndVideos.vue";

const routes = [
  //#region 数据管理
  {
    path: "/dataManagement",
    component: dataManagement,
    name: "数据管理",
    meta: { title: "数据管理", breadcrumb: true },
  },
  //#endregion

  //#region 商品管理
  {
    // 商品列表
    path: "/commodityManagement/listOfProducts",
    component: listOfProducts,
    name: "商品列表",
    meta: { title: "商品列表", breadcrumb: true },
  },
  {
    // 商品分类
    path: "/commodityManagement/productClassification",
    component: productClassification,
    name: "商品分类",
    meta: { title: "商品分类", breadcrumb: true },
  },
  {
    // 仓库管理
    path: "/commodityManagement/warehouseManagement",
    component: warehouseManagement,
    name: "仓库管理",
    meta: { title: "仓库管理", breadcrumb: true },
  },
  //#endregion

  //#region 商城
  {
    // 店铺信息
    path: "/shoppingMall/storeInformation",
    component: storeInformation,
    meta: { title: "店铺信息", breadcrumb: true },

    // requireAuth: true,
  },
  {
    // 商城商品管理
    path: "/shoppingMall/mallMerchandiseManagement",
    component: mallMerchandiseManagement,
    meta: { title: "商品管理", breadcrumb: true },
  },
  //#endregion

  //#region 客户管理
  {
    // 客户列表
    path: "/customerManagement/customerList",
    component: customerList,
    meta: { title: "客户列表", breadcrumb: true },
  },
  {
    // 供应商列表
    path: "/customerManagement/suppliersList",
    component: suppliersList,
    meta: { title: "供应商列表", breadcrumb: true },
  },
  //#endregion

  //#region 销货开单
  {
    // 销货开票
    path: "/invoiceForSales/invoicingForSales",
    component: invoicingForSales,
    meta: { title: "销货开票", breadcrumb: true },
  },

  {
    // 进货开票
    path: "/invoiceForSales/invoicingForIncomingGoods",
    component: invoicingForIncomingGoods,
    meta: { title: "进货开票", breadcrumb: true },

    // requireAuth: true,
  },
  {
    // 退货开票
    path: "/invoiceForSales/returnBilling",
    component: returnBilling,
    meta: { title: "退货开票", breadcrumb: true },
  },
  //#endregion

  //#region 订单管理
  {
    path: "/orderManagement",
    component: orderManagement,
    meta: { title: "订单管理", breadcrumb: true },
  },
  //#endregion
 //#region 客户人员列表
 {
  path: "/customerLists/list",
  component: customerLists,
  meta: { title: "客户人员列表", breadcrumb: true },
},
  //#region 店铺管理
  {
    path: "/storeManagement/stores",
    component: stores,
    meta: { title: "店铺列表", breadcrumb: true },
  },
  {
    path: "/storeManagement/order",
    component: order,
    meta: { title: "店铺订单", breadcrumb: true },
  },
  {
    path: "/storeManagement/identityManager",
    component: identityManager,
    meta: { title: "角色管理", breadcrumb: true },
  },
  {
    path: "/storeManagement/adminList",
    component: adminList,
    meta: { title: "管理员列表", breadcrumb: true },
  },
  {
    path: "/storeManagement/adsAndVideos",
    component: adsAndVideos,
    meta: { title: "广告/视频", breadcrumb: true },
  },
  //#endregion

  //#region 投诉建议
  {
    path: "/complaintSuggestion/contactCustomerService",
    component: contactCustomerService,
    meta: { title: "联系客服", breadcrumb: true },
  },
  {
    path: "/complaintSuggestion/feedback",
    component: feedback,
    meta: { title: "意见反馈", breadcrumb: true },
  },
  //#endregion

  //#region 子账号设置
  {
    path: "/subAccountSettings",
    component: subAccountSettings,
    meta: { title: "子账号设置", breadcrumb: true },
  },
  //#endregion

  //#region 系统设置
  {
    path: "/systemSettings",
    component: systemSettings,
    meta: { title: "系统设置", breadcrumb: true },
  },
  //#endregion

  {
    path: "/",
    redirect: "/dataManagement",
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  },
];

export default routes;
