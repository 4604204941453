<template>
  <div id="app" class="login flex a-i-c">
    <div class="slogan">
      <div class="slogan_t">HI,你好!</div>
      <div class="slogan_c"> 欢迎进入易创管理系统</div>
      <div class="slogan_b">Welcome to the Etron Management System</div>
    </div>
    <div class="formContainer">
      <a-form ref="loginFormRef" :model="loginForm" :style="{ width: '300px' }" @submit="submitForm">
        <a-form-item field="mobile" validate-trigger="blur" :rules="[{ required: true, message: '请输入您的账号' }]">
          <a-input v-model="loginForm.mobile" placeholder="请输入您的账号">
            <template #prepend>
              账号:
            </template> </a-input>
        </a-form-item>
        <a-form-item field="password" validate-trigger="blur" :rules="[{
          required: true, message: '请输入您的密码'
        }]">
          <!-- { minLength: 5, message: 'must be greater than 5 characters' } -->
          <a-input-password v-model="loginForm.password" allow-clear placeholder="请输入您的密码">
            <template #prepend>
              密码:
            </template> </a-input-password>
        </a-form-item>
        <a-form-item style="width: 100%;text-align: center;">
          <a-button html-type="submit" type="primary"> 登录</a-button>
        </a-form-item>
      </a-form>
      {{ form }}

    </div>
  </div>
</template>

<script setup>
import CryptoJS from "crypto-js";
import { reactive, ref, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
const store = useStore();
//#region 表单
const loginForm = reactive({
  mobile: "",
  password: "",
  type: 1
});
const loginFormRef = ref({});
const router = useRouter();
const Message = getCurrentInstance().appContext.config.globalProperties.$message
const Api = getCurrentInstance()?.appContext.config.globalProperties.$Api
const submitForm = () => {
  if (!loginForm.mobile || !loginForm.password) return
  const encryptedPassword = CryptoJS.MD5(loginForm.password).toString();
  console.log(encryptedPassword);

  Api.login({ mobile: loginForm.mobile, password: encryptedPassword, type: 1 }).then((res) => {
    if (res.data) {
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("userInfo", JSON.stringify(res.data));
      store.commit("changeUserInfo", res.data.data);
      Message.success(res.message)
      //TODO::
      getPower()
    } else {
      Message.error(res.message)
    }
  });
};
const getPower = () => {
  Api.power({ userid: JSON.parse(localStorage.getItem("userInfo")).id }).then(res => {
    const arr = res.data.datalist[0];

    const paths = [];
    // 递归函数，处理嵌套的数组结构
    const processList = (list) => {
      list.forEach(item => {
        item.oldId = item.id
        if (item.component) {
          item.path = item.component;
          delete item.id;
          item.id = item.idShow;
          paths.push(item.path);
        }
        // 如果有子项，递归处理子项
        if (item.childList && item.childList.length > 0) {
          processList(item.childList);
        }
      });
    };

    // 处理数据列表
    processList(arr);

    // 更新 menuJsonData 的值
    store.commit('SET_PERMISSIONS', arr) //权限菜单列表

    // 跳转到第一个 path
    if (paths.length > 0) {
      store.dispatch('updateCurrentPath', paths[0]);
      router.push(paths[0]);
      console.log('跳转到', paths[0]);
    }
  });
}
//#endregion
</script>

<style lang="scss" scoped>
:deep(.arco-input-prepend) {
  font-weight: 400;
  white-space: nowrap;
  color: #000000;
  font-size: 14px;
  background-color: red;
  background: #FFFFFF !important;
  border: none;
}

:deep(.arco-input-wrapper) .arco-input::placeholder,
:deep(.arco-select-view-single) .arco-select-view-input::placeholder {
  font-size: 12px !important;

}

.login {


  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -5;
    background-image: url('../assets/login/login.png');
    background-size: 100% 100%;
  }


  .slogan {
    padding: 260px 0 0 150px;

    .slogan_t,
    .slogan_c {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 40px;
      color: #FFFFFF;
    }

    .slogan_b {
      margin-top: 20px;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 20px;
      color: #FFFFFF;
    }
  }

  .formContainer {
    width: 320px;
    height: 170px;
    padding: 30px 30px 30px 0;
    position: fixed;
    right: 0;
    top: 40%;
    transform: translate(-50%, -50%);
    // background: rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    border-radius: 12px;

    ::v-deep .el-form-item__label {
      color: white;
    }
  }
}
</style>
